define('play/controllers/play/sync', ['exports', 'ember'], function (exports, _ember) {

  var PlaySyncController = _ember['default'].Controller.extend({
    needs: ['playSyncWaypoints', 'playSyncUploads'],
    numUploadsCompleted: _ember['default'].computed.alias('controllers.playSyncUploads.numCompleted'),
    numUploadsWaiting: _ember['default'].computed.alias('controllers.playSyncUploads.numWaiting'),
    numWaypointsSynced: _ember['default'].computed.alias('controllers.playSyncWaypoints.numCompleted')
  });

  exports['default'] = PlaySyncController;
});