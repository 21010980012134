define('play/routes/play-mode/home', ['exports'], function (exports) {
  var PlayModeHomeRoute = Breadcrumb.Route.extend({
    afterModel: function afterModel() {
      // Do initial check for default mission here. So assuming everything is
      // loaded, catch it before setting up controllers so the redirect happens
      // pre-load rather than post.
      this.goToDefaultMission();
    },

    setupController: function setupController() {
      // check here in case this is on initial load; default mission must be
      // caught on setupController since controllers aren't loaded when
      // afterModel hook is called. If default mission was presented, don't send
      // homeLoaded signal.
      if (this.goToDefaultMission()) {
        return;
      }
      var modeName = this.modelFor('playMode');
      var mode = this.controllerFor('playModes').findBy('modeName', modeName);
      var modeType = mode.get('modeType');
      var controllerName = 'playMode%@Home'.fmt(modeType.classify());
      var homeController = this.controllerFor(controllerName);
      homeController.send('homeLoaded');
    },

    renderTemplate: function renderTemplate(controller) {
      var modeName = this.modelFor('playMode');
      var mode = this.controllerFor('playModes').findBy('modeName', modeName);
      var modeType = mode.get('modeType');
      var controllerName = 'playMode%@Home'.fmt(modeType.classify());
      this.render(controllerName, { into: 'playthrough', controller: controllerName });
    },

    goToDefaultMission: function goToDefaultMission() {
      var modeName = this.modelFor('playMode');
      var mode = this.controllerFor('playModes').findBy('modeName', modeName);
      if (!mode) {
        return false;
      } // not loaded yet -- if initial load.
      var defaultMission = mode.getDefaultMission();
      if (defaultMission) {
        this.transitionTo('playMode.waypoint', defaultMission);
        return true;
      }
      return false;
    },

    actions: {
      didTransition: function didTransition() {
        if (!BREADCRUMB_ENV.TESTING) {
          this.controllerFor('identity').get('team').safeReload();
        }
      }
    }
  });

  exports['default'] = PlayModeHomeRoute;
});