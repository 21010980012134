define('play/routes/play-mode/waypoint', ['exports'], function (exports) {
    var PlayModeWaypointRoute = Breadcrumb.Route.extend({
        controllerName: 'playWaypoint',
        afterModel: function afterModel(model) {
            if (model.get('completedAt')) {
                this.transitionTo('playMode.home');
            }
        },
        renderTemplate: function renderTemplate(controller, model) {
            var modeName = this.modelFor('playMode');
            var mode = this.controllerFor('playModes').findBy('modeName', modeName);
            var modeType = mode.get('modeType');
            var controllerName = 'playMode%@Waypoint'.fmt(modeType.classify());
            this.render(controllerName, {
                into: 'playthrough',
                controller: controllerName
            });
            this.render('playWaypoint', { into: controllerName });
        },
        actions: {
            completed: function completed() {
                var playWaypointController = this.controllerFor('playWaypoint'),
                    modeName = this.modelFor('playMode'),
                    modeControllers = this.controllerFor('playModes'),
                    modeController = modeControllers.findBy('modeName', modeName),
                    completedWaypoint = playWaypointController.get('content'),
                    activeWaypoints = modeController.get('activeWaypoints'),
                    nextWaypoint = null;
                // First see if we have any subsequent steps to the same mission
                // that the completed waypoint was on. If so, proceed to those.
                var subsequentWaypoints = activeWaypoints.filter(function (w) {
                    return w.get('mission') === completedWaypoint.get('mission') && w.get('stepNum') > completedWaypoint.get('stepNum');
                });
                if (subsequentWaypoints.length) {
                    nextWaypoint = subsequentWaypoints[0];
                } else if (this.controller.get('isRevealingAnswers')) {
                    // if we're revealing answers, don't go home -- just go to the next
                    // mission
                    nextWaypoint = activeWaypoints[0];
                } else {
                    // if no subsequent mission, go back home rather than proceed.
                    nextWaypoint = null;
                }

                if (nextWaypoint) {
                    this.transitionTo('playMode.waypoint', nextWaypoint);
                } else {
                    if (modeName === 'anytime') {
                        this.transitionTo('playMode.home', modeName);
                        return false;
                    }
                    this.transitionTo('play.home');
                }
            },
            didTransition: function didTransition() {
                var _this = this;

                if (this.controller.isRendered) {
                    // set to queue and run after insert view's html
                    setTimeout(function () {
                        _this.controller.mapInstructionLinks();
                    });
                }
                return true;
            }
        }
    });

    exports['default'] = PlayModeWaypointRoute;
});