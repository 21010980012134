define('play/controllers/play-modes', ['exports', 'ember'], function (exports, _ember) {

  var PlayModesController = _ember['default'].ArrayController.extend({
    needs: ['playthrough', 'playSyncWaypoints', 'playRole', 'identity'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    game: _ember['default'].computed.oneWay('playthrough.game'),

    content: (function () {
      // you don't want this to update with each routing update.
      return this.get('playthrough.game.routing.modes');
    }).property('playthrough'),

    modesDidChange: (function () {
      // update mode content in an observer rather than changing content
      // because you don't want to destroy and recreate the controllers, just
      // update their content.
      var modes = this.get('game.routing.modes');
      this.forEach(function (mode, modeIndex) {
        mode.set('content', modes[modeIndex]);
      });
    }).observes('playthrough.game.routing.modes'),

    lookupItemController: function lookupItemController(mode) {
      return 'playMode%@'.fmt(mode.type.classify());
    }
  });

  exports['default'] = PlayModesController;
});