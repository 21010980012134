define('play/routes/gallery/teams', ['exports', 'play/routes/gallery'], function (exports, _playRoutesGallery) {

  var GalleryTeamsRoute = _playRoutesGallery['default'].extend({
    model: function model() {
      return null;
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var playthroughID = this.modelFor('playthrough').playthrough.get('id');
      controller.load(this.store.find('team', { playthrough: playthroughID, is_archived: false }));
    }
  });

  exports['default'] = GalleryTeamsRoute;
});