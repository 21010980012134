define('play/controllers/gallery-waypoints', ['exports', 'ember'], function (exports, _ember) {

  var GalleryWaypointsController = _ember['default'].ArrayController.extend({
    itemController: 'galleryWaypoint',
    sortFunction: function sortFunction(a, b) {
      // sort such that profile photo is first, bonus cam is last
      return (a === 'profile_photo' ? -1 : 1) + (b === 'profile_photo' ? 1 : -1) + (a === 'camera' ? 1 : -1) + (b === 'camera' ? -1 : 1);
    },
    sortProperties: ['modeName', 'step.behaviorName']
  });

  exports['default'] = GalleryWaypointsController;
});