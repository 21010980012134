define("play/views/play-behavior", ["exports", "ember"], function (exports, _ember) {

    var forEach = _ember["default"].EnumerableUtils.forEach,
        get = _ember["default"].get,
        map = _ember["default"].EnumerableUtils.map;

    var RANDOM_WORDS = ["Amaranth", "Arrowroot", "Banana Squash", "Bell Pepper", "Black Eyed Peas", "Black Radish", "Bok Choy", "Broccoflower", "Broccolini", "Burdock Root", "Cabbage", "Carrots", "Celeriac", "Celery", "Cherry Tomato", "Chinese Eggplant", "Galangal Root", "Leek", "Lettuce", "Mushroom", "Olive", "Onion", "Parsnip", "Pearl Onion", "Potato", "Rutabaga", "Salad Savoy", "Snow Pea", "Wasabi Root", "Yucca Root", "Artichoke", "Asparagus", "Belgian Endive", "Broccoli", "Butter Lettuce", "Cactus", "Chayote Squash", "Chive", "Collard Greens", "Corn", "Fava Bean", "Fennel", "Fiddlehead Fern", "Green Bean", "Manoa Lettuce", "Morel Mushroom", "Mustard Greens", "Pea Pod", "Pea", "Purple Asparagus", "Radicchio", "Ramps", "Red Leaf Lettuce", "Rhubarb", "Snow Pea", "Sorrel", "Spinach", "Spring Baby Lettuce", "Swiss Chard", "Vidalia Onion", "Watercress", "Acorn Squash", "Belgian Endive", "Black Salsify", "Broccoli", "Brussels Sprouts", "Butter Lettuce", "Buttercup Squash", "Butternut Squash", "Cauliflower", "Chayote Squash", "Chinese Long Bean", "Delicata Squash", "Diakon Radish", "Endive", "Garlic", "Ginger", "Jalapeno Pepper", "Jerusalem Artichoke", "Kohlrabi", "Pumpkin", "Radicchio", "Sweet Dumpling Squash", "Sweet Potato", "Swiss Chard", "Turnip", "Winter Squash"];

    function randomWord() {
        return randomChoice(RANDOM_WORDS);
    }
    function randomNumber() {
        return Math.floor(Math.random() * 100);
    }
    function randomChoice(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    var Behavior = _ember["default"].Object.extend({
        autosubmit: false,
        showSubmit: true,
        waitForCompletion: false,
        successResponse: 'success',
        asyncSuccessResponse: 'ok',

        validate: function validate(submission) {
            return true;
        },
        interpret: function interpret(submission, attemptNum) {
            return this.successResponse;
        },
        interpretAsyncComplete: function interpretAsyncComplete(result) {
            return this.asyncSuccessResponse;
        },

        correct: (function () {
            return this.getCorrect();
        }).property('content'),
        getCorrect: function getCorrect() {
            return [];
        },

        getSubmissionText: function getSubmissionText(submission) {
            if (!submission || !submission[0]) {
                return "";
            }
            return (submission[0].text || submission[0].number || "").toString();
        },

        annotateMedia: function annotateMedia(media, submission) {},
        getResponse: function getResponse(submission, attemptNum) {
            var responseType = this.interpret(submission, attemptNum);
            _ember["default"].assert("Must have a message for response type " + responseType, !!this.messages[responseType]);
            var responseParams = this.messages[responseType];
            var isCompleted = !!responseParams.completed;
            var shouldSync = isCompleted || responseParams.sync === true;
            var shouldUpload = shouldSync || responseParams.upload === true;
            var message = responseParams.message ? {
                message: responseParams.message,
                style: responseParams.style,
                correct: this.getCorrectText(),
                given: this.getSubmissionText(submission)
            } : null;
            return {
                isCompleted: isCompleted,
                shouldSync: shouldSync,
                shouldUpload: shouldUpload,
                message: message
            };
        },

        // by default success is indeterminate... specific behaviors can
        // override this to return a definitely value of points.
        getSuccess: function getSuccess(submission, attemptNum) {
            return null;
        },

        getAsyncCompleteMessage: function getAsyncCompleteMessage(result) {
            var responseType = this.interpretAsyncComplete(result);
            if (!responseType || !this.messages[responseType]) {
                return null;
            }
            var responseParams = this.messages[responseType];
            var message = {
                message: responseParams.message,
                style: responseParams.style
            };
            return message;
        },

        // this is also by default indeterminate, but can be overridden to
        // allow showing of points values earned.
        getAsyncCompleteSuccess: function getAsyncCompleteSuccess(result) {
            return null;
        },

        getCorrectText: function getCorrectText() {
            var correct = this.getCorrect();
            if (!correct || !correct[0]) {
                return "";
            }
            return (correct[0].text || correct[0].number || "").toString();
        },

        numAttemptsAllowed: _ember["default"].computed.oneWay('content.num_attempts'),
        areAttemptsLimited: _ember["default"].computed.bool('numAttemptsAllowed'),

        isLastAttempt: function isLastAttempt(attemptNum) {
            if (!this.get('areAttemptsLimited')) {
                return false;
            }
            return attemptNum >= this.get('numAttemptsAllowed');
        },

        // by default, all media goes to gallery
        //    annotateMedia: function (media, submission) {
        //        media.forEach(function (mediaItem) {
        //            mediaItem.in_gallery = true;
        //        });
        //    },

        messages: {
            invalid: {
                message: 'mission.code.invalid.tryagain',
                style: 'unsuccessful'
            },
            invalidFinal: {
                message: 'mission.code.invalid.final',
                style: 'unsuccessful', completed: true
            },
            tryagain: { message: 'mission.incorrect.tryagain', style: 'unsuccessful' },
            failure: {
                message: 'mission.incorrect.final', style: 'unsuccessful',
                completed: true
            },
            ok: { message: 'mission.ok', style: 'successful', completed: true },
            success: {
                message: 'mission.correct', style: 'successful',
                completed: true
            }
        },

        footnote: (function () {
            var key = 'mission.footnotes.' + this.name;
            if (!_ember["default"].I18n.exists(key)) {
                return null;
            }
            return _ember["default"].I18n.t(key);
        }).property()
    });
    /**
     INFO BEHAVIOR
     */
    var InfoBehavior = Behavior.extend({
        // always successful
        successResponse: 'ok',
        getSuccess: function getSuccess(submission, attemptNum) {
            return 1;
        }
    });
    var StallBehavior = Behavior.extend({
        successResponse: 'ok',
        showSubmit: false,
        getSuccess: function getSuccess(submission, attemptNum) {
            return 1;
        }
    });
    /**
     VERIFICATION BEHAVIORS
     */
    function processWord(word) {
        return word.trim().toLowerCase().replace(/[\!\,\.\;\?\-\_]/g, '');
    }

    function splitWords(sentence) {
        return map(sentence.split(" "), processWord);
    }

    function isSubset(subArr, superArr) {
        // If sub is a subset of super,
        // then each member in sub must be present in super
        return subArr.every(function (val) {
            return superArr.indexOf(val) >= 0;
        });
    }

    function checkAnswer(given, correct) {
        // Accecptable answers are determined by:
        //   splitting the specified correct answers by commas,
        //   stripping away any punctuation from the given guess,
        //   splitting both the correct answers and given guesses by spaces,
        //   checking for exact, partial, and subset matches both ways.
        if (!correct || !given) {
            return false;
        }
        // fix for support-371, replace UTF-8 quotation marks to single quote
        given = given.replace(/[’‘“”]/, "'");
        // console.log("checkAnswer: given=", given, "correct=", correct);
        var correctAnswers = map(correct.split(","), processWord),
            givenWords = splitWords(given),
            correctWords,
            isOk = false;

        // console.log(correctAnswers);
        // console.log(givenWords);

        forEach(correctAnswers, function (correctAnswer) {
            if (!isOk) {
                var ratio = window.fuzzball.ratio(given.toLowerCase(), correctAnswer.toLowerCase());
                // console.log("checkAnswer: ratio=", ratio, "given=", given, "correctAnswer=", correctAnswer);
                if (ratio >= 90) {
                    isOk = true;
                    return;
                }
                correctWords = splitWords(correctAnswer);
                // console.log(correctWords);

                if (given.indexOf(correctAnswer) > -1) {
                    isOk = true;
                    return;
                    // console.log("given.indexOf(correctAnswer) ok");
                }

                forEach(givenWords, function (givenWord) {
                    // console.log(givenWord, correctAnswer);

                    if (givenWord === correctAnswer) {
                        isOk = true;
                        return;
                        // console.log("givenWord === correctAnswer ok");
                    }
                    if (givenWord.indexOf(correctAnswer) > -1) {
                        isOk = true;
                        return;
                        // console.log("givenWord.indexOf(correctAnswer) ok");
                    }
                });
                if (isSubset(correctWords, givenWords) || isSubset(givenWords, correctWords)) {
                    // console.log("isSubset(correctWords, givenWords) ok");
                    isOk = true;
                    return;
                }
            }
        });

        return isOk;
    }

    var verification = _ember["default"].Mixin.create({
        successResponse: 'success',
        interpret: function interpret(submission, attemptNum) {
            var isCorrect = this.isCorrect(submission);
            if (isCorrect) {
                return this.successResponse;
            }
            return this.isLastAttempt(attemptNum) ? 'failure' : 'tryagain';
        },

        getSuccess: function getSuccess(submission, attemptNum) {
            if (!this.isCorrect(submission)) {
                return 0;
            }
            var numAttemptsAllowed = this.get('numAttemptsAllowed') || 3;
            var success = 1 - 1.0 / numAttemptsAllowed * Math.max(0, attemptNum - 1);
            return success;
        }
    });

    var unverifiedNumber = _ember["default"].Mixin.create(verification, {
        isCorrect: function isCorrect(submission) {
            return !_ember["default"].isEmpty(submission && submission[0].number);
        },
        getCorrect: function getCorrect() {
            return [{ number: randomNumber() }];
        }
    });

    var unverifiedText = _ember["default"].Mixin.create(verification, {
        isCorrect: function isCorrect(submission) {
            return !_ember["default"].isEmpty(submission && submission[0].text);
        },
        getCorrect: function getCorrect() {
            return [{ text: randomWord() }];
        }
    });
    var unverifiedChoice = _ember["default"].Mixin.create(verification, {
        isCorrect: function isCorrect(submission) {
            return !_ember["default"].isEmpty(submission && submission[0].choice);
        },
        getCorrect: function getCorrect() {
            return [{ number: randomNumber() }];
        }
    });
    var TextAnswerBehavior = Behavior.extend(verification, {
        getCorrect: function getCorrect() {
            var correct = this.content.correct || "";
            return [{ text: correct.split(',')[0] }];
        },
        isCorrect: function isCorrect(submission) {
            return checkAnswer(submission[0].text, this.content.correct);
        }
    });
    var TextUnverifiedBehavior = Behavior.extend(unverifiedText, {});
    var NumberAnswerBehavior = Behavior.extend(verification, {
        getCorrect: function getCorrect() {
            return [{ number: this.content.correct_number }];
        },
        isCorrect: function isCorrect(submission) {
            return submission[0].number === this.content.correct_number;
        }
    });
    var NumberUnverifiedBehavior = Behavior.extend(unverifiedNumber, {});
    var MultipleChoiceBehavior = Behavior.extend(verification, {
        isCorrect: function isCorrect(submission) {
            return !!this.getChoice(submission[0].choice).checked;
        },
        getCorrect: function getCorrect() {
            return [{ choice: this.getCorrectIndex() }];
        },
        getCorrectIndex: function getCorrectIndex() {
            var firstCorrect = 0,
                content = this.content;
            for (var i = 0; i < content.choices.length; i++) {
                if (content.choices[i].checked) {
                    firstCorrect = i;
                    break;
                }
            }
            return firstCorrect;
        },
        getChoice: function getChoice(index) {
            return this.content.choices[index];
        },
        getSubmissionText: function getSubmissionText(submission) {
            return this.getChoice(submission[0].choice).text;
        },
        getCorrectText: function getCorrectText() {
            return this.getChoice(this.getCorrectIndex()).text;
        }
    });
    var ManyChoicesBehavior = Behavior.extend(verification, {
        getCorrectIncexes: function getCorrectIncexes() {
            var choices = this.content.choices;
            var correct_indexes = [];
            for (var i in choices) {
                if (choices[i].checked) {
                    correct_indexes[correct_indexes.length] = parseInt(i, 10);
                }
            }
            return correct_indexes;
        },
        isCorrect: function isCorrect(submission) {
            var chosen_indexes = submission[0].checkboxes;
            var correct_indexes = this.getCorrectIncexes();
            return chosen_indexes.length === correct_indexes.length && chosen_indexes.every(function (i) {
                return correct_indexes.includes(i);
            });
        },
        getCorrect: function getCorrect() {
            return [{ checkboxes: this.getCorrectIncexes() }];
        },
        getChoice: function getChoice(index) {
            return this.content.choices[index];
        },
        getSubmissionText: function getSubmissionText(submission) {
            var texts = [];
            for (var i = 0; i < submission[0].checkboxes.length; i++) {
                texts[texts.length] = this.getChoice(submission[0].checkboxes[i]).text;
            }
            return texts.join(" and ");
        },
        getCorrectText: function getCorrectText() {
            var texts = [];
            var correct_indexes = this.getCorrectIncexes();
            for (var i = 0; i < correct_indexes.length; i++) {
                texts[texts.length] = this.getChoice(correct_indexes[i]).text;
            }
            return texts.join(" and ");
        }
    });

    var SurveyBehavior = Behavior.extend(unverifiedChoice, {});
    var MultipleChoicePointsBehavior = Behavior.extend({
        isCorrect: function isCorrect(submission) {
            return this.getPoints(submission) > 0;
        },
        getChoice: function getChoice(index) {
            return this.content.choices_with_points[index];
        },
        getPoints: function getPoints(submission) {
            if (!submission || !submission.length) {
                return 0;
            }
            return this.getChoice(submission[0].choice).number;
        },
        interpret: function interpret(submission, attemptNum) {
            return this.isCorrect(submission) ? 'success' : 'failure';
        }
    });
    /**
     CREATIVE BEHAVIORS
     */

    var DUMMY_VIDEO = { file: 'dummy' };
    var DUMMY_IMAGE = { file: 'dummy' };

    var creative = _ember["default"].Mixin.create({
        interpret: function interpret(submission, attemptNum) {
            return 'ok';
        },
        areAttemptsLimited: (function () {
            return false;
        }).property(),

        // if these missions have simple voting, then full points right away.
        // otherwise, this isn't called.
        getSuccess: function getSuccess() {
            return 1;
        },
        getCorrect: function getCorrect() {
            return [DUMMY_IMAGE];
        }
    });

    var simpleCreative = _ember["default"].Mixin.create(creative, {
        autosubmit: true // simple one-media uploads submit right away.
    });

    var creativeWithCaption = _ember["default"].Mixin.create(creative, {
        extraOptions: {
            maxlength: 100
        },
        annotateMedia: function annotateMedia(media, submission) {
            this._super(media, submission);
            var s;
            for (var i = 0, j = media.length; i < j; i += 1) {
                s = submission[i * 2 + 1];
                if (s && s.text) {
                    media[i].caption = s.text;
                }
            }
        },
        getCorrect: function getCorrect() {
            return [DUMMY_IMAGE, { text: randomWord() }];
        }
    });

    var PhotoBehavior = Behavior.extend(simpleCreative, {});
    var PhotoAndTextBehavior = Behavior.extend(creativeWithCaption, {});
    var PhotoPairBehavior = Behavior.extend(creative, {
        getCorrect: function getCorrect() {
            return [DUMMY_IMAGE, DUMMY_IMAGE];
        }
    });
    var PhotoAndTextPairBehavior = Behavior.extend(creativeWithCaption, {
        getCorrect: function getCorrect() {
            return [DUMMY_IMAGE, { text: randomWord() }, DUMMY_IMAGE, { text: randomWord() }];
        }
    });
    var PhotoSequenceBehavior = Behavior.extend(creative, {});
    var PhotoAndTextSequenceBehavior = Behavior.extend(creativeWithCaption, {});
    var MovieBehavior = Behavior.extend(creative, {});
    var MovieAndTextBehavior = Behavior.extend(creativeWithCaption, {});
    var MoviePairBehavior = Behavior.extend(creative, {});
    var CameraBehavior = Behavior.extend(simpleCreative, {});
    /**
     ENDLESS CREATIVE BEHAVIORS
     */
    var endlessCreative = _ember["default"].Mixin.create(creative, {
        autosubmit: true,
        messages: {
            ok: { message: null, completed: false, sync: false, upload: true }
        }
    });

    var EndlessPhotoBehavior = Behavior.extend(endlessCreative, {});
    var EndlessMovieBehavior = Behavior.extend(endlessCreative, {});
    var EndlessCameraBehavior = Behavior.extend(endlessCreative, {});
    /**
     CODE BEHAVIORS
     */
    var code = _ember["default"].Mixin.create({
        codeType: 'text',

        areAttemptsLimited: (function () {
            return true;
        }).property(),
        numAttemptsAllowed: (function () {
            return 3;
        }).property(),

        getCorrect: function getCorrect() {
            var correct = {};
            correct[this.codeType] = randomChoice(get(this, 'codes'));
            return [correct];
        },

        getCodeIndex: function getCodeIndex(submission) {
            var codeIndex = -1,
                submitted = (submission[0].text || submission[0].number).toString().trim().toLowerCase();
            forEach(get(this, 'codes'), function (code, i) {
                if (code.toString().toLowerCase() === submitted) {
                    codeIndex = i;
                }
            }, this);
            return codeIndex;
        },

        interpret: function interpret(submission, attemptNum) {
            var ok = this.getCodeIndex(submission) > -1;
            if (ok) {
                return 'success';
            }
            return this.isLastAttempt(attemptNum) ? 'invalidFinal' : 'invalid';
        },

        getSuccess: function getSuccess(submission, attemptNum) {
            var codeIndex = this.getCodeIndex(submission);
            if (codeIndex === -1) {
                return 0;
            }
            var numCodes = this.get('codes').length;
            return (codeIndex + 1) / numCodes;
        }
    });

    var CodeStandardBehavior = Behavior.extend(code, {
        codeType: 'number',
        codes: [1111, 2111, 3111, 4111, 5111, 6111]
    });
    var CodeCustomBehavior = Behavior.extend(code, {
        codes: (function () {
            return this.content.codes || [];
        }).property()
    });
    /**
     NORMALIZED SCORE BEHAVIORS
     */
    var plantScore = _ember["default"].Mixin.create({
        interpret: function interpret(submission, attemptNum) {
            var content = this.content;
            var plantPassword = content && content.plant_password;
            if (!plantPassword) {
                return 'success';
            }
            var ok = checkAnswer(submission[1].text, plantPassword);
            if (ok) {
                return 'success';
            }
            return this.isLastAttempt(attemptNum) ? 'invalidFinal' : 'invalid';
        },
        getCorrect: function getCorrect() {
            return [{ number: parseInt(Math.random() * 1000, 10) }, { text: this.content.plant_password }];
        }
    });

    var PlantScoreHighestBehavior = Behavior.extend(plantScore, {});
    var PlantScoreLowestBehavior = Behavior.extend(plantScore, {});
    var ClosestNumberBehavior = Behavior.extend(unverifiedNumber, {});
    /**
     PROFILE BEHAVIORS
     */
    var profileText = _ember["default"].Mixin.create(unverifiedText, { successResponse: 'ok' });
    var profileNumber = _ember["default"].Mixin.create(unverifiedNumber, { successResponse: 'ok' });

    var worksheet = _ember["default"].Mixin.create(unverifiedText, { successResponse: 'ok' });

    var ProfileTeamNameBehavior = Behavior.extend(profileText, {});
    var WorksheetBehavior = Behavior.extend(worksheet, {});
    var ProfilePhoneBehavior = Behavior.extend(profileNumber, {});
    var ProfileOtherBehavior = Behavior.extend(profileText, {});
    var ProfileOtherNumberBehavior = Behavior.extend(profileNumber, {});
    var ProfilePhotoBehavior = Behavior.extend({
        successResponse: 'ok',
        autosubmit: true, // photo missions just get submitted right away
        // profile photo doesn't go to gallery.
        //    annotateMedia: function (media, submission) {
        //        media.forEach(function (mediaItem) {
        //            mediaItem.in_gallery = false;
        //        });
        //    },
        getCorrect: function getCorrect() {
            return [DUMMY_IMAGE];
        }
    });
    var ProfileMultipleChoiceBehavior = Behavior.extend(profileText, {
        isCorrect: function isCorrect(submission) {
            return true;
        },
        getCorrect: function getCorrect() {
            return [{ choice: randomChoice(this.content.choices_with_values) }];
        }
    });
    /**
     LINKED BEHAVIORS
     */
    var linkedBehavior = _ember["default"].Mixin.create({
        waitForCompletion: true,
        waitMessage: 'mission.linked.wait',
        completedMessage: 'mission.linked.completed'
    });
    var LinkedHeadToHeadBehavior = Behavior.extend(linkedBehavior, {
        autosubmit: true, // since it's just a button.
        interpret: function interpret(submission, attemptNum) {
            return 'waiting';
        },
        messages: {
            waiting: { completed: false, sync: true }, // no visible message
            disagreed: { style: 'unsuccessful', message: 'mission.linked.disagreed' },
            won: { style: 'successful', message: 'mission.linked.won' },
            lost: { style: 'unsuccessful', message: 'mission.linked.lost' }
        },

        interpretAsyncComplete: function interpretAsyncComplete(result) {
            // don't show any message if don't have data
            if (!result || result.agreed === undefined) {
                return null;
            }
            // otherwise show result.
            if (!result.agreed) {
                return 'disagreed';
            }
            return result.successful ? 'won' : 'lost';
        }
    });

    var RedirectBehavior = Behavior.extend({
        getResponse: function getResponse(submission, attemptNum) {
            // console.log('RedirectBehavior.getResponse', submission);
            // console.log('RedirectBehavior.current url:', window.location);
            //http://localhost:5051/play/#/?login[c]=
            var pid = this.content.login_codes[submission[0].choice].value;
            Breadcrumb.localStorage.clearMany(["breadcrumb-save-playthrough-id", "breadcrumb-save-team-id", "breadcrumb-save-role-index", "breadcrumb-save-game-title", "breadcrumb-save-date"]);
            var new_hash = "#/?c=ZZZ&pid=" + pid;
            window.location.hash = new_hash;
            return {
                isCompleted: true,
                shouldSync: true,
                shouldUpload: false,
                message: null
            };
        }
    });

    var RedirectEmailsBehavior = Behavior.extend({
        getResponse: function getResponse(submission, attemptNum) {
            // console.log('RedirectEmailsBehavior.getResponse', submission, this.content);
            //http://localhost:5051/play/#/?login[c]=
            var res = {
                isCompleted: false,
                shouldSync: false,
                shouldUpload: false,
                message: this.messages['tryagain']
            };
            if (!submission[0] || !submission[0].text) {
                return res;
            }

            var corrs = this.content.redirect_emails.split("\n");
            var pid = false;
            while (!pid && corrs.length > 0) {
                var str = corrs.pop().split(",");
                if (submission[0].text.toLowerCase() === str[0]) {
                    pid = str[1];
                }
            }
            if (!pid && attemptNum >= 3) {
                res.message["messageText"] = "Whoops. Looks like something went wrong. " + "Please find a Go Game team member to help you login to the game";
            }
            if (pid) {
                res.message = null;
                res.isCompleted = false;
                res.shouldSync = true;
                Breadcrumb.localStorage.clearMany(["breadcrumb-save-playthrough-id", "breadcrumb-save-team-id", "breadcrumb-save-role-index", "breadcrumb-save-game-title", "breadcrumb-save-date"]);
                // console.log('RedirectBehavior.isCorrect, pid=', pid);
                // console.log('RedirectBehavior.isCorrect redirecting, new_url=', new_url);
                var new_hash = "#/?c=ZZZ&pid=" + pid;
                window.location.hash = new_hash;
            }
            return res;
        }
    });

    exports["default"] = {
        Behavior: Behavior,
        InfoBehavior: InfoBehavior,
        StallBehavior: StallBehavior,
        TextAnswerBehavior: TextAnswerBehavior,
        TextUnverifiedBehavior: TextUnverifiedBehavior,
        NumberAnswerBehavior: NumberAnswerBehavior,
        NumberUnverifiedBehavior: NumberUnverifiedBehavior,
        MultipleChoiceBehavior: MultipleChoiceBehavior,
        ManyChoicesBehavior: ManyChoicesBehavior,
        MultipleChoicePointsBehavior: MultipleChoicePointsBehavior,
        PhotoBehavior: PhotoBehavior,
        PhotoAndTextBehavior: PhotoAndTextBehavior,
        PhotoPairBehavior: PhotoPairBehavior,
        PhotoAndTextPairBehavior: PhotoAndTextPairBehavior,
        PhotoSequenceBehavior: PhotoSequenceBehavior,
        PhotoAndTextSequenceBehavior: PhotoAndTextSequenceBehavior,
        MovieBehavior: MovieBehavior,
        MovieAndTextBehavior: MovieAndTextBehavior,
        MoviePairBehavior: MoviePairBehavior,
        CameraBehavior: CameraBehavior,
        EndlessPhotoBehavior: EndlessPhotoBehavior,
        EndlessMovieBehavior: EndlessMovieBehavior,
        EndlessCameraBehavior: EndlessCameraBehavior,
        CodeStandardBehavior: CodeStandardBehavior,
        CodeCustomBehavior: CodeCustomBehavior,
        PlantScoreHighestBehavior: PlantScoreHighestBehavior,
        PlantScoreLowestBehavior: PlantScoreLowestBehavior,
        ClosestNumberBehavior: ClosestNumberBehavior,
        ProfileTeamNameBehavior: ProfileTeamNameBehavior,
        ProfilePhoneBehavior: ProfilePhoneBehavior,
        ProfileOtherBehavior: ProfileOtherBehavior,
        ProfileOtherNumberBehavior: ProfileOtherNumberBehavior,
        ProfilePhotoBehavior: ProfilePhotoBehavior,
        ProfileMultipleChoiceBehavior: ProfileMultipleChoiceBehavior,
        SurveyBehavior: SurveyBehavior,
        LinkedHeadToHeadBehavior: LinkedHeadToHeadBehavior,
        WorksheetBehavior: WorksheetBehavior,
        RedirectBehavior: RedirectBehavior,
        RedirectEmailsBehavior: RedirectEmailsBehavior
    };
});