define('play/views/submission-text-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionTextWidget = _playViewsSubmissionBaseWidget['default'].extend({
    blankSubmission: { text: '' },

    emailFormat: function emailFormat(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    },

    placeholder: (function () {
      return this.get("options.placeholder") || this.get('options.default_placeholder');
    }).property(),

    maxlength: (function () {
      return this.get("options.maxlength") || 999;
    }).property(),

    validate: function validate(submission) {
      var value = _ember['default'].get(submission, 'text');
      if (this.get('options.check_format') && !_ember['default'].isEmpty(value)) {
        var validator = this.get('options.check_format') + 'Format';
        _ember['default'].assert("Must have a validator for format " + this.get('options.check_format'), !!this.get(validator));
        return this.get(validator)(value);
      }
      if (this.get('options.optional')) {
        return true;
      }
      return !_ember['default'].isEmpty(_ember['default'].get(submission, 'text')) && !_ember['default'].isEmpty(_ember['default'].get(submission, 'text').trim());
    },
    textDidChange: (function () {
      this.performValidation();
    }).observes('submission.text'),
    template: _ember['default'].Handlebars.compile('{{view Ember.TextField class="input-block-level" ' + 'disabledBinding="controller.isUpdating" ' + 'placeholderBinding="view.placeholder" ' + 'maxlengthBinding="view.maxlength" ' + 'action="submit"' + 'valueBinding="view.submission.text"}}')
  });

  exports['default'] = SubmissionTextWidget;
});