define('play/views/submission-movie-widget', ['exports', 'play/views/submission-media-widget', 'ember'], function (exports, _playViewsSubmissionMediaWidget, _ember) {

  var SubmissionMovieWidget = _playViewsSubmissionMediaWidget['default'].extend({
    accept: 'video/*',
    mediaTypeTitle: 'video',
    isVideo: true,
    isPlaying: false,
    hasReviewed: false,
    mimeTypes: ['video/mpeg', 'video/mp4', 'video/quicktime', 'video/3gpp', 'video/ogg', 'video/x-flv', 'video/webm', 'video/avi'],

    videoDuration: false,

    clearVideoDuration: (function () {
      this.set('videoDuration', false);
    }).observes('file'),

    isWebReviewEnabled: (function (key, val) {
      // console.log('isWebReviewEnabled, args:', arguments);
      if (arguments.length > 1) {
        // console.log('isWebReviewEnabled return val:', val);
        return val;
      } else {
        // if phone is admined disable web review
        if (this.get('controller.admin')) {
          return false;
        }
        // if is native app disable web review
        if (Breadcrumb.NativeHooks.useNativeMedia()) {
          return false;
        }
        return true;
      }
    }).property('controller.admin', 'file'),

    didInsertElement: function didInsertElement() {
      this._super();
      if (!this.get('isWebReviewEnabled')) {
        return;
      }

      var video = this.$('.video-review').get(0);
      var self = this;
      video.addEventListener('loadedmetadata', function () {
        var duration = video.duration;
        // console.log('video loadedmetadata', duration);
        self.set('videoDuration', Math.floor(duration));
      });
      video.addEventListener('playing', function () {
        self.set('isPlaying', true);
      }, false);
      video.addEventListener('pause', function () {
        self.set('isPlaying', false);
      }, false);
      video.addEventListener('ended', function () {
        self.set('isPlaying', false);
        self.set('hasReviewed', true);
      }, false);
      video.addEventListener('error', function (err) {
        if (self.get('isReady')) {
          self.set('isWebReviewEnabled', false);
          console.error(video.error);
        }
      }, false);
    },

    isReviewReady: (function () {
      return this.get('isWebReviewEnabled') && this.get('isReady');
    }).property('isWebReviewEnabled', 'isReady'),

    isHighQualityVideo: (function () {
      var videoQuality = this.get('controller.step.content.video_quality') || this.get('controller.playthrough.game.options.video_quality');
      var val = videoQuality && ['very high', 'best'].contains(videoQuality);
      this.set('controller.isHighQualityVideo', val);
      return val;
    }).property('controller.step.content.video_quality', 'controller.playthrough.game.options.video_quality'),

    nativeOptions: (function () {
      var options = this._super();
      // if(Breadcrumb.NativeHooks.useNativeMedia()) {
      var step = this.get('controller.step.content');
      var gameOptions = this.get('controller.controllers.gameOptions');

      // use value defined on the mission step
      // else use gameOptions value
      // which returns value defined on the game
      // or default defined in behavior
      var maxLength = _ember['default'].get(step, 'video_max_length') || gameOptions.get('videoMaxLength');
      var quality = _ember['default'].get(step, 'video_quality') || gameOptions.get('videoQuality');
      var usePauseRecord = _ember['default'].get(step, 'video_pause_record');
      if (typeof usePauseRecord === 'undefined') {
        usePauseRecord = gameOptions.get('videoPauseRecord') || false;
      }
      var disableRotation = _ember['default'].get(step, 'video_disable_rotation');
      if (typeof disableRotation === 'undefined') {
        disableRotation = gameOptions.get('videoDisableRotation') || false;
      }
      options = $.extend(options, {
        maxLength: maxLength,
        usePauseRecord: usePauseRecord,
        disableRotation: disableRotation,
        useCompression: quality !== 'best',
        quality: quality,
        waypointID: this.get('controller.waypoint.id'),
        playthroughID: this.get('controller.playthrough.id')
      });
      console.log('submission-movie-widget, nativeOptions:', options);
      return options;
      // } else { return options; }
    }).property('_super.nativeOptions'),

    getNativeMedia: function getNativeMedia() {
      var options = this.get('nativeOptions');
      this.showAndroidVideoWarning();
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        Breadcrumb.NativeHooks.getVideo(resolve, reject, options);
      });
    },

    source: (function () {
      if (this.get('isWebReviewEnabled')) {
        var file = this.get('file');
        var url = window.URL || window.webkitURL;
        if (!file || !url || !url.createObjectURL) {
          return null;
        }
        this.set('hasReviewed', false);
        return url.createObjectURL(file);
      } else {
        this.set('hasReviewed', true);
        return "";
      }
    }).property('file'),

    callValidation: (function () {
      this.performValidation();
    }).observes('hasReviewed', 'isWebReviewEnabled', 'videoDuration'),

    validate: function validate(submission) {
      this.set('isError', false);
      var options = this.get('nativeOptions');
      console.log('validate', options);
      console.log('videoDuration=', this.get('videoDuration'));
      if (this.get('isWebReviewEnabled') && this.get('videoDuration') > options["maxLength"]) {
        this.set('isError', true);
        this.set('hasReviewed', true);
        var msg = 'Oops! Your video can only be ' + options['maxLength'] + ' seconds long. Try again!';
        this.set('errorMessage', msg);
        return false;
      }
      if (this.get('isWebReviewEnabled') && !this.get('hasReviewed')) {
        return false;
      }
      return this._super(submission);
    },

    actions: {
      review: function review() {
        var video = this.$('.video-review').get(0);
        video.play();
      }
    }

  });

  exports['default'] = SubmissionMovieWidget;
});