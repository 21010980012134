define('play/controllers/play-mode-routed', ['exports', 'play/controllers/play-mode-base', 'ember'], function (exports, _playControllersPlayModeBase, _ember) {

  var PlayModeRoutedController = _playControllersPlayModeBase['default'].extend({
    needs: ['playthrough', 'identity', 'playSyncWaypoints', 'identity', 'playRole'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),

    getDefaultMission: function getDefaultMission() {
      var activeWaypoints = this.get('activeWaypoints');
      var startedWaypoint = activeWaypoints.findBy('startedAt');
      return startedWaypoint ? startedWaypoint : this.get('activeWaypoints.firstObject');
    },

    isDisabled: _ember['default'].computed.oneWay('playthrough.isPaused'),

    isGameEndedForTeam: (function () {
      var waypoints = this.get('activeWaypoints');
      var isEnded = this.get('playthrough.isEnded');
      var hasTeamCompleted = this.get('team.hasCompletedGame');
      return isEnded || hasTeamCompleted && !waypoints.length;
    }).property('playthrough.status', 'team.hasCompletedGame', 'activeWaypoints.length'),

    isVisible: (function () {
      return this.get('isActive') && !this.get('isGameEndedForTeam');
    }).property('isActive', 'isGameEndedForTeam')
  });

  exports['default'] = PlayModeRoutedController;
});