define('play/routes/playthrough/intro', ['exports', 'play/routes/playthrough-tab', 'ember'], function (exports, _playRoutesPlaythroughTab, _ember) {

  var PlaythroughIntroRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'home',
    setupController: function setupController(controller) {
      var identity = this.controllerFor('identity'),
          isLoggedIn = identity.get('loggedIn'),
          playthrough = this.modelFor('playthrough').playthrough,
          teamHasStarted = identity.get('team.hasStartedGame'),
          canStart = playthrough.get('hasStarted') && teamHasStarted;
      if (!isLoggedIn) {
        if (playthrough.get('options.public')) {
          this.transitionTo('playthrough.login', playthrough.id);
        } else {
          this.transitionTo('login');
        }
      } else if (canStart) {
        this.transitionTo('play.play', playthrough.id);
      }
    },
    activate: function activate() {
      if (!BREADCRUMB_ENV.TESTING) {
        _ember['default'].run.later(this, function () {
          this.controller.send('testRealtime');
        }, 1000);
      }
    },
    renderTemplate: function renderTemplate(controller) {
      this.render('playthrough/intro', { into: 'playthrough' });
    },
    actions: {
      resume: function resume() {
        if (!BREADCRUMB_ENV.TESTING) {
          this.controller.send('testRealtime');
        }
      }
    }
  });

  exports['default'] = PlaythroughIntroRoute;
});