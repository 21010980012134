define('play/routes/gallery/index', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var GalleryIndexRoute = _playRoutesPlaythroughTab['default'].extend({
    beforeModel: function beforeModel(transition) {
      if (this.modelFor('playthrough').playthrough.get('game.options.gallery_show_all_teams')) {
        this.transitionTo('gallery.teams');
      } else {
        this.transitionTo('gallery.team', this.controllerFor('identity').get('team'));
      }
    }
  });

  exports['default'] = GalleryIndexRoute;
});