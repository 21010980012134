define('play/routes/playthrough/teamchat', ['exports', 'play/routes/playthrough-tab', 'play/config/environment'], function (exports, _playRoutesPlaythroughTab, _playConfigEnvironment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function normalizeMessages(object) {
    var breadcrumbTeamID = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    return (Array.isArray(object) ? object : Object.values(object || {})).map(function (message) {
      return _extends({}, message, {
        date: moment(message.timestamp).format('h:mm A'),
        fromUser: breadcrumbTeamID === message.fromID
      });
    });
  }

  function firebaseDBAuxiliary(clientID) {
    if (Breadcrumb.firebaseApp === undefined) {
      throw new Error('Breadcrumb.firebaseApp wasn\'t initialized');
    }
    if (clientID === undefined || clientID.length !== 20) {
      return Breadcrumb.firebaseApp.database();
    }
    var database = clientID.slice(15, 20);
    var projectID = _playConfigEnvironment['default'].firebaseConfig.projectId;
    return Breadcrumb.firebaseApp.database('https://' + projectID + '-' + database + '.firebaseio.com');
  }

  var PlaythroughTeamChatRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'teamchat',
    messageRef: null,
    teamChatController: null,
    unreadMessagesRef: null,
    renderTemplate: function renderTemplate() {
      this.render('playthrough/teamchat', { into: 'playthrough' });
    },
    setupController: function setupController(controller, _) {
      this.set("teamChatController", controller);
      var playthrough = this.modelFor('playthrough').playthrough;
      var weveClientId = playthrough.get('weve_client_id');
      var database = firebaseDBAuxiliary(weveClientId);
      var conversationTeamID = controller.get("team.weve_conversation_team_id");
      var conversationOrgID = controller.get("team.weve_conversation_org_id");
      var conversationGameID = controller.get("team.weve_conversation_game_id");
      var breadcrumbTeamID = controller.get("team.id");

      if (!(conversationGameID && conversationOrgID && conversationTeamID)) return;

      this.unsubscribeToUnreadMessage();
      controller.send("clearMessageNotification");

      this.messageRef = database.ref('org/' + conversationOrgID + '/messaging/' + conversationGameID + '/' + conversationTeamID + '/messages');
      this.unreadMessagesRef = database.ref('org/' + conversationOrgID + '/messaging/' + conversationGameID + '/' + conversationTeamID + '/activeReadTimestamp/' + breadcrumbTeamID);

      this._onValueCallback = this.messageRef.limitToLast(50).on("child_added", function (snap) {
        controller.set("messages", [].concat(_toConsumableArray(controller.get("messages")), _toConsumableArray(normalizeMessages(snap.val() ? [snap.val()] : [], breadcrumbTeamID))));
      });

      controller.set('isLoadedChat', true);
      controller.set("unreadMessagesRef", this.unreadMessagesRef);
    },
    deactivate: function deactivate() {
      this.teamChatController && this.teamChatController.set("messages", []);
      try {
        this._onValueCallback && this.messageRef && this.messageRef.off('child_added', this._onValueCallback);
      } catch (e) {}
      try {
        this.subscribeToUnreadMessage();
      } catch (e) {}
    },
    getActiveChatTimestamp: function getActiveChatTimestamp() {
      return this.unreadMessagesRef.once("value").then(function (val) {
        return val.val() || null;
      });
    },
    subscribeToUnreadMessage: function subscribeToUnreadMessage() {
      var _this = this;

      this.unsubscribeToUnreadMessage();
      this.getActiveChatTimestamp().then(function (val) {
        _this._activeChatTimestampSubscription = _this.messageRef.orderByChild("timestamp").startAt(val).limitToLast(10).on("value", function (snap) {
          _this.teamChatController.set("unreadMessageCount", Object.values(snap.val() || {}).length);
        });
      });
    },
    unsubscribeToUnreadMessage: function unsubscribeToUnreadMessage() {
      try {
        this._activeChatTimestampSubscription && this.messageRef && this.messageRef.off("value", this._activeChatTimestampSubscription);
      } catch (e) {
        console.error(e);
      }
    }
  });

  exports['default'] = PlaythroughTeamChatRoute;
});