define('play/routes/play-retry/waypoint', ['exports', 'ember'], function (exports, _ember) {

  var PlayRetryWaypointRoute = Breadcrumb.Route.extend({
    controllerName: 'playWaypoint',

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // recover last submission
      var numSubmissions = model.get('submissions.length');
      if (numSubmissions > 0) {
        _ember['default'].run.next(this, 'restoreSubmission');
      }
    },

    restoreSubmission: function restoreSubmission() {
      var waypoint = this.modelFor('playRetry/waypoint'),
          submissions = waypoint.get('submissions'),
          lastSubmission = submissions && submissions[submissions.length - 1],
          partController;
      lastSubmission.submission.forEach(function (part, i) {
        partController = this.controllerFor('playSubmissionParts').objectAt(i);
        partController.set('submission', part);
      }, this);
    },

    renderTemplate: function renderTemplate() {
      this.render('playRetry/waypoint', { into: 'playthrough' });
      this.render('playWaypoint', { into: 'playRetry/waypoint' });
    },
    actions: {
      completed: function completed() {
        this.transitionTo('play.home');
      }
    }
  });

  exports['default'] = PlayRetryWaypointRoute;
});