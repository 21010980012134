define('play/controllers/play-sync-waypoint', ['exports', 'ember'], function (exports, _ember) {

  var PlaySyncWaypointController = _ember['default'].ObjectController.extend({
    isWaypointCompleted: null,

    syncRetryable: null,
    isSyncing: _ember['default'].computed.oneWay('syncRetryable.isInProgress'),
    isSynced: _ember['default'].computed.oneWay('syncRetryable.isCompleted'),
    isError: _ember['default'].computed.oneWay('syncRetryable.isError'),
    syncFailed: _ember['default'].computed.oneWay('syncRetryable.isFailure'),
    retryAt: _ember['default'].computed.oneWay('syncRetryable.retryAt'),

    updates: null,

    sync: function sync() {
      if (!!this.get('syncRetryable')) {
        return;
      }
      // FIXME
      var app = this.parentController.container.lookup('application:main');
      var retryable = Breadcrumb.retry(this.doSync, this, app.get('xhrRetryOptions')),
          self = this;
      this.set('syncRetryable', retryable);
      return retryable.then(function () {
        if (self.get('isDestroyed')) {
          return;
        }
        self.get('parentController').syncDidComplete(self);
        self.destroy();
      }, function (err) {
        console.error('All waypoint sync attempts failed.');
      });
    },

    doSync: function doSync() {
      return this.get('content').triggerAction(this.get('syncAction'), this.get('updates'));
    },

    retry: function retry() {
      var self = this;
      this.get('syncRetryable').restart().then(function () {
        if (self.get('isDestroyed')) {
          return;
        }
        self.get('parentController').syncDidComplete(self);
        self.destroy();
      }, function (err) {
        console.error('All waypoint sync retry attempts failed.');
      });
    },

    actions: {
      retry: function retry() {
        this.retry();
      }
    }
  });

  exports['default'] = PlaySyncWaypointController;
});