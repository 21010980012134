define('play/views/submission-choice-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionChoiceWidget = _playViewsSubmissionBaseWidget['default'].extend({
    blankSubmission: { choice: null },
    validate: function validate(submission) {
      var idx = parseInt(submission.choice, 10);
      if (isNaN(idx) || idx === false || idx === null || idx === undefined) {
        return false;
      }
      var numChoices = this.get('options.choices.length');
      return idx >= 0 && idx < numChoices;
    },
    clean: function clean(submission) {
      return { choice: parseInt(submission.choice, 10) };
    },
    choicesWithValue: (function () {
      if (!_ember['default'].isArray(this.get('options.choices'))) {
        return [];
      }
      return this.get('options.choices').map(function (choice, idx) {
        var label = _ember['default'].typeOf(choice) === 'string' ? choice : choice.text;
        return { label: label, value: idx };
      });
    }).property('options.choices', 'controller.waypoint'),

    choiceDidChange: (function () {
      this.performValidation();
    }).observes('submission.choice'),

    template: _ember['default'].Handlebars.compile('<p>' + '{{#view Ember.RadioButtonGroup name="choice" ' + 'valueBinding="view.submission.choice"}}' + '  {{#each choiceAndIndex in view.parentView.choicesWithValue}}' + '    <label class="radio-label">' + '      {{view view.RadioButton disabledBinding="controller.isUpdating" ' + '        valueBinding="choiceAndIndex.value"}}' + '      <div>{{choiceAndIndex.label}}</div>' + '    </label>' + '  {{/each}}' + '{{/view}}' + '</p>')
  });

  exports['default'] = SubmissionChoiceWidget;
});