define('play/controllers/gallery-teams-item', ['exports', 'ember'], function (exports, _ember) {

  var GalleryTeamsItemController = _ember['default'].ObjectController.extend({
    needs: ['identity'],
    isIdentity: (function () {
      return this.get('controllers.identity.team.id') === this.get('content.id');
    }).property('content.id')
  });

  exports['default'] = GalleryTeamsItemController;
});