define('play/views/play-social-select', ['exports', 'play/views/play-modal-base'], function (exports, _playViewsPlayModalBase) {

  var PlaySocialSelectView = _playViewsPlayModalBase['default'].extend({
    classNames: ['play-social-select'],

    click: function click(e) {
      e.preventDefault();
    }
  });

  exports['default'] = PlaySocialSelectView;
});