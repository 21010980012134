define('play/routes/play', ['exports'], function (exports) {
  var PlayRoute = Breadcrumb.Route.extend({
    afterModel: function afterModel() {
      var _this = this;

      var playthrough = this.modelFor('playthrough').playthrough;
      var isLoggedIn = this.controllerFor('identity').get('loggedIn');
      if (!isLoggedIn) {
        if (playthrough.get('options.public')) {
          this.transitionTo('playthrough.signup', playthrough.id);
        } else {
          this.transitionTo('login');
        }
        return;
      }
      var hasStarted = playthrough.get('hasStarted');
      if (!hasStarted) {
        this.transitionTo('playthrough.intro', playthrough.id);
        return;
      }
      // no max
      var maxVotables = playthrough.get('game.routing.votables.max');
      var status = playthrough.get('status');
      var selectVotes = maxVotables && [B.PlaythroughStatuses.ENDED, B.PlaythroughStatuses.ENDING].includes(status);
      var contentLength = this.controllerFor('vote.select').get('content.length');
      if (selectVotes && contentLength === 0) {
        (function () {
          var self = _this;
          self.controllerFor('vote.select').reload().then(function () {
            return this.checkReadiness();
          }).then(function (isReady) {
            if (!isReady) {
              self.controllerFor('vote.select').notifySelectionRequired().then(function () {
                if (status === B.PlaythroughStatuses.ENDING) {
                  this.transitionToRoute('vote.select');
                }
              });
            }
          });
        })();
      }
    },
    actions: {
      playthroughStatusDidChange: function playthroughStatusDidChange(newStatus) {
        var hasStarted = this.modelFor('playthrough').playthrough.get('hasStarted');
        if (!hasStarted) {
          this.transitionTo('playthrough.intro', this.modelFor('playthrough').id);
        }
        return true;
      }
    }
  });

  exports['default'] = PlayRoute;
});