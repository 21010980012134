define('play/controllers/vote-admin-voting-on', ['exports', 'ember', 'breadcrumb/mixins/realtime'], function (exports, _ember, _breadcrumbMixinsRealtime) {

  var VoteAdminVotingOnController = _ember['default'].Controller.extend(_breadcrumbMixinsRealtime['default'], {
    needs: ['vote/admin', 'playthrough'],
    channelFormat: 'breadcrumb-@env-waypoint-@id',
    playthrough: _ember['default'].computed.alias('controllers.playthrough.playthrough'),

    votingOnDidChange: (function () {
      this.set('content', this.get('playthrough.votingWaypoint'));
    }).observes('playthrough.votingWaypoint'),

    realtimeEvents: {
      votingChanged: function votingChanged() {
        if (this.get('content')) {
          this.get('content').safeReload();
        }
      }
    }
  });

  exports['default'] = VoteAdminVotingOnController;
});