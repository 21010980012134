define('play/controllers/gallery/team', ['exports', 'ember'], function (exports, _ember) {

  var GalleryTeamController = _ember['default'].ObjectController.extend({
    needs: ['galleryWaypoints', 'playthrough'],
    waypoints: _ember['default'].computed.oneWay('controllers.galleryWaypoints'),

    teamDidChange: (function () {
      if (this.get('controllers.playthrough.content.game.options.gallery_show_all_teams')) {
        // reset when different team is selected
        // so user doesn't see stale waypoints while new ones are loading
        this.get('waypoints').set('content', null);
      }
    }).observes('content')
  });

  exports['default'] = GalleryTeamController;
});