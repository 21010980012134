define('play/routes/gallery', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var GalleryRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'gallery',
    beforeModel: function beforeModel(transition) {
      if (!this.controllerFor('identity').get('loggedIn')) {
        this.transitionTo('playthrough.intro', this.modelFor('playthrough'));
      }
    }
  });

  exports['default'] = GalleryRoute;
});