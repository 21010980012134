define('play/upload', ['exports', 'ember', 'play/config/environment'], function (exports, _ember, _playConfigEnvironment) {

  var Upload = _ember['default'].Object.extend({

    isReady: false,
    isUploading: false,
    progress: null,
    isError: false,
    isCompleted: false,

    size: _ember['default'].computed.alias('file.size'),
    mediaType: _ember['default'].computed.alias('mediaItem.media_type'),
    mimeType: _ember['default'].computed.alias('mediaItem.upload_mime_type'),

    deferred: null,
    xhr: null,

    mediaPolicyOptions: {
      maxAttempts: _playConfigEnvironment['default'].environment === 'local' ? 3 : 6,
      initialRetryBackoff: 5
    },

    start: function start() {
      if (this.get('isUploading')) {
        return;
      }
      return this._start();
    },

    restart: function restart() {
      // stop upload if in progress
      if (this.get('isUploading')) {
        this.stopUpload();
      }
      // re-create deferred if the current one is not fulfilled/rejected yet.
      // just to make sure that if the currently-stalled upload does complete
      // it doesn't erroneously report the completion of this new one.
      return this._start();
    },

    _start: function _start() {
      this.deferred = _ember['default'].RSVP.defer();
      var self = this;
      this.loadMediaPolicy().then(function () {
        // media policy succeeded!
        self.startUpload();
      }, function () {
        // media policy load failed
        self.deferred.reject();
      });
      return this.deferred.promise;
    },

    loadMediaPolicy: function loadMediaPolicy() {
      // return media policy if already found.
      if (this.mediaPolicy) {
        return _ember['default'].RSVP.resolve(this.mediaPolicy);
      }
      // always override existing promise and return a new one.
      if (this.mediaPolicyRetryable) {
        this.mediaPolicyRetryable.cancel();
      }
      // create and save retryable
      this.mediaPolicyRetryable = Breadcrumb.Retryable.create({
        action: this._loadMediaPolicy,
        context: this,
        options: this.mediaPolicyOptions,
        isMediaPolicyRetryable: true
      });
      // start it and return a promise.
      var self = this;
      return this.mediaPolicyRetryable.start().then(function (data) {
        self.mediaPolicy = data;
        self.mediaPolicyRetryable = null;
        return self.mediaPolicy;
      });
    },

    _loadMediaPolicy: function _loadMediaPolicy() {
      return _ember['default'].$.getJSON(Breadcrumb.api.siteUrl('/media/policy'));
    },

    getUploadParams: function getUploadParams() {
      return {
        key: 'uploads/' + this.mediaItem.upload_key,
        awsaccesskeyid: this.mediaPolicy.access_key,
        skey: this.mediaPolicy.skey,
        bucket: this.mediaPolicy.bucket_name,
        'Content-Type': this.mediaItem.upload_mime_type,
        acl: 'private',
        policy: this.mediaPolicy.policy,
        signature: this.mediaPolicy.signature,
        success_action_status: '201'
      };
    },

    didProgress: function didProgress(e) {
      var progress = 100 * (e.loaded || e.done) / e.total || 0;
      // console.log('app/upload/didProgress, progress: ' + progress + 'e:', e);
      this.set('progress', progress);
    },

    didComplete: function didComplete(e) {
      // console.log('app/upload/didComplete');
      this.xhr = null;
      // this.stopProgressWatch();
      this.setProperties({
        isUploading: false,
        isError: false,
        isReady: false,
        isCompleted: true
      });
      this.deferred.resolve();
    },

    didFail: function didFail(e) {
      // console.log('app/upload/didFail, e:' +JSON.stringify(e));
      this.xhr = null;
      // this.stopProgressWatch();
      this.setProperties({
        isUploading: false,
        isError: true,
        isReady: true, // can try again
        isCompleted: false
      });
      this.deferred.reject();
    },

    getPostUrl: function getPostUrl() {
      return 'https://%@.s3.amazonaws.com/'.fmt(this.mediaItem.upload_bucket);
    },

    saveUploadToQueue: function saveUploadToQueue(upload) {
      if (Breadcrumb.NativeHooks.getUploadingQueue) {
        var queue = Breadcrumb.NativeHooks.getUploadingQueue();
        queue[upload['uploadId']] = upload;
        Breadcrumb.NativeHooks.setUploadingQueue(queue);
      }
    },
    removeUploadFromQueue: function removeUploadFromQueue(upload) {
      // console.log('upload.js, removeUploadFromQueue', upload);
      if (Breadcrumb.NativeHooks.getUploadingQueue) {
        var queue = Breadcrumb.NativeHooks.getUploadingQueue();
        delete queue[upload['uploadId']];
        Breadcrumb.NativeHooks.setUploadingQueue(queue);
      }
    },

    startUpload: function startUpload() {
      if (this.xhr !== null) {
        return;
      }
      var self = this;
      var xhr = Breadcrumb.NativeHooks.upload(self.getPostUrl(), self.getUploadParams(), self.file, function didComplete(e) {
        // console.debug('upload.js, complete', self.xhr, xhr, self.uploadItem);
        // if(self.xhr !== xhr) { return; } // skip if upload aborted.
        self.removeUploadFromQueue(self.uploadItem);
        self.didComplete(e);
      }, function didFail(e) {
        // console.debug('upload.js, fail', self.xhr, xhr, self.uploadItem);
        // if(self.xhr !== xhr) { return; } // skip if upload aborted.
        // self.removeUploadFromQueue(self.uploadItem);
        self.didFail(e);
      }, function didProgress(e) {
        // if(self.xhr !== xhr) { return; } // skip if upload aborted.
        self.didProgress(e);
      });
      self.xhr = xhr;
      self.uploadItem = {
        uploadId: xhr['uploadId'],
        mediaItem: self.get('mediaItem'),
        file: self.file,
        waypointId: self.get('waypoint.id')
      };
      //console.log('uploadItem:');
      //console.log(self.uploadItem);
      self.saveUploadToQueue(self.uploadItem);
      // self.startProgressWatch();
      self.setProperties({ isUploading: true, progress: 0, isReady: false });
    },

    stopUpload: function stopUpload() {
      if (this.xhr === null) {
        return;
      }
      Breadcrumb.NativeHooks.abortUpload(this.xhr);
      this.xhr = null;
      this.setProperties({ isUploading: false, progress: 0 });
    },

    toString: function toString() {
      return "Upload";
    }
  });

  exports['default'] = Upload;
});