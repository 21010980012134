define('play/controllers/identity', ['exports', 'ember'], function (exports, _ember) {

  var IdentityController = _ember['default'].Controller.extend({
    team: null,
    roleIndex: null,
    playerId: null,
    deviceId: null,
    admin: !!BREADCRUMB_ENV.IS_ADMIN,

    loggedIn: _ember['default'].computed.bool('team'),
    loggedOut: _ember['default'].computed.not('loggedIn'),

    // get the team model, then set appropriate properties and return
    _login: function _login(playthroughId, teamId, roleIndex, playerId) {
      var self = this;
      return this.store.find('team', teamId).then(function (team) {
        return self._completeLogin(team, roleIndex, playerId);
      });
    },

    _completeLogin: function _completeLogin(team, roleIndex, playerId) {
      var admin = this.get('admin') || !!BREADCRUMB_ENV.IS_ADMIN;

      var promise = _ember['default'].RSVP.resolve();
      var deviceId = this.get('deviceId') || null;

      if (deviceId && deviceId !== team.get('deviceId')) {
        team.set('deviceId', deviceId);
        promise = team.save();
      }

      this.setProperties({
        team: team,
        player: null,
        admin: admin,
        roleIndex: roleIndex,
        playerId: playerId
      });

      Breadcrumb.Realtime.onReconnect(function () {
        team.safeReload();
        team.get('playthrough.game').safeReload();
      });
      return promise;
    },

    lookupIdentity: function lookupIdentity(playthroughId) {
      var localLogin = this.getLocalLogin(playthroughId);
      if (!localLogin) {
        this.setProperties({ team: null });
        return _ember['default'].RSVP.resolve();
      }
      return this._login(playthroughId, localLogin.teamId, localLogin.roleIndex);
    },

    logout: function logout(playthroughId) {
      Breadcrumb.localStorage.clear('local_login_' + playthroughId);
      this.setProperties({ team: null, playerId: null });
      return _ember['default'].RSVP.resolve();
    },

    loginTeam: function loginTeam(playthroughId, teamId, roleIndex, playerId) {
      this.saveLocalLogin(playthroughId, teamId, roleIndex, playerId);
      return this._login(playthroughId, teamId, roleIndex, playerId);
    },

    isLoggedInto: function isLoggedInto(playthroughId) {
      return !!this._identity || !!this.getLocalLogin(playthroughId);
    },

    getLocalLogin: function getLocalLogin(playthroughId) {
      return this._identity || Breadcrumb.localStorage.getJson('local_login_' + playthroughId);
    },

    saveLocalLogin: function saveLocalLogin(playthroughId, teamId, roleIndex, playerId) {
      this._identity = {
        playthroughId: playthroughId,
        teamId: teamId,
        roleIndex: roleIndex || 0,
        playerId: playerId || null
      };
      Breadcrumb.localStorage.setJson('local_login_' + playthroughId, this._identity);
    }
  });

  exports['default'] = IdentityController;
});