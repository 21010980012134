define('play/controllers/gallery-waypoint', ['exports', 'ember'], function (exports, _ember) {

  var GalleryWaypointController = _ember['default'].ObjectController.extend({
    isAnyMediaReady: (function () {
      // don't show waypoint unless at least one mediaItem is ready
      return this.get('media').isAny('is_ready');
    }).property('media.@each.is_ready')
  });

  exports['default'] = GalleryWaypointController;
});