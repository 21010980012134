define('play/controllers/playthrough', ['exports', 'ember', 'breadcrumb/mixins/realtime'], function (exports, _ember, _breadcrumbMixinsRealtime) {

  var PlaythroughController = _ember['default'].Controller.extend(_breadcrumbMixinsRealtime['default'], {
    needs: ['identity', 'vote/select', 'play', 'playWaypoint'],

    // overrides for the RealtimeMixin
    channelFormat: 'breadcrumb-@env-playthrough-@id',
    realtimeModel: _ember['default'].computed.alias('playthrough'),

    play: _ember['default'].computed.alias('controllers.play.content'),
    playthrough: _ember['default'].computed.alias('model.playthrough'),
    hasStarted: _ember['default'].computed.alias('playthrough.hasStarted'),
    isStarted: _ember['default'].computed.alias('playthrough.isStarted'),
    isPaused: _ember['default'].computed.alias('playthrough.isPaused'),
    isVoting: _ember['default'].computed.alias('playthrough.isVoting'),
    isEnded: _ember['default'].computed.alias('playthrough.isEnded'),

    missions: _ember['default'].computed.alias('model.missions'),

    startTrackMissions: (function () {
      var start_track_groups = this.get('model.missionGroups.content').filterBy('groupType', Breadcrumb.MissionGroupTypes.START_TRACK);
      if (!start_track_groups || start_track_groups.length === 0) {
        return [];
      }
      var start_track_mission_ids = start_track_groups[0].get('missions').getEach('id');
      return this.get('missions').filter(function (mission) {
        return start_track_mission_ids.indexOf(mission.get('id')) !== -1;
      });
    }).property('missions', 'model.missionGroups'),

    footerImage: (function () {
      var image = _ember['default'].I18n.t('nav.footer.image');
      return image.substr(0, 4) === 'http' ? image : null;
    }).property('content'),

    sendHeartbeat: function sendHeartbeat() {
      this.get('playthrough').triggerAction('heartbeat').then(null, function () {});
      if (!this.heartbeatDefer) {
        this.heartbeatDefer = _ember['default'].RSVP.defer();
      }
      return this.heartbeatDefer.promise;
    },

    resolveHeartbeat: function resolveHeartbeat() {
      if (this.heartbeatDefer) {
        this.heartbeatDefer.resolve();
      }
      this.heartbeatDefer = null;
    },

    onMessage: function onMessage(message) {
      message.events.forEach(function (evt) {
        if (message.source_id === Breadcrumb.api._clientId && evt.event === 'heartbeat') {
          this.resolveHeartbeat();
        }
      }, this);
      this._super(message);
    },

    triggerAction: function triggerAction(actionName, actionParams) {
      this.set('isUpdating', true);

      var self = this;
      return new _ember['default'].RSVP.Promise(Breadcrumb.bind(function (resolve, reject) {
        this.get('playthrough').triggerAction(actionName, actionParams).then(Breadcrumb.bind(function (data) {
          if (data && data.playthrough) {

            var serializer = self.store.serializerFor('playthrough');
            var payload = serializer.extract(self.store, self.store.modelFor('playthrough').playthrough, data, data.playthrough.id, 'find');
            self.store.push('playthrough', payload);
          }
          this.set('isUpdating', false);
          resolve(data);
        }, this), Breadcrumb.bind(function (err) {
          this.set('isUpdating', false);
          reject(err);
        }, this));
      }, this));
    },

    realtimeEvents: {
      teamStartedGame: function teamStartedGame(params) {
        // if teams started happens asynchronously, reload team.
        var team = this.get('controllers.identity.team');
        if (team && team.id === params.team_id) {
          team.safeReload();
        }
      },
      statusChanged: function statusChanged() {
        // wait for reload
        var playthrough = this.get('playthrough'),
            self = this;
        this.store.findWithSideload('playthrough', playthrough.id, 'game.flavors').then(function () {
          self.get('target').send('playthroughStatusDidChange');
          // if playthrough is ending and we need to choose votables, redirect
          // to that page.
          if (playthrough.get('isEnding') || playthrough.get('isEnded')) {
            self.get('controllers.vote/select').checkReadinessAndNotify(playthrough.get('isEnding'));
          }
          if (playthrough.get('isVoting')) {
            self.transitionToRoute('vote.vote');
          }
        });
        var team = this.get('controllers.identity.team');
        if (team) {
          team.safeReload();
        }
      },
      routingChanged: function routingChanged() {
        this.store.findWithSideload('playthrough', this.get('playthrough.id'), 'game.flavors');
        var team = this.get('controllers.identity.team');
        if (team) {
          team.safeReload();
        }
      },
      gameChanged: function gameChanged() {
        this.get('playthrough.game').safeReload(true);
      },
      votingChanged: function votingChanged() {
        if (!this.get('playthrough.isReloading')) {
          this.store.findWithSideload('playthrough', this.get('playthrough.id'), 'voting_waypoint');
        }
      },
      loadTest: function loadTest(params) {
        this.get('controllers.identity').set('admin', true);
        this.get('controllers.play').set('automaticInterval', params.interval);
        this.get('controllers.play').set('isOnAutomatic', true);
        this.get('controllers.playWaypoint').setProperties({
          isRevealingAnswers: true,
          isAutofillingMedia: true
        });
        this.transitionToRoute('play.home');
      }
    }
  });

  exports['default'] = PlaythroughController;
});