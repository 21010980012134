define('play/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'play/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _playConfigEnvironment) {
  exports.getXhrRetryOptions = getXhrRetryOptions;
  exports.setXhrRetryOptions = setXhrRetryOptions;

  var App;

  // FIXME: Re-enable after Ember Data upgrade?
  // Disabled to avoid this bug: https://github.com/emberjs/data/pull/1952
  // Ember.MODEL_FACTORY_INJECTIONS = true;

  BREADCRUMB_ENV.PLATFORM = _playConfigEnvironment['default'].APP.PLATFORM;
  BREADCRUMB_ENV.FACEBOOK_APP_ID = _playConfigEnvironment['default'].APP.FACEBOOK_APP_ID;
  BREADCRUMB_ENV.AWS_REGION = _playConfigEnvironment['default'].APP.AWS_REGION;
  BREADCRUMB_ENV.MEDIA_BUCKET_NAME = _playConfigEnvironment['default'].APP.MEDIA_BUCKET_NAME;
  BREADCRUMB_ENV.DISPLAY_MEDIA_BUCKET_NAME = _playConfigEnvironment['default'].APP.DISPLAY_MEDIA_BUCKET_NAME;
  BREADCRUMB_ENV.DISPLAY_VERSION = _playConfigEnvironment['default'].displayVersion;
  var xhrRetryOptions;

  function getXhrRetryOptions() {
    if (xhrRetryOptions) {
      return xhrRetryOptions;
    }
    return _playConfigEnvironment['default'].environment === 'local' ? { initialRetryBackoff: 500, maxAttempts: 6, maxRetryBackoff: 5000 } : { initialRetryBackoff: 5, maxAttempts: false, maxRetryBackoff: 5000 };
  }

  function setXhrRetryOptions(obj) {
    xhrRetryOptions = obj;
  }

  App = _ember['default'].Application.extend({
    modulePrefix: _playConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _playConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default'].extend({
      resolve: function resolve(fullName) {
        var res = this._super(fullName);
        if (res) {
          return res;
        }
        res = this._super(_ember['default'].String.dasherize(fullName));
        if (res) {
          return res;
        }
        return null;
      }
    }),

    ready: function ready() {
      // FIXME
      var i18nService = this.__container__.lookup('service:i18n');
      _ember['default'].I18n = {
        t: function t() {
          return i18nService.t.apply(i18nService, arguments).toString();
        },
        exists: function exists() {
          return i18nService.exists.apply(i18nService, arguments);
        }
      };
      return this._super.apply(this, arguments);
    },

    willDestroy: function willDestroy() {
      _ember['default'].I18n = null;
      return this._super.apply(this, arguments);
    },

    adminCodes: ['game', 'g', 'gg'],
    restartCodes: ['restart', 'refresh', 'r', 'rr'],
    logoutCodes: ['quit', 'logout', 'q', 'qq'],
    defaultFlavorName: 'gogame-en',
    defaultBehaviorName: 'gogame',
    xhrRetryOptions: _ember['default'].computed(function () {
      return getXhrRetryOptions();
    }).volatile()
  });

  Breadcrumb.localStorage = Breadcrumb.LocalStorage;

  (0, _emberLoadInitializers['default'])(App, _playConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});