define('play/controllers/play-waypoint-progress', ['exports', 'ember'], function (exports, _ember) {

  var PlayWaypointProgressController = _ember['default'].Controller.extend({
    needs: ['playWaypoint', 'playSubmissionParts'],
    content: _ember['default'].computed.alias('controllers.playWaypoint.content'),

    progressCache: null,

    partsData: null,
    startedAt: null,
    pendingSubmissions: null,
    numHintsUsed: 0,

    init: function init() {
      this._super();
      this.progressCache = {};
      this.resetProgress();
    },

    contentWillChange: (function () {
      if (!this.get('content.id')) {
        return;
      }
      this.saveProgress(this.get('content.id'));
    }).observesBefore('content.id'),

    contentDidChange: (function () {
      var waypointId = this.get('content.id');
      if (!waypointId) {
        return;
      }
      if (this.hasProgressFor(waypointId)) {
        this.restoreProgress(waypointId);
      } else {
        this.resetProgress();
      }
    }).observes('content.id'),

    hasProgressFor: function hasProgressFor(waypointId) {
      return !!this.progressFor(waypointId);
    },

    progressFor: function progressFor(waypointId) {
      return this.progressCache[waypointId];
    },

    saveProgress: function saveProgress(waypointId) {
      var partsData = this.get('controllers.playSubmissionParts').map(function (p, index) {
        return { file: p.get('file') };
      });

      this.progressCache[waypointId] = {
        partsData: partsData,
        pendingSubmissions: this.get('pendingSubmissions'),
        numHintsUsed: this.get('numHintsUsed'),
        startedAt: this.get('startedAt')
      };
    },

    getStoredPart: function getStoredPart(waypointId, partIndex) {
      var waypointProgress = this.get('progressCache')[waypointId];
      if (!waypointProgress) {
        return null;
      }
      return waypointProgress.partsData[partIndex] || null;
    },

    restoreProgress: function restoreProgress(waypointId) {
      var progress = this.progressFor(waypointId);
      if (!progress) {
        return;
      }
      this.setProperties(progress);
    },

    resetProgress: function resetProgress() {
      this.setProperties({
        startedAt: this.get('content.startedAt'),
        pendingSubmissions: this.get('content.submissions') || [],
        numHintsUsed: 0,
        partsData: {}
      });
    }
  });

  exports['default'] = PlayWaypointProgressController;
});