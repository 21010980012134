define('play/controllers/play-mode-challenge', ['exports', 'play/controllers/play-mode-base', 'ember'], function (exports, _playControllersPlayModeBase, _ember) {

  var PlayModeChallengeController = _playControllersPlayModeBase['default'].extend({
    needs: ['playthrough', 'identity', 'playSyncWaypoints', 'play', 'playMessage', 'play/home', 'playRole', 'playSyncWaypoints'],

    team: _ember['default'].computed.oneWay('controllers.identity.team'),

    getDefaultMission: function getDefaultMission() {
      return this.get('activeWaypoints.firstObject');
    },

    didReceiveChallengeMessage: function didReceiveChallengeMessage(eventType, team, params) {
      this.showChallengeMessage(eventType, team, params);
    },

    hideDuringStartTrack: (function () {
      var blueprint = this.get('controllers.playthrough.content.playthrough.game.blueprint.name');
      return blueprint === 'conference';
    }).property('controllers.playthrough.content.playthrough.game.blueprint.name'),

    isVisible: (function () {
      return this.get('isActive') && this.get('model.enabled') === true && !(this.get('controllers.identity.team.status') <= 200 && this.get('hideDuringStartTrack')) && this.get('missions.length') > 0;
    }).property('isActive', 'model.enabled', 'controllers.identity.team.status', 'missions.length', 'hideDuringStartTrack'),

    homeTitle: (function () {
      var title = this.translationForKey('title');
      if (this.get('playthrough.isEnded')) {
        title += ' (' + _ember['default'].I18n.t('global.unavailable') + ')';
      } else if (this.get('isInStartDelay')) {
        var timer = Breadcrumb.Utils.timeFormatFns.timer(this.get('delayUntil'), { relativeTimeType: 'until' });
        title += ' (' + timer + ')';
      }
      return title;
    }).property('content.name', 'isInStartDelay', 'delayUntil'),

    delayUntil: (function () {
      var startedAt = this.get('playthrough.startedAt'),
          delay = this.get('mode.delay');
      if (!delay || !startedAt) {
        return null;
      }
      return new Date(startedAt.getTime() + delay * 60000);
    }).property('playthrough.startedAt', 'mode'),

    isInStartDelay: (function () {
      return this._isInStartDelay();
    }).property('delayUntil'),

    _isInStartDelay: function _isInStartDelay() {
      var delayUntil = this.get('delayUntil');
      if (!delayUntil) {
        return false;
      }
      return Breadcrumb.now().getTime() < delayUntil.getTime();
    },

    checkStartDelay: (function () {
      if (this.get('isInStartDelay') !== this._isInStartDelay()) {
        delete _ember['default'].meta(this).cache['isInStartDelay'];
        this.notifyPropertyChange('isInStartDelay');
      }
    }).observes('Breadcrumb.ticker'),

    isSelfUninterruptible: (function () {
      if (this.get('modeName') !== 'head_to_head') return false;
      var completedAndSyncingIds = this.get('controllers.playSyncWaypoints.completedWaypointsSyncedIds');
      var activeStarted = this.get('team.activeWaypoints').filter(function (w) {
        if (!w.get('startedAt')) {
          return false;
        }
        if (w.get('completedAt')) {
          return false;
        }
        if (completedAndSyncingIds.contains(w.get('id'))) {
          return false;
        }
        return true;
      });
      return activeStarted.anyBy('mission.category.uninterruptible');
    }).property('controllers.playSyncWaypoints.completedWaypointsSyncedIds', 'team.activeWaypoints.@each.mission.category', 'team.activeWaypoints.@each.startedAt'),

    isDisabled: (function () {
      if (this.get('isInStartDelay')) {
        return true;
      }
      if (this.get('isSelfUninterruptible')) {
        return true;
      }
      var playthroughStatus = this.get('playthrough.status');
      if (playthroughStatus === B.PlaythroughStatuses.STARTED || playthroughStatus === B.PlaythroughStatuses.ENDING) {
        return false;
      }
      return true;
    }).property('playthrough.status', 'isInStartDelay', 'isSelfUninterruptible'),

    showChallengeMessage: function showChallengeMessage(eventType, fromTeam, params) {
      var selfTeam = this.get('controllers.identity.team'),
          self = this;
      switch (eventType) {
        case 'challenge-received':
          this.showChallengeReceived(fromTeam, params);
          break;
        case 'challenge-accepted':
          selfTeam.safeReload().then(function () {
            self.showChallengeAccepted(fromTeam, params);
          });
          break;
        case 'challenge-declined':
          self.showChallengeDeclined(fromTeam, params);
          break;
        case 'challenge-failed':
          break;
      }
    },

    showChallengeReceived: function showChallengeReceived(team, params) {
      var context = { teamName: team.get('name') },
          self = this;
      return this.get('controllers.playMessage').show({
        text: this.translationForKey('confirm.received', context),
        confirmOptions: [{
          label: this.translationForKey('actions.accept'),
          result: true,
          primary: true
        }, {
          label: this.translationForKey('actions.decline'),
          result: false
        }],
        footer: this.translationForKey('confirm.received_footer', context)
      }).then(function (response) {
        if (response === true) {
          return self.acceptChallenge(team);
        } else if (response === false) {
          return self.declineChallenge(team);
        }
      });
    },

    showChallengeAccepted: function showChallengeAccepted(team, params) {
      var context = { teamName: team.get('name') },
          self = this;
      var waypoint = self.getChallengeWaypoint(team);
      if (waypoint) {
        return self.get('controllers.playMessage').show({ text: this.translationForKey('confirm.was_accepted', context) }).then(function () {
          self.transitionToRoute('playMode.waypoint', self.get('modeName'), waypoint);
        });
      }
      return self.get('controllers.playMessage').show({ text: this.translationForKey('confirm.exhausted') });
    },

    showChallengeDeclined: function showChallengeDeclined(team, params) {
      var context = { teamName: team.get('name') },
          self = this;
      this.get('controllers.playMessage').show({
        text: this.translationForKey('confirm.was_declined', context),
        confirmType: 'ok'
      });
    },

    sendChallenge: function sendChallenge(team) {
      var fromTeam = this.get('controllers.identity.team'),
          modeName = this.get('modeName');
      this.get('controllers.playMessage').show({
        text: this.translationForKey('inprogress.sending'),
        confirmType: 'none' });
      return this.challengeAction(team, 'send', { mode_name: modeName });
    },

    acceptChallenge: function acceptChallenge(team) {
      var self = this;
      this.get('controllers.playMessage').show({
        text: this.translationForKey('inprogress.accepting'),
        confirmType: 'none' });
      return this.challengeAction(team, 'accept').then(function () {
        var waypoint = self.getChallengeWaypoint(team);
        if (waypoint) {
          return self.transitionToRoute('playMode.waypoint', self.get('modeName'), waypoint);
        }
      });
    },

    declineChallenge: function declineChallenge(team) {
      this.get('controllers.playMessage').show({
        text: this.translationForKey('inprogress.declining'),
        confirmType: 'none' });
      return this.challengeAction(team, 'decline');
    },

    challengeAction: function challengeAction(team, actionName, params) {
      var self = this,
          teamName;
      params = params || {};
      params.team_id = team.id || team.pin;
      params.action = actionName;
      return this.get('controllers.play').triggerAction('challenge', params).then(function (data) {
        // for getting team name -- use from response first, then find
        // locally if not present
        if (data.team_name) {
          teamName = data.team_name;
        } else if (data.team && data.team.team.id) {
          team = self.store.getById('team', data.team.team.id);
          teamName = team.get('name');
        }
        // use default if still not present.
        teamName = teamName || self.translationForKey('confirm.other_team_default');

        // the challenge actions returns the team as an embedded object
        // so it can return response meta-data as well
        var messageKey = 'confirm.' + data.result,
            messageContext = { teamName: teamName },
            message = self.translationForKey(messageKey, messageContext),
            messageStyle = data.ok === true ? 'successful' : data.ok === false ? 'unsuccessful' : 'info';
        return self.get('controllers.playMessage').show({
          text: message, style: messageStyle });
      }, function () {
        return self.get('controllers.playMessage').show({
          text: self.translationForKey('inprogress.error'),
          style: 'error' });
      });
    },

    getChallengeWaypoint: function getChallengeWaypoint(withTeam) {
      var team = this.get('controllers.identity.team'),
          challengeStatus = team.get('challenges.' + withTeam.id),
          missionId = challengeStatus && challengeStatus.mission_id;
      var waypoint = this.get('activeWaypoints').filterBy('mission.id', missionId).get('firstObject');
      if (!waypoint) {
        waypoint = this.get('activeWaypoints.firstObject');
      }
      return waypoint;
    }
  });

  exports['default'] = PlayModeChallengeController;
});