define('play/views/submission-camera-widget', ['exports', 'play/views/submission-media-widget', 'ember'], function (exports, _playViewsSubmissionMediaWidget, _ember) {

  var SubmissionCameraWidget = _playViewsSubmissionMediaWidget['default'].extend({
    accept: '*',
    mediaTypeTitle: 'photo or video',
    isVideo: true,
    mimeTypes: ['image/jpeg', 'image/png', 'image/gif', 'video/mpeg', 'video/mp4', 'video/quicktime', 'video/3gpp', 'video/ogg', 'video/x-flv', 'video/webm', 'video/avi'],

    nativeOptions: (function () {
      var options = this._super();
      if (Breadcrumb.NativeHooks.useNativeMedia()) {
        var step = this.get('controller.step.content');
        var gameOptions = this.get('controller.controllers.gameOptions');

        // use value defined on the mission step
        // else use gameOptions value
        // which returns value defined on the game
        // or default defined in behavior
        var maxLength = _ember['default'].get(step, 'video_max_length') || gameOptions.get('videoMaxLength');
        var quality = _ember['default'].get(step, 'video_quality') || gameOptions.get('videoQuality');
        var usePauseRecord = _ember['default'].get(step, 'video_pause_record');
        if (typeof usePauseRecord === 'undefined') {
          usePauseRecord = gameOptions.get('videoPauseRecord') || false;
        }
        var disableRotation = _ember['default'].get(step, 'video_disable_rotation');
        if (typeof disableRotation === 'undefined') {
          disableRotation = gameOptions.get('videoDisableRotation') || false;
        }
        options = $.extend(options, {
          maxLength: maxLength,
          usePauseRecord: usePauseRecord,
          disableRotation: disableRotation,
          useCompression: quality !== 'best',
          quality: quality,
          waypointID: this.get('controller.waypoint.id'),
          playthroughID: this.get('controller.playthrough.id')
        });
        return options;
      } else {
        return options;
      }
    }).property('_super.nativeOptions'),

    getNativeMedia: function getNativeMedia() {
      var options = this.get('nativeOptions');
      this.showAndroidVideoWarning();
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        Breadcrumb.NativeHooks.getMedia(resolve, reject, options);
      });
    }
  });

  exports['default'] = SubmissionCameraWidget;
});