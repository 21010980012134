define('play/controllers/login', ['exports', 'ember'], function (exports, _ember) {

  var LoginController = _ember['default'].Controller.extend({
    needs: ['identity'],
    pin: '',
    error: '',
    message: '',
    appVersion: '',
    appBuild: '',
    isLoggingIn: false,
    isResuming: false,

    queryParams: ['c', 'retry', 'pid', 'login[c]', 'user', 'goto'],
    c: null,
    goto: null,
    pid: null,
    retry: null,
    externalUserId: null,

    userParamDidChange: (function () {
      if (this.get('user')) {
        this.set('externalUserId', this.get('user'));
      }
    }).observes('user'),

    gotoDidChange: (function () {
      // console.log('gotoDidChange');
      if (!this.get('goto')) {
        return;
      }
      _ember['default'].run.next(this, function () {
        this.submitPin(this.get('c'));
      });
    }).observes('goto'),
    cDidChange: (function () {
      // console.log('cDidChange', this.get('forceResume'));
      if (!this.get('c')) {
        return;
      }
      _ember['default'].run.next(this, function () {
        if (this.get('user')) {
          if (this.get('canResume')) {
            this.resumeAndLogin();
          } else {
            //get login data from server
            var self = this;
            var data = { ext_user: this.get('user'), pin: this.get('c') };
            $.getJSON(Breadcrumb.api.siteUrl('/api/tournament/logindata_by_ext_user'), data).then(function (result) {
              // console.log('logindata_by_ext_user', arguments);
              _ember['default'].run(function () {
                console.log('logindata_by_ext_user', result);
                var playthrough = self.store.findWithSideload('playthrough', result['playthroughId'], 'game.flavors');
                var team = self.store.find('team', result['teamId']);
                _ember['default'].RSVP.all([playthrough, team, result['roleIndex']]).then(function (arr) {
                  return self.loginTeam(arr[0], arr[1], arr[2]);
                });
              });
            }, function () {
              // console.error('logindata_by_ext_user', arguments);
              self.submitPin(self.get('c'));
            });
          }
        } else {
          this.submitPin(this.get('c'));
        }
      });
    }).observes('c'),
    logincDidChange: (function () {
      this.set('c', this.get('login[c]'));
    }).observes('login[c]'),

    formIsVisible: (function () {
      return !this.get('c');
    }).property('c'),

    init: function init() {
      var self = this;
      self._super();
      Breadcrumb.NativeHooks.getDeviceInfo().then(function (device) {
        device.app_version = Breadcrumb.NativeHooks.getAppVersion();
        device.app_build = Breadcrumb.NativeHooks.getAppBuild();
        device.is_virtual = device["isVirtual"];
        self.set('deviceInfo', device);
        self.set('appVersion', device.app_version);
        self.set('appBuild', device.app_build);
      });
      if (!Breadcrumb.localStorage.test()) {
        self.set('error', "Your browser is in private mode. Unless you exit private mode, you'll need to log in again if you refresh the page.");
      }
    },

    saveResumePoint: function saveResumePoint(playthrough, team, roleIndex) {
      var game = playthrough.get('game');
      this.propertyWillChange('resumePoint');
      Breadcrumb.localStorage.setMany({
        "breadcrumb-save-playthrough-id": playthrough.id,
        "breadcrumb-save-team-id": team.id,
        "breadcrumb-save-role-index": roleIndex || 0,
        "breadcrumb-save-game-title": game.get('title') || game.get('name'),
        "breadcrumb-save-date": new Date().getTime()
      });
      this.propertyDidChange('resumePoint');
    },

    resumePoint: (function () {}).property().volatile(),

    clearResumePoint: function clearResumePoint() {
      this.propertyWillChange('resumePoint');
      Breadcrumb.localStorage.clearMany(["breadcrumb-save-playthrough-id", "breadcrumb-save-team-id", "breadcrumb-save-role-index", "breadcrumb-save-game-title", "breadcrumb-save-date"]);
      this.propertyDidChange('resumePoint');
      this.setProperties({ pin: '', error: '' });
    },

    canResume: (function () {
      if (!Breadcrumb.localStorage.get("breadcrumb-save-playthrough-id")) {
        return false;
      }
      if (!Breadcrumb.localStorage.get("breadcrumb-save-team-id")) {
        return false;
      }
      return true;
      // var dateSaved = Breadcrumb.localStorage.getInt("breadcrumb-save-date");
      // var isFromBeforeToday = (new Date().getTime() - dateSaved > (86400 * 1000));
      // return !isFromBeforeToday;
    }).property('resumePoint'),

    resumeGameTitle: (function () {
      return Breadcrumb.localStorage.get("breadcrumb-save-game-title");
    }).property('resumePoint'),

    isSubmitDisabled: (function () {
      var pin = this.get('pin');
      if (this.get('isLoggingIn')) {
        return true;
      }
      return !pin;
    }).property('pin', 'isLoggingIn'),

    isResumeDisabled: _ember['default'].computed.or('isLoggingIn', 'isResuming'),

    submitTitle: (function () {
      return this.get('isLoggingIn') ? 'Logging in...' : 'Log in';
    }).property('isLoggingIn'),

    getResumePlaythroughAndTeam: function getResumePlaythroughAndTeam() {
      var playthroughId = Breadcrumb.localStorage.get("breadcrumb-save-playthrough-id");
      var teamId = Breadcrumb.localStorage.get("breadcrumb-save-team-id");
      var roleIndex = parseInt(Breadcrumb.localStorage.get("breadcrumb-save-role-index"), 10);
      var playthrough = this.store.findWithSideload('playthrough', playthroughId, 'game.flavors');
      var team = this.store.find('team', teamId),
          self = this;
      return _ember['default'].RSVP.all([playthrough, team, roleIndex]);
    },

    allowQrs: (function () {
      return Breadcrumb.NativeHooks.useQrCodes();
    }).property(),

    createTeam: function createTeam(playthrough) {
      var self = this;
      return playthrough.triggerAction('createTeam').then(function (data) {
        // console.log('createTeam', data, self.get('externalUserId'));
        var serializer = self.store.serializerFor('team');
        var payload = serializer.extract(self.store, self.store.modelFor('team'), data, data.team.id, 'find');
        self.store.push('team', payload);
        return self.store.getById('team', data.team.id);
      });
    },

    createTeamAndLogin: function createTeamAndLogin(playthrough) {
      var self = this,
          roleIndex = 0; // new teams are always default role
      return self.createTeam(playthrough).then(function (team) {
        return self.loginTeam(playthrough, team, roleIndex);
      });
    },

    resumeOrCreateTeam: function resumeOrCreateTeam(playthrough) {
      // TODO : deprecate and get rid of this whole messy method
      // But if there is no team or the team is from a different playthrough,
      // go ahead and create a new one and return it.
      var resumePlaythroughId = Breadcrumb.localStorage.get("breadcrumb-save-playthrough-id");
      if (!this.get('canResume') || resumePlaythroughId !== playthrough.id) {
        return this.createTeam(playthrough);
      }
      // first see if our currently logged in
      // team or localStoraged team is in this playthrough. If so,
      // fallback and just log in as that team -- we don't want to
      // unintentionally create two new teams for this point.
      var self = this;
      return this.getResumePlaythroughAndTeam().then(function (arr) {
        var team = arr[1];
        if (!arr[1].get('isArchived')) {
          return arr[1];
        }
        // if team is archived, then clear resume point and create a new one.
        self.clearResumePoint();
        return self.createTeam(playthrough);
      });
    },

    getPlaythroughQuery: function getPlaythroughQuery(playthroughPin) {
      // if we have a playthrough ID sent in params, then use that.
      if (this.get('pid')) {
        return { id: this.get('pid') };
      }
      // otherwise, use the pin, and make sure playthrough is active.
      return { pin: playthroughPin, is_active: true, is_archived: false };
    },

    getTeamQuery: function getTeamQuery(playthrough, teamPin) {
      return { playthrough: playthrough.id, pin: teamPin };
    },

    submitPin: function submitPin(pin) {
      // console.log('submitPin', pin, this.get('isLoggingIn'));
      if (this.get('isLoggingIn')) {
        return;
      }
      return B.Pin.isNumeric(pin) ? this.submitNumericPin(pin) : this.submitAlphabeticPin(pin);
    },

    submitAlphabeticPin: function submitAlphabeticPin(pin) {
      var _this = this;

      if (pin && pin.toLowerCase) {
        pin = pin.toLowerCase().trim();
      }

      if (!B.Pin.validatePin(pin)) {
        this.set('error', 'Invalid code');
        return;
      }
      this.setProperties({ error: '', isLoggingIn: true });

      var playthroughPin = B.Pin.getPlaythroughPin(pin),
          teamPin = B.Pin.getTeamPin(pin),
          roleIndex = B.Pin.getRoleIndexFromPin(pin),
          pQuery = _ember['default'].$.extend(this.getPlaythroughQuery(playthroughPin), { sideload: 'game.flavors' });

      this.store.first('playthrough', pQuery).then(function (playthrough) {
        if (!playthrough) {
          throw new Error("Game not found");
        }
        // var roles = playthrough.get('game.blueprint.roles');
        // TODO: fix me - we have to make sure the roleIndex is valid but how
        // can we do this if blueprint for game is not loaded yet?
        if (roleIndex + 1 > 2) {
          throw new Error("Invalid code");
        }
        if (teamPin) {
          return _this.getTeamByPinAndLogin(playthrough, teamPin, roleIndex);
        } else {
          return _this.createTeamAndLogin(playthrough);
        }
      }).then(function () {
        _this.set('isLoggingIn', false);
        _this.set('c', null);
      })['catch'](function (err) {
        _this.set('isLoggingIn', false);
        if (err.message === 'TransitionAborted') {
          return;
        } // ignore redirects
        console.error('catch err', err);
        _this.set('error', err.message || "Error logging in"); // show errors
      });
    },

    submitNumericPin: function submitNumericPin(pin) {
      // TODO : Deprecate numeric pins
      console.error('method "submitNumericPin" is deprecated and should not be used anymore');
      throw 'method "submitNumericPin" is deprecated and cannot be used anymore';
      // this.setProperties({error: '', isLoggingIn: true});
      // var playthroughPin = pin.substr(0, 2), teamPin = pin.substr(2, 3);
      // var tryResume = (teamPin === "0");
      // var roleCode = parseInt(pin.substr(5, 1), 10) || 0;
      // var roleIndex = tryResume ? 0 : ((roleCode / 3) - 1);
      // // role code must be 3, 6 or 9 to prevent accidental mistypes
      // var roleCodeIsValid = true;
      // if(!tryResume) { roleCodeIsValid = roleCode > 0 && (roleCode % 3 === 0); }
      // var pQuery = Ember.$.extend(this.getPlaythroughQuery(playthroughPin),
      //   {sideload: 'game.flavors'});
      //
      // this.store.first('playthrough', pQuery).then(playthrough => {
      //   if(!playthrough) { throw new Error("Game not found"); }
      //   if(tryResume) { return this.resumeOrCreateTeamAndLogin(playthrough); }
      //   // Otherwise, look up team and then log into that team, or error.
      //   if(!roleCodeIsValid) { throw new Error('Invalid code'); }
      //
      //   Breadcrumb.Logging.debug({
      //     pin: playthroughPin,
      //     query: pQuery,
      //     playthrough_id: playthrough.id,
      //     playthrough: playthrough
      //   });
      //
      //   return this.getTeamByPinAndLogin(playthrough, teamPin, roleIndex);
      // }).then(null, err => {
      //   if(err.message === 'TransitionAborted') { return; } // ignore redirects
      //   this.set('error', err.message || "Error logging in"); // show others
      // }).then(() => {
      //   this.set('isLoggingIn', false);
      // });
    },

    getTeamByPinAndLogin: function getTeamByPinAndLogin(playthrough, teamPin, roleIndex) {
      var _this2 = this;

      var params = this.getTeamQuery(playthrough, teamPin);
      return this.store.first('team', params).then(function (team) {
        if (!team) {
          throw new Error("Team not found");
        }
        return _this2.loginTeam(playthrough, team, roleIndex);
      });
    },

    resumeAndLogin: function resumeAndLogin() {
      var _this3 = this;

      return this.getResumePlaythroughAndTeam().then(function (arr) {
        return _this3.loginTeam(arr[0], arr[1], arr[2]);
      });
    },

    resumeOrCreateTeamAndLogin: function resumeOrCreateTeamAndLogin(playthrough) {
      var _this4 = this;

      var roleIndex = Breadcrumb.localStorage.getInt("breadcrumb-save-role-index");
      return this.resumeOrCreateTeam(playthrough).then(function (team) {
        return _this4.loginTeam(playthrough, team, roleIndex || 0);
      });
    },

    loginTeam: function loginTeam(playthrough, team, roleIndex) {
      var _this5 = this;

      var retryId = this.get('retry');
      var self = this;
      return self.get('controllers.identity').loginTeam(playthrough.id, team.id, roleIndex).then(function () {
        var saveTeam = false;
        if (self.get('externalUserId')) {
          team.set('externalUserId', self.get('externalUserId'));
          saveTeam = true;
        }
        var device = self.get('deviceInfo');
        if (device && device.name) {
          team.set('deviceName', device.name);
          team.set('deviceInfo', device);
          team.triggerAction('add_device', { "device_info": device }).then(function () {}, function () {
            console.error('team.triggerAction add_device error, args:', arguments);
          });
          saveTeam = true;
        }
        if (saveTeam) {
          team.save();
        }
        return team.get('activeWaypoints');
      }).then(function (activeWaypoints) {
        if (retryId) {
          var retryWaypoint = _this5.store.find('waypoint', retryId);
          return _this5.transitionToRoute('playRetry.waypoint', playthrough.id, retryWaypoint);
        }
        //        if(!team.get('hasCompletedGame') && !activeWaypoints.get('length')) {
        //          console.log('redirect');
        //        }
        return _this5.transitionToRoute('play.play', playthrough.id);
      });
    },

    getUpdateStatus: function getUpdateStatus() {
      this.set('isGettingUpdateAvailability', true);
      Breadcrumb.NativeHooks.getUpdateAvailability();
    },

    promptDeviceId: function promptDeviceId() {
      var self = this;
      Breadcrumb.NativeHooks.prompt("Enter the phone's cell number.", function (deviceId) {
        if (deviceId !== null) {
          Breadcrumb.NativeHooks.setDeviceId(deviceId);
          self.get('controllers.identity').set('deviceId', deviceId);
        }
      }, "Init Device Id");
    },

    loadDeviceId: function loadDeviceId() {
      // TODO: fix this
      // Breadcrumb.NativeHooks.getDeviceId()
      //   .then(deviceId => {
      //     this.get('controllers.identity').set('deviceId', deviceId);
      //   });
    },

    deviceName: (function () {
      return this.get('deviceInfo.name');
    }).property('deviceInfo.name'),

    deviceId: (function () {
      return this.get('controllers.identity.deviceId');
    }).property('controllers.identity.deviceId'),

    getLoginDataFromQrValue: function getLoginDataFromQrValue(value) {
      // todo handle urls without get params
      return Breadcrumb.Utils.deparam(value.split('?')[1] || "");
    },

    actions: {
      resumeGame: function resumeGame() {
        var self = this;
        this.set('isResuming', true);
        return this.resumeAndLogin().then(null, function (err) {
          self.set('error', "Error logging in");
        }).then(function () {
          self.set('isResuming', false);
        });
      },

      scanQr: function scanQr() {
        var self = this;
        Breadcrumb.NativeHooks.getQrCode(function (value) {
          try {
            var data = self.getLoginDataFromQrValue(value);
            self.submitPin(data['login[c]']);
          } catch (err) {
            var route = value.split("play#")[1];
            // todo validate url
            self.transitionToRoute(route);
          }
        }, function (err) {
          if (err && err.type === 'EXPECTED_FAILURE' && err.message === 'User cancelled') {
            return;
          }
          self.set('error', 'Error scanning bar code');
        });
      },

      submitPin: function submitPin(code) {
        this.submitPin(this.get('pin'));
      },

      gotoNew: function gotoNew() {
        this.clearResumePoint();
      }
    }
  });

  exports['default'] = LoginController;
});