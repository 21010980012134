define('play/routes/playthrough/admin', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlaythroughAdminRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'admin',
    renderTemplate: function renderTemplate(controller, model) {
      this.render('playthrough/admin', { into: 'playthrough' });
    }
  });

  exports['default'] = PlaythroughAdminRoute;
});