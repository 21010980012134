define('play/controllers/play-home-in-progress', ['exports', 'ember'], function (exports, _ember) {

  var PlayHomeInProgressController = _ember['default'].Controller.extend({
    needs: ['identity', 'playthrough', 'playWaypoint'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    numMissionsCompleted: _ember['default'].computed.oneWay('team.history.length'),
    score: _ember['default'].computed.oneWay('team.pointsTotal'),
    game: _ember['default'].computed.oneWay('controllers.playthrough.content.playthrough.game'),

    isTeamGated: (function () {
      return this.get('team.status') === Breadcrumb.TeamStatuses.GATED;
    }).property("team.status"),

    isReentered: false,

    reenterMessage: (function () {
      var message = _ember['default'].I18n.t('home.reentered.message');
      return message;
    }).property("isReentered"),

    teamGatedMessage: (function () {
      var message,
          playthrough = this.get('playthrough');
      message = this.get('game.teamGatedMessage');
      if (!message) {
        message = _ember['default'].I18n.t('home.inprogress.gated_message', { teamname: this.get('team.name') });
      }
      message = this.get('controllers.playWaypoint').preprocessInstructions(message);
      var html = Breadcrumb.Utils.markdownToHtml(message);
      html = this.get('controllers.playWaypoint').postprocessInstructionsHtml(html);
      return new _ember['default'].Handlebars.SafeString(html);
    }).property('playthrough.status', 'game.endedEarlyMessage', 'game.endedMessage')
  });

  exports['default'] = PlayHomeInProgressController;
});