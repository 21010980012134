define('play/views/play-modal-base', ['exports', 'ember'], function (exports, _ember) {

  var PlayModalBaseView = _ember['default'].View.extend({
    classNames: 'app-play-modal',
    controllerName: 'playModal',
    isVisible: _ember['default'].computed.bool('controller.isVisible'),

    isVisibleDidChange: (function () {
      if (this.get('isVisible') && this.$()) {
        window.scrollTo(0, 0);
        this.$().height(Math.max($('body').height(), $('#appContainer').height(), window.innerHeight));
      }
    }).observes('isVisible')
  });

  exports['default'] = PlayModalBaseView;
});