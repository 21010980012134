define('play/controllers/location', ['exports', 'ember'], function (exports, _ember) {

  var LocationController = _ember['default'].Controller.extend({

    isWatching: false,
    watchId: null,

    lastError: null,
    lastFix: null,

    deferredRequests: [],

    handleFix: function handleFix(fix) {
      this.setProperties({ lastFix: fix, lastError: null });
    },

    handleError: function handleError(error) {
      this.setProperties({ lastFix: null, lastError: error });
    },

    lastErrorTitle: (function () {
      var error = this.get('lastError');
      if (!error) {
        return null;
      }
      switch (error.code) {
        case 'NO_GEOLOCATION':
          return "This device does not support geolocation.";
        case error.PERMISSION_DENIED:
          return "User denied the request for geolocation.";
        case error.POSITION_UNAVAILABLE:
          return "Location information is unavailable.";
        case error.TIMEOUT:
          return "The request to get user location timed out.";
        case error.UNKNOWN_ERROR:
          return "An unknown error occurred.";
      }
    }).property('lastError'),

    startWatching: function startWatching() {
      if (this.get('isWatching')) {
        return;
      }
      var watchId = Breadcrumb.Location.watch(B.bind(this.handleFix, this), B.bind(this.handleError, this));
      this.setProperties({ isWatching: true, watchId: watchId });
      return this.getCurrentOrDeferred().then(null, function () {
        // dismiss errors
      });
    },

    endWatching: function endWatching() {
      if (!this.get('isWatching')) {
        return;
      }
      Breadcrumb.Location.unwatch(this.get('watchId'));
      this.setProperties({ isWatching: false, watchId: null });
    },

    getCurrentOrDeferred: function getCurrentOrDeferred() {
      var deferred = new _ember['default'].RSVP.defer();
      if (this.get('lastFix')) {
        deferred.resolve(this.get('lastFix'));
      } else if (this.get('lastError')) {
        deferred.reject(this.get('lastError'));
      } else {
        this.deferredRequests.push(deferred);
      }
      return deferred.promise;
    },

    requestCurrent: function requestCurrent() {
      Breadcrumb.Location.getCurrent(B.bind(this.handleFix, this), B.bind(this.handleError, this));
      return this.getCurrentOrDeferred();
    }

  });

  exports['default'] = LocationController;
});