define('play/routes/play/info', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlayInfoRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'info',
    renderTemplate: function renderTemplate() {
      this.render('play/info', { into: 'playthrough' });
    }
  });

  exports['default'] = PlayInfoRoute;
});