define('play/controllers/play-sync-waypoints', ['exports', 'ember', 'play/controllers/play-sync-waypoint'], function (exports, _ember, _playControllersPlaySyncWaypoint) {

  var PlaySyncWaypointsController = _ember['default'].ArrayController.extend({
    numCompleted: 0,
    completedWaypointsSyncedIds: null,

    init: function init() {
      this._super();
      this.set('completedWaypointsSyncedIds', []);
    },

    syncDidComplete: function syncDidComplete(waypointController) {
      this.incrementProperty('numCompleted');
      this.removeObject(waypointController);
    },

    add: function add(waypoint, syncAction, updates, isCompleted) {
      var waypointController = _playControllersPlaySyncWaypoint['default'].create({
        content: waypoint,
        syncAction: syncAction,
        updates: updates,
        isCompleted: isCompleted,
        parentController: this
      });
      this.pushObject(waypointController);
      if (isCompleted) {
        this.markAsCompleted(waypoint.get('id'));
      }
      return waypointController;
    },

    markAsCompleted: function markAsCompleted(waypointId) {
      this.propertyWillChange('completedWaypointsSyncedIds');
      this.get('completedWaypointsSyncedIds').push(waypointId);
      this.propertyDidChange('completedWaypointsSyncedIds');
    },

    addAndSync: function addAndSync(waypoint, syncAction, updates, isCompleted) {
      var controller = this.add(waypoint, syncAction, updates, isCompleted);
      return controller.sync();
    }
  });

  exports['default'] = PlaySyncWaypointsController;
});