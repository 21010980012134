define('play/controllers/playthrough/signup', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughSignupController = _ember['default'].Controller.extend({
    needs: ['playthrough', 'identity', 'playLoginRoles', 'playthroughRealtimeTest', 'play', 'playWaypoint'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    game: _ember['default'].computed.oneWay('playthrough.game'),

    i18n: _ember['default'].inject.service(),
    email: '',
    error: null,
    message: null,

    isSigningUp: false,
    didSignUp: false,

    signupAllowed: (function () {
      return !this.get('isSigningUp') && !this.get('didSignUp');
    }).property('isSigningUp', 'didSignUp'),

    gameTitle: (function () {
      return this.get('game.title') || this.get('i18n').t('intro.title').toString();
    }).property('game.title'),

    welcomeMessage: (function () {
      var welcomeMessage = this.get('game.welcomeMessage') || this.get('i18n').t('intro.welcome').toString();
      welcomeMessage = this.get('controllers.playWaypoint').preprocessInstructions(welcomeMessage);
      var html = Breadcrumb.Utils.markdownToHtml(welcomeMessage);
      html = this.get('controllers.playWaypoint').postprocessInstructionsHtml(html);
      return new _ember['default'].Handlebars.SafeString(html);
    }).property('game.welcomeMessage'),

    checkEmailMessage: (function () {
      return this.get('i18n').t('signup.check_email').toString();
    }).property(),

    isSignupDisabled: (function () {
      if (_ember['default'].isEmpty(this.get('email'))) {
        return true;
      }
      return !this.get('signupAllowed');
    }).property('email', 'signupAllowed'),

    actions: {
      signup: function signup() {
        var _this = this;

        if (this.get('isSigningUp')) {
          return;
        }

        var device = navigator.userAgent,
            email = this.get('email'),
            playthrough = this.get('playthrough'),
            signupParams = { email: email, host: window.location.origin, device: device };

        this.setProperties({ error: null, isSigningUp: true });

        return playthrough.triggerAction('findOrCreatePlayer', signupParams).then(function (response) {
          if (response.success) {
            _this.set('didSignUp', true);
            _this.set('message', _this.get('checkEmailMessage'));
          } else {
            _this.set('error', _this.get('i18n').t(response.reason).toString());
          }
        }, function (err) {
          _this.set('error', _this.get('i18n').t('signup.error').toString());
        }).then(function () {
          _this.set('isSigningUp', false);
        });
      }
    }
  });

  exports['default'] = PlaythroughSignupController;
});