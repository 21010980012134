define('play/controllers/play-modal-base', ['exports', 'ember'], function (exports, _ember) {

  var PlayModalBaseController = _ember['default'].Controller.extend({
    isVisible: false,

    actions: {
      show: function show(props) {
        this.set('isVisible', true);
      }
    }
  });

  exports['default'] = PlayModalBaseController;
});