define('play/controllers/playthrough/admin', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughAdminController = _ember['default'].Controller.extend({
    needs: ['play', 'playWaypoint', 'playthrough', 'identity', 'login', 'playRole', 'playthroughRealtimeTest'],
    team: _ember['default'].computed.alias('controllers.play.team'),
    playthrough: _ember['default'].computed.alias('controllers.playthrough.playthrough'),

    heartbeatFailed: _ember['default'].computed.alias('controllers.playthroughRealtimeTest.heartbeatFailed'),
    heartbeatInProgress: _ember['default'].computed.alias('controllers.playthroughRealtimeTest.heartbeatInProgress'),
    heartbeatTime: _ember['default'].computed.alias('controllers.playthroughRealtimeTest.heartbeatTime'),

    buildstamp: (function () {
      if (!Breadcrumb.BUILDSTAMP) {
        return null;
      }
      return moment(Breadcrumb.BUILDSTAMP).format('MMM D h:mm:ssa z');
    }).property(),

    roles: _ember['default'].computed.oneWay('controllers.playRole.roles'),

    isAutomaticDidChange: (function () {
      if (this.get('controllers.play.isOnAutomatic')) {
        this.get('controllers.playWaypoint').setProperties({
          isRevealingAnswers: true,
          isAutofillingMedia: true
        });
      }
    }).observes('controllers.play.isOnAutomatic'),

    actions: {
      cancelAdmin: function cancelAdmin() {
        this.get('controllers.identity').set('admin', false);
        this.get('controllers.play').set('isFakingLocation', false);
        this.get('controllers.playWaypoint').set('isRevealingAnswers', false);
        this.transitionToRoute("play.home");
      },
      logout: function logout() {
        this.get('controllers.play').setProperties({
          isFakingLocation: false,
          isOnAutomatic: false
        });
        this.get('controllers.playWaypoint').setProperties({
          isRevealingAnswers: false,
          isAutofillingMedia: false
        });
        this.get('controllers.login').clearResumePoint();
        this.get('controllers.identity').logout(this.get('playthrough.id'));
        this.transitionToRoute("login");
      },
      hardRestart: function hardRestart() {
        if (!confirm("Are you sure you'd like to restart the game interface? This will interrupt any current photo or video uploads, so ideally wait until they have finished.")) {
          return;
        }
        window.location = window.location.pathname; // reset href
      },
      testRealtime: function testRealtime() {
        this.get('controllers.playthroughRealtimeTest').send('testRealtime');
      }
    }
  });

  exports['default'] = PlaythroughAdminController;
});
/* globals moment */