define('play/views/submission-media-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionMediaWidget = _playViewsSubmissionBaseWidget['default'].extend({
    classNames: ['submission-widget', 'submission-media-widget'],
    templateName: "submission-media",
    isReady: _ember['default'].computed.bool('file'),
    isError: false,
    errorMessage: null,
    fileBinding: 'controller.file',

    init: function init() {
      // This is done since the template isn't loaded on class init
      //this.template = Ember.TEMPLATES.submission_media;
      this._mediaAdded = false;
      this._super();
    },

    validate: function validate(submission) {
      if (this.get('options.optional')) {
        return true;
      }
      // have to check for have you actually added media. Otherwise, this will
      // validate if you're retrying an old submission.
      if (!this._mediaAdded) {
        return false;
      }
      return submission && !!submission.file;
    },

    resetEntry: function resetEntry() {
      var submission = {};
      if (this.get('controller.isAutofillingMedia')) {
        submission = _ember['default'].copy(this.get('controller.correctSubmission'));
      }
      if (submission && submission.file === 'dummy') {
        var mimeType = this.get('mimeTypes')[0];
        var file = { type: mimeType, dummy: true, size: 0 };
        this.setProperties({ file: file, submission: { file: mimeType } });
        this._mediaAdded = true;
      }
      this.performValidation();
    },

    isRevealingAnswersDidChange: (function () {
      // this.resetEntry(); // don't affect media
    }).observes('controller.isRevealingAnswers'),

    isAutofillingMediaDidChange: (function () {
      _ember['default'].run.next(this, 'resetEntry');
    }).observes('controller.isAutofillingMedia'),

    blankSubmission: {},

    isAndroid: (function () {
      return Breadcrumb.Utils.isAndroid();
    }).property(),
    isIOS: (function () {
      return Breadcrumb.Utils.isIOS();
    }).property(),
    isBlackberry: (function () {
      return Breadcrumb.Utils.isBlackberry();
    }).property(),
    isMobile: (function () {
      return Breadcrumb.Utils.isMobile();
    }).property(),

    dragOver: function dragOver(e) {
      e.preventDefault();
      return false;
    },

    dragEnter: function dragEnter(e) {
      e.preventDefault();
      return false;
    },

    dragLeave: function dragLeave(e) {
      e.preventDefault();
      return false;
    },

    drop: function drop(e) {
      if (e.dataTransfer && e.dataTransfer.files) {
        this.setFile(e.dataTransfer.files[0]);
      }
      e.preventDefault();
      return false;
    },

    uploadLabel: (function () {
      var isMobile = this.get('isMobile');
      var label = isMobile ? 'mobile' : 'desktop';
      if (this.get('options.optional')) {
        label += '.optional';
      }
      return _ember['default'].I18n.t('mission.upload.' + label, { mediaType: this.get('mediaTypeTitle') });
    }).property('isMobile', 'options'),

    didInsertElement: function didInsertElement() {
      var fileInput = this.$('form input[type=file]');
      fileInput.on('change.media-upload', B.bind(this.fileDidChange, this));

      this.restoreFile();
      this.doNativeBinds();
    },

    willClearRender: function willClearRender() {
      var fileInput = this.$('form input[type=file]');
      if (fileInput) {
        fileInput.off('change.media-upload');
      }
      this.setProperties({ file: null, isError: false });
      if (Breadcrumb.NativeHooks.useNativeMedia() && this.$('form')) {
        this.$('form').off('click.native-media-upload');
        this.$('form').off('touchstart.native-media-upload');
        this.$('form').off('touchend.native-media-upload');
      }
    },

    doNativeBinds: function doNativeBinds() {
      if (Breadcrumb.NativeHooks.useNativeMedia()) {
        this.$('form').on('click.native-media-upload', B.bind(this.didClickNativeForm, this));
        this.$('form').on('touchstart.native-media-upload', B.bind(this.didTouchStartNativeForm, this));
        this.$('form').on('touchend.native-media-upload', B.bind(this.didTouchNativeForm, this));
      } else {}
    },

    restoreFile: function restoreFile() {
      var behavior = this.get('controller.controllers.playWaypoint.behavior');
      if (behavior.autosubmit) {
        return;
      } // avoid infinite submit
      var storedPartData = this.get('controller.storedData');
      if (storedPartData && storedPartData['file']) {
        this.setFile(storedPartData.file);
      }
    },

    resetFileUpload: function resetFileUpload() {
      this.$('.upload_container').html('<input type="file" name="file" id="fileUpload' + this.get('elementId') + '" accept="' + this.get('accept') + '">');
      var fileInput = this.$('form input[type=file]');
      fileInput.on('change.media-upload', B.bind(this.fileDidChange, this));
      this.doNativeBinds();
    },

    fileDidChange: function fileDidChange() {
      if (this.get('file')) {
        return;
      }
      var fileInput = this.$('form input[type=file]')[0];
      var file = fileInput.files[0];
      if (!file) {
        return;
      }
      file.timestamp = Math.floor(Breadcrumb.now().getTime() / 1000);
      this.setFile(file);
    },

    didClickNativeForm: function didClickNativeForm(e) {
      e.preventDefault();
      return false;
    },

    didTouchStartNativeForm: function didTouchStartNativeForm(e) {
      this._scrollTop = $(window).scrollTop();
    },

    didTouchNativeForm: function didTouchNativeForm(e) {
      var _this = this;

      // don't proceed if we've scrolled more than 10 pixels.
      var scrollDiff = $(window).scrollTop() - (this._scrollTop || 0);
      if (Math.abs(scrollDiff) > 10) {
        return;
      }
      this.showNativePending();

      this.getNativeMedia().then(function (result) {
        _this.nativeMediaDidSucceed(result);
      }, function (reason) {
        _this.nativeMediaDidError(reason);
      });
      return false;
    },

    showNativePending: function showNativePending() {
      this.$('.media-upload-form').hide();
      this.$('.alert-native-media-pending').show();
    },

    hideNativePending: function hideNativePending() {
      this.$('.media-upload-form').show();
      this.$('.alert-native-media-pending').hide();
    },

    nativeMediaDidSucceed: function nativeMediaDidSucceed(file) {
      //Breadcrumb.NativeHooks.logging.debug(JSON.stringify(file));
      var uri = file.uri;
      var ext = (uri.split('&ext=')[1] || // native camera
      uri.substring(uri.lastIndexOf(".") + 1) || // custom video module
      "").toLowerCase();
      //native android camera returns file.type="image" for taken picture
      ext = uri.lastIndexOf(".") > -1 ? ext : file.type;
      var mimeTypes = { png: 'image/png', jpg: 'image/jpeg', jpeg: 'image/jpeg', mov: 'video/quicktime',
        image: 'image/jpeg',
        mp4: 'video/mp4',
        m4v: 'video/x-m4v', flv: 'video/x-flv', ts: 'video/MP2T', avi: 'video/x-msvideo', tif: 'image/tiff',
        tiff: 'image/tiff',
        wmv: 'video/x-ms-wmv', ogv: 'video/ogg', webm: 'video/webm', mpg: 'video/mpeg', mpeg: 'video/mpeg' };
      //Breadcrumb.NativeHooks.logging.log(ext + "\n" +JSON.stringify(mimeTypes));
      if (!mimeTypes[ext]) {
        throw new Error("Invalid extension " + ext);
      }
      if (!file.name || file.name.toLowerCase() === "photo" || file.name.toLowerCase() === "video" || file.name.toLowerCase() === "image" || file.name.toLowerCase() === "movie") {
        // todo: change the name value to a correct filename in native module
        var path = uri.split('/');
        file.name = path[path.length - 1];
      }
      this.setFile({
        type: mimeTypes[ext],
        name: file.name,
        size: file.size,
        timestamp: file.timestamp,
        _nativeFile: file
      });
      this.hideNativePending();
    },

    nativeMediaDidError: function nativeMediaDidError(err) {
      Breadcrumb.NativeHooks.logging.error(err);
      this.hideNativePending();
      // default iphone native camera
      if (err && err.type === 'EXPECTED_FAILURE' && /cancelled/i.test(err.message)) {
        this.setProperties({
          isError: true,
          errorMessage: err.message
        });
        return;
      }
      // default iphone native gallery
      if (err && /no image selected/i.test(err)) {
        this.setProperties({
          isError: true,
          errorMessage: err
        });
        return;
      }
      // TGGPlayMedia module
      if (err && err === 'user_cancelled') {
        this.setProperties({
          isError: true,
          errorMessage: _ember['default'].I18n.t('mission.upload.native_media_cancelled')
        });
        return;
      }
      // default Android natige gallery
      if (err && /cancelled/i.test(err)) {
        this.setProperties({
          isError: true,
          errorMessage: err
        });
        return;
      }

      this.setProperties({
        isError: true,
        errorMessage: _ember['default'].I18n.t('mission.upload.native_add_error')
      });
      this.hideNativePending();
    },

    maxAcceptSize: (function () {
      var app = this.container.lookup('application:main');
      return !Breadcrumb.Utils.isSafari() ? Infinity : app.get('maxUploadSize');
    }).property(),

    setFile: function setFile(file) {
      this._mediaAdded = false;
      if (!file) {
        return false;
      }
      // Check ok size.
      if (file.size > this.get('maxAcceptSize')) {
        this.setProperties({
          isError: true,
          errorMessage: _ember['default'].I18n.t('mission.upload.toolarge')
        });
        return false;
      }
      // Check right type
      if (this.get('mimeTypes').indexOf(file.type) === -1) {
        this.setProperties({
          isError: true,
          errorMessage: _ember['default'].I18n.t('mission.upload.invalidtype')
        });
        return false;
      }
      // Set file to ok
      this.setProperties({
        file: file,
        isError: false,
        submission: { file: file.type }
      });
      this._mediaAdded = true;
      this.performValidation();
      return true;
    },

    clearFile: function clearFile() {
      if (!this.get('file')) {
        return;
      }
      _ember['default'].run(this, function () {
        this.setProperties({
          file: null,
          isError: false,
          submission: null,
          hasReviewed: false
        });
      });
      this.resetFileUpload();
      this.performValidation();
    },

    nativeOptions: (function () {
      var mission_gallery_select_enabled = this.get('controller.waypoint.step.content.select_gallery_media_enabled'),
          game_gallery_select_enabled = this.get('controller.playthrough.game.options.select_gallery_media_enabled'),
          is_admin = this.get('controller.admin');

      var allow_player_gallery = null;

      // decide which setting to use.
      // setting on the mission overrides setting on the game, if they are both set and different
      if (game_gallery_select_enabled === mission_gallery_select_enabled) {
        allow_player_gallery = game_gallery_select_enabled;
      } else {
        if (mission_gallery_select_enabled === undefined) {
          allow_player_gallery = game_gallery_select_enabled;
        } else {
          allow_player_gallery = mission_gallery_select_enabled;
        }
      }

      if (is_admin || allow_player_gallery) {
        return { allowCameraRoll: true };
      }
      return {};
    }).property('controller.admin', 'controller.playthrough.game.options.select_gallery_media_enabled', 'controller.waypoint.step.content.select_gallery_media_enabled'),

    showAndroidVideoWarning: function showAndroidVideoWarning() {
      // made this alert deprecated. we don't need this restrictions in 2018 anymore
      // var lastShown = Breadcrumb._androidWarningShownAt || 0;
      // var timesShown = Breadcrumb._timesAndroidWarningShown || 0;
      // var lastShownSince = new Date().getTime() - lastShown;
      // var showInterval = 5 * 60 * 1000;
      // if(!Breadcrumb.NativeHooks.useNativeMedia() &&
      //     this.get('isAndroid') && this.get('isVideo') &&
      //     lastShownSince > showInterval && timesShown < 3) {
      //   Breadcrumb.Utils.alert(Ember.I18n.t("mission.upload.tip.video.android"));
      //   Breadcrumb._androidWarningShownAt = new Date().getTime();
      //   Breadcrumb._timesAndroidWarningShown = timesShown + 1;
      // }
    },

    actions: {
      clearFile: function clearFile() {
        this.clearFile();
      }
    }
  });

  exports['default'] = SubmissionMediaWidget;
});