define('play/routes/play-mode', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlayModeRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: (function () {
      return this.modelFor('playMode');
    }).property().volatile(),

    model: function model(params) {
      return params.mode_name;
    },

    setupController: function setupController(controller, model) {
      controller.set('modeName', model);
      this.checkPlaythroughStatus();
    },

    serialize: function serialize(model) {
      return { mode_name: model };
    },

    checkPlaythroughStatus: function checkPlaythroughStatus() {
      var hasStarted = this.modelFor('playthrough').playthrough.get('hasStarted');
      if (!hasStarted) {
        this.transitionTo('playthrough.intro');return;
      }
      var isPaused = this.modelFor('playthrough').playthrough.get('isPaused');
      if (isPaused) {
        this.transitionTo('play.home');
      }
      var modeName = this.modelFor('playMode');
      var mode = this.controllerFor('playModes').findBy('modeName', modeName);
      // if mode is game over or invisible and is current, go back to home.
      if (!mode || !mode.get('isVisible') || mode.get('isDisabled')) {
        this.transitionTo('play.home');
      }
    },

    actions: {
      playthroughStatusDidChange: function playthroughStatusDidChange() {
        this.checkPlaythroughStatus();
        return true;
      },
      didTransition: function didTransition() {
        this.checkPlaythroughStatus();
      }
    }
  });

  exports['default'] = PlayModeRoute;
});