define('play/controllers/play-waypoint', ['exports', 'ember', 'play/i18n', 'play/upload', 'play/views/play-behavior'], function (exports, _ember, _playI18n, _playUpload, _playViewsPlayBehavior) {

  var PlayWaypointController = _ember['default'].Controller.extend({
    needs: ['identity', 'play', 'playthrough', 'playSubmission', 'playSubmissionParts', 'location', 'playMessage', 'playSyncUploads', 'application', 'playSyncWaypoints', 'playWaypointProgress', 'playRole', 'playModes', 'gameOptions'],
    i18n: _ember['default'].inject.service(),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    waypoint: _ember['default'].computed.oneWay('content'),
    mission: _ember['default'].computed.oneWay('waypoint.mission'),
    step: _ember['default'].computed.oneWay('waypoint.step'),

    hasSubmitted: false,
    isSubmitting: false,
    isAutosubmitting: false,
    currentImageIndices: null,

    startingWaypoint: null,
    startedAt: _ember['default'].computed.alias('controllers.playWaypointProgress.startedAt'),
    pendingSubmissions: _ember['default'].computed.alias('controllers.playWaypointProgress.pendingSubmissions'),
    numHintsUsed: _ember['default'].computed.alias('controllers.playWaypointProgress.numHintsUsed'),

    isRevealingAnswers: false, // reveal answers
    isAutofillingMedia: false, // auto fill photos & videos
    error: null,
    isRendered: false,

    init: function init() {
      var _this = this;

      this._super();
      this.contentDidChange();
      this.set('currentImageIndices', {});
      // Ember.run.schedule
      // trick
      _ember['default'].run.schedule("afterRender", this, function () {
        _this.mapInstructionLinks();
        _this.isRendered = true;
      });
    },
    showStats: (function () {
      return this.get('points') || this.get('waypoint.isLinked');
    }).property('points', 'waypoint.isLinked'),

    shouldShowSubmit: (function () {
      if (this.get('behavior.autosubmit')) {
        return false;
      }
      if (this.get('behavior.showSubmit') === false) {
        return false;
      }
      return true;
    }).property('behavior.autosubmit', 'behavior.showSubmit'),

    points: (function () {
      var blueprint = this.get('playthrough.game.blueprint');
      if (blueprint.get('data.hide_mission_points') === true) {
        return false;
      }
      return Math.floor(this.get('mission.points') / this.get('mission.steps.length'));
    }).property('mission.points'),

    playthroughDidChange: (function () {
      this.set('isRevealingAnswers', false);
    }).observes('playthrough.id'),

    fakeSubmissionDelay: (function () {
      return BREADCRUMB_ENV.TESTING ? 100 : 1000;
    }).property(),

    behavior: (function () {
      var behaviorName = this.get('step.behaviorName') || 'info';
      var stepContent = this.get('step.contentWithDefaults'),
          behaviorClassName = behaviorName.classify() + 'Behavior',
          behaviorClass = _playViewsPlayBehavior['default'][behaviorClassName];
      _ember['default'].assert('Should have behavior ' + behaviorName, !!behaviorClass);
      return behaviorClass.create({ content: stepContent, name: behaviorName });
    }).property('step'),

    correctSubmission: _ember['default'].computed.oneWay('behavior.correct'),

    preProcessVariables: (function () {
      var gamePlan = this.get('controllers.playthrough.model.gamePlan.content')[0];
      var variables = { teamname: this.get('team.name') },
          key;
      // add game variables
      var gameVars = this.get('playthrough.game.variables') || {};
      for (key in gameVars) {
        if (gameVars.hasOwnProperty(key)) {
          variables[key.toLowerCase()] = gameVars[key] || "";
        }
      }
      if (!variables["gameproducer"] && gamePlan) {
        variables["gameproducer"] = gamePlan.get('gameProducer');
      }
      // add team profile values
      var teamProfile = this.get('team.profile') || {};
      for (key in teamProfile) {
        if (key !== 'photo') {
          variables['profile:' + key.toLowerCase()] = teamProfile[key];
        }
      }

      // add login codes
      var roleNames = this.get('controllers.playRole.roleNames');
      var playthroughPin = this.get('playthrough.pin');
      var baseLoginCode = playthroughPin + this.get('team.pin');

      roleNames.forEach(function (roleName, roleIndex) {
        var roleCode = Breadcrumb.Pin.getRoleCode(roleIndex, playthroughPin);
        variables['login:' + roleName] = baseLoginCode + roleCode;
      }, this);

      return variables;
    }).property('step'),

    mapInstructionLinks: function mapInstructionLinks() {
      var _this2 = this;

      var links = Array.from(document.querySelectorAll(".instruction-link"));
      links.filter(function (el) {
        return el.dataset && el.dataset.mode !== undefined;
      }).forEach(function (el) {
        el.addEventListener('click', function () {
          if (!el.dataset.mode) {
            _this2.transitionToRoute('playMode.home', 'home');
          } else {
            _this2.transitionToRoute('playMode.home', el.dataset.mode);
          }
        });
      });
    },

    getPreProcessVariable: function getPreProcessVariable(key) {
      return this.get('preProcessVariables')[key.toLowerCase()] || "{{" + key.replace('_', 'UNDERSCORE') + "}}";
    },

    preprocessInstructions: function preprocessInstructions(instructions) {
      var self = this;
      instructions = instructions.replace(/\{\{([\w_:]+)\}\}/gi, function (m, p1) {
        return self.getPreProcessVariable(p1);
      });
      instructions = instructions.replace(/\-\|([\w_:]+)\|\-/gi, function (m, p1) {
        return self.getPreProcessVariable(p1);
      });
      return instructions;
    },

    postProcessReplacements: (function () {
      var i18n = this.get('i18n');
      // create replacement macros for tab buttons
      var playRoot = '#/p/' + this.get('playthrough.id') + '/play',
          url;
      url = '<a href="%@" data-mode="" class="instruction-link">%@</a>'.fmt(playRoot, i18n.t('nav.home').toString());
      var postReplacements = { 'tab:home': url };
      this.get('controllers.playModes').forEach(function (mode) {
        url = '<a href="%@/mode/%@" data-mode="%@" class="instruction-link">%@</a>'.fmt(playRoot, mode.get('modeName'), mode.get('modeName'), i18n.t('nav.modes.' + mode.get('modeName')).toString());
        postReplacements['tab:' + mode.get('modeName').replace(/_/g, '')] = url;
        var modeAlias = i18n.t('nav.modes.' + mode.get('modeName')).toString().replace(/\s/g, '').toLowerCase();
        postReplacements['tab:' + modeAlias] = url;
      }, this);
      return postReplacements;
    }).property('step'),

    getPostProcessReplacement: function getPostProcessReplacement(key) {

      var postReplacements = this.get('postProcessReplacements');
      if (key.toLowerCase().substr(0, 8) === 'youtube:') {
        var youtubeId = key.substr(8);
        return '<iframe type="text/html" src="https://www.youtube.com/embed/' + youtubeId + '?modestbranding=1&showinfo=0&rel=0&origin=' + BREADCRUMB_ENV.SITE_DOMAIN + '" frameborder="0"></iframe>';
      }
      var ssl = location.protocol === 'http:' ? '' : 's';
      if (key.toLowerCase().substr(0, 6) === 'video:') {
        var videoId = key.substr(6);
        return '<video controls width=100% preload=auto> <source src="http' + ssl + '://' + BREADCRUMB_ENV.DISPLAY_MEDIA_BUCKET_NAME + '.s3.amazonaws.com/' + videoId + '" type=\'video/mp4\'/> </video>';
      }

      if (key.toLowerCase().substr(0, 6) === 'audio:') {
        var audioId = key.substr(6);
        return '<audio controls preload="auto"> <source src="http' + ssl + '://' + BREADCRUMB_ENV.DISPLAY_MEDIA_BUCKET_NAME + '.s3.amazonaws.com/' + audioId + '" type="audio/mpeg"> </audio>';
      }
      return postReplacements[key.toLowerCase()] || "";
    },

    postprocessInstructionsHtml: function postprocessInstructionsHtml(html) {
      var self = this;
      html = html.replace(/\{\{([\w_\-:\.\/]+)\}\}/gi, function (m, p1) {
        return self.getPostProcessReplacement(p1.replace('UNDERSCORE', '_'));
      });
      return html;
    },

    instructions: (function () {
      var instructions = this.get('step.instructions') || "";
      this._instructions = instructions;
      instructions = this.preprocessInstructions(instructions);
      var html = Breadcrumb.Utils.markdownToHtml(instructions);
      html = this.postprocessInstructionsHtml(html);
      return new _ember['default'].Handlebars.SafeString(html);
    }).property(),

    stepInstructionsDidChange: (function () {
      // only change if instructions have changed;
      var instructions = this.get('step.instructions') || "";
      if (this._instructions === instructions) {
        return;
      }
      delete _ember['default'].meta(this).cache['instructions'];
      this.notifyPropertyChange('instructions');
    }).observes('step.content.instructions'),

    canSkip: (function () {
      return false; // can't skip ever....for now.
    }).property('isTimedout'),

    canAbort: (function () {
      //Only for head to head functionality when timed out
      if (this.get('behavior.name') === "linked_head_to_head") {
        return true;
      }
      return false;
    }).property('behavior.name'),

    hasTimeLimit: (function () {
      if (!this.get('step')) {
        return null;
      }
      return !!this.get('step').getContentValueOrDefault('timelimit');
    }).property('step.content'),

    timeoutAt: (function () {
      if (!this.get('step')) {
        return null;
      }
      var timelimit = this.get('step').getContentValueOrDefault('timelimit');
      if (!timelimit) {
        return null;
      }
      var startedAt = this.get('startedAt') || this.get('waypoint.startedAt');
      if (!startedAt) {
        return null;
      }
      return new Date(startedAt.getTime() + timelimit * 60000);
    }).property('startedAt', 'step.content', 'waypoint'),

    isTimedout: (function () {
      if (!this.get('timeoutAt')) {
        return false;
      }
      return Breadcrumb.now() >= this.get('timeoutAt');
    }).property('timeoutAt', 'Breadcrumb.ticker'),

    showTimeout: (function () {
      return !!this.get('timeoutAt') && !this.get('isTimedout');
    }).property('timeoutAt', 'isTimedout'),

    attemptNum: (function () {
      return (this.get('pendingSubmissions.length') || 0) + 1;
    }).property('pendingSubmissions', 'pendingSubmissions.length'),

    numAttemptsRemaining: (function () {
      if (!this.get('behavior.areAttemptsLimited')) {
        return null;
      }
      if (!this.get('behavior.numAttemptsAllowed')) {
        return null;
      }
      return this.get('behavior.numAttemptsAllowed') - (this.get('attemptNum') - 1);
    }).property('behavior.areAttemptsLimited', 'behavior.numAttemptsAllowed', 'attemptNum'),

    isWaitingForCompletion: (function () {
      if (!this.get('behavior.waitForCompletion')) {
        return false;
      }
      return this.get('hasSubmitted');
    }).property('hasSubmitted', 'behavior.waitForCompletion'),

    isWaiting: _ember['default'].computed.alias('isWaitingForCompletion'),

    didIsWaitingForCompletionChange: (function () {
      var self = this;
      var checkerId = self.get('checkerId');
      if (self.get('isWaitingForCompletion') === true && !checkerId) {
        checkerId = setInterval(function () {
          if (self.get('isWaitingForCompletion')) {
            self.get('waypoint').safeReload().then(function () {
              self.checkAsyncCompletion();
            });
          } else {
            self.clearCheckingForCompletion();
          }
        }, 3000);
        self.set('checkerId', checkerId);
      }
    }).observes('isWaitingForCompletion'),

    clearCheckingForCompletion: function clearCheckingForCompletion() {
      clearInterval(this.get('checkerId'));
      this.set('checkerId', null);
    },

    waitMessage: (function () {
      return this.get('i18n').t(this.get('behavior.waitMessage')).toString();
    }).property('behavior.waitMessage'),

    isValid: _ember['default'].computed.alias('controllers.playSubmission.isValid'),

    hints: _ember['default'].computed.oneWay('step.content.hints'),
    numHints: _ember['default'].computed.oneWay('hints.length'),
    hasHints: _ember['default'].computed.bool('hints.length'),
    hintPenalties: [0, 0.25, 0.5, 0.75, 1],
    hintPenalty: 0.25,

    numHintsRemaining: (function () {
      return (this.get('numHints') || 0) - this.get('numHintsUsed');
    }).property('numHints', 'numHintsUsed'),
    hasHintsRemaining: _ember['default'].computed.bool('numHintsRemaining'),
    hasNoHintsRemaining: _ember['default'].computed.not('hasHintsRemaining'),

    hasCurrentHint: _ember['default'].computed.bool('numHintsUsed'),
    currentHintText: (function () {
      if (!this.get('numHintsUsed') || !this.get('hints')) {
        return '';
      }
      return this.get('hints')[this.get('numHintsUsed') - 1];
    }).property('hints', 'numHintsUsed'),

    hintPointCost: (function () {
      var hintPenalty = this.get('hintPenalty');
      return Math.floor(this.get('pointsPossible') * hintPenalty);
    }).property('pointsPossible'),

    isSubmitDisabled: (function () {
      return this._isSubmitDisabled();
    }).property('isSubmitting', 'isValid'),

    _isSubmitDisabled: function _isSubmitDisabled() {
      if (this.get('isSubmitting')) {
        return true;
      }
      if (!this.get('isValid')) {
        return true;
      }
      return false;
    },

    shouldAutosubmit: function shouldAutosubmit() {
      return !!this.get('behavior.autosubmit');
    },

    checkAutosubmit: function checkAutosubmit() {
      if (!this.shouldAutosubmit()) {
        return;
      }
      if (this.isAutosubmitting) {
        return;
      }
      if (this._isSubmitDisabled()) {
        return;
      }
      _ember['default'].run.next(this, '_checkAutosubmit');
    },

    _checkAutosubmit: function _checkAutosubmit() {
      if (!this.shouldAutosubmit()) {
        return;
      }
      if (this.isAutosubmitting) {
        return;
      }
      if (this._isSubmitDisabled()) {
        return;
      }
      this.autosubmit();
    },

    triggerAction: function triggerAction(actionName, actionParams) {
      actionParams = actionParams || {};
      var lastFix = this.get('controllers.play').getLocationFix();
      actionParams.location_fix = lastFix;
      return this.get('waypoint').triggerAction(actionName, actionParams);
    },

    contentDidChange: (function () {
      this.setProperties({
        hasSubmitted: this.get('content.submissions.length') > 0
      });
      // Run this next to allow progress to update as well.
      _ember['default'].run.next(this, 'checkStarted');
      this.isAutosubmitting = false;
    }).observes('content.id'),

    checkStarted: function checkStarted() {
      if (this._autostart === false) {
        return;
      }
      if (!this.get('content')) {
        return;
      }
      if (this.get('content.startedAt')) {
        return;
      }
      if (this.get('startingWaypoint') === this.get('content')) {
        return;
      }
      var startedAt = Breadcrumb.now(),
          self = this,
          dateTransform = this.container.lookup('transform:date'),
          startedAtString = dateTransform.serialize(startedAt),
          startingWaypoint = this.get('content');
      this.setProperties({
        startingWaypoint: startingWaypoint,
        startedAt: startedAt });
      this.triggerAction('start', { started_at: startedAtString }).then(function () {
        self.set('startingWaypoint', null);
        if (self.get('content') !== startingWaypoint) {
          self.checkStarted();
        }
      }, function (err) {
        // error triggering. Ignore at moment.
        console.error("Error starting waypoint", err);
      });
    },

    mediaKeyForFile: function mediaKeyForFile(file, index) {
      var playthrough = this.get('playthrough');
      var team = this.get('team');
      var teamName = Breadcrumb.Utils.slugify(team.get('name')) || team.id;
      var mission = this.get('mission');
      var missionName = Breadcrumb.Utils.slugify(mission.get('name'));
      var stepNum = this.get('waypoint.stepNum');
      if (stepNum > 1) {
        missionName += '-' + stepNum;
      }
      if (index > 0) {
        missionName += '-' + (index + 1);
      }
      var timestamp = moment(Breadcrumb.nowPacific()).utc().format('YYYY-MM-DD-hh-mm-ss');
      // get extension or substitute
      var mimeType = file.type,
          extension = mimeType.split('/')[1];
      extension = ({ quicktime: 'mov', 'x-flv': 'flv' })[extension] || extension;
      // basic folders
      var startedAt = moment(Breadcrumb.toPacificTime(playthrough.get('startedAt'))).utc();
      var playthroughDate = startedAt.format('YYYY-MM-DD');
      var playthroughName = Breadcrumb.Utils.slugify(playthrough.get('game.name') || playthrough.id);
      var playthroughTime = startedAt.format('hh-mm');
      // put alltogether into full media key
      var mediaKey = '%@/%@-%@/%@/%@-%@.%@'.fmt(playthroughDate, playthroughName, playthroughTime, teamName, missionName, timestamp, extension);
      return mediaKey;
    },

    createMediaForFile: function createMediaForFile(file, index) {
      // Breadcrumb.NativeHooks.logging.log('add media_filename to media metadata'  +
      //   "; " + JSON.stringify(file));
      return {
        media_type: file.type.split('/')[0],
        media_size: file.size,
        media_timestamp: file.timestamp,
        media_filename: file.name,

        upload_mime_type: file.type,
        upload_bucket: BREADCRUMB_ENV.MEDIA_BUCKET_NAME,
        upload_key: this.mediaKeyForFile(file, index)
      };
    },

    autosubmit: function autosubmit() {
      if (this.isAutosubmitting) {
        return;
      }
      this.isAutosubmitting = true;
      var submitPromise = this.submit();
      if (this.get('isSubmitting')) {
        submitPromise.then(B.bind(function () {
          this.isAutosubmitting = false;
        }, this));
      } else {
        this.isAutosubmitting = false;
      }
    },

    pointsPossible: (function () {
      var mission = this.get('waypoint.mission');
      return Math.floor((mission.get('points') || 0) / (mission.get('steps.length') || 1));
    }).property('mission'),

    getImageAndTextForMessageAndKey: function getImageAndTextForMessageAndKey(message, key) {
      var indices = this.get("currentImageIndices");
      var index = indices[key];
      index = typeof index !== 'undefined' ? ++index : 0;
      //if you have reached a null image, restart counter
      if (!_playI18n['default'].tWithSelect(key, message, 'image', index)) {
        index = 0;
      } else if (_playI18n['default'].tWithSelect(key, message, 'text', index) === " ") {
        index = 0;
      }
      indices[key] = index;
      this.set("currentImageIndices", indices);
      message.text = _playI18n['default'].tWithSelect(key, message, 'text', index);
      message.image = _playI18n['default'].tWithSelect(key, message, 'image', index);
      return [message.image, message.text];
    },

    showMessage: function showMessage(waypoint, message, points, pointsForSharing, isSocialEnabled) {
      // don't show messages when on automatic mode.
      if (this.get('controllers.play.isOnAutomatic')) {
        return _ember['default'].RSVP.resolve();
      }

      var blueprint = this.get('playthrough.game.blueprint');
      if (blueprint.get('data.hide_mission_points') === true) {
        points = false;
      }

      var updatedAt = waypoint.get('updatedAt');
      var time = updatedAt ? updatedAt.getTime() : 0;

      var key = message.message;
      var imageAndText = this.getImageAndTextForMessageAndKey(message, key);
      var image = imageAndText[0];
      var text = message.messageText ? message.messageText : imageAndText[1];
      var style = message.style;

      var customTextLookups = {
        'mission.linked.won': 'success_message',
        'mission.linked.lost': 'failure_message',
        'mission.correct': 'success_message',
        'mission.ok': 'success_message',
        'mission.incorrect.final': 'failure_message'
      };

      if (customTextLookups[key]) {
        var customTextLookup = customTextLookups[key];
        var stepContent = waypoint.get('step.content');
        var customText = stepContent && stepContent[customTextLookup];
        if (customText) {
          text = customText;
        }
      }
      return this.get('controllers.playMessage').show({
        style: style,
        text: text,
        image: image,
        confirmType: 'ok',
        pointsAwarded: points,
        pointsPossible: this.get('pointsPossible'),
        pointsForSharing: pointsForSharing,
        isSocialEnabled: isSocialEnabled
      });
    },

    checkAsyncCompletion: function checkAsyncCompletion() {
      var waypoint = this.get('waypoint');
      if (this.get('isWaitingForCompletion') && waypoint.get('completedAt')) {
        this.send('completedAsync');
      }
    },

    pointsForSuccess: function pointsForSuccess(success, numHintsUsed) {
      // if this step is using a relative scoring method, then can't figure
      // out points locally.
      if (this.get('step.scoring') !== Breadcrumb.StepScoringMethods.NORMAL) {
        return null;
      }
      // if success is undefined, unknown points
      if (success === null) {
        return null;
      }
      var missionPoints = this.get('mission.points') || 0,
          numSteps = this.get('mission.steps.length'),
          stepPoints = missionPoints / numSteps,
          hintPenalties = this.get('hintPenalties');
      var finalPoints = stepPoints;
      if (numHintsUsed > 0) {
        finalPoints = stepPoints - this.get('hintPointCost') * numHintsUsed;
        return Math.max(finalPoints, 0);
      } else {
        return Math.floor(success * stepPoints);
      }
    },

    mediaItemsForFiles: function mediaItemsForFiles(files, submission) {
      var mediaItems = files.map(function (file, fileIndex) {
        return this.createMediaForFile(file, fileIndex);
      }, this);
      this.get('behavior').annotateMedia(mediaItems, submission);
      return mediaItems;
    },

    submit: function submit() {
      if (this._isSubmitDisabled()) {
        return _ember['default'].RSVP.reject();
      }
      var deferred = _ember['default'].RSVP.defer();
      this.set('isSubmitting', true);
      // get local info
      var waypoint = this.get('waypoint');
      var behavior = this.get('behavior');
      var attemptNum = this.get('attemptNum');
      var numHintsUsed = this.get('numHintsUsed');
      var startedAt = waypoint.get('startedAt') || this.get('startedAt');

      // get submission data and create uploads for file objects
      var submission = this.get('controllers.playSubmission.submission');
      var files = this.get('controllers.playSubmission.files');
      var mediaItems = this.mediaItemsForFiles(files, submission);

      // get appropriate response.
      var response = behavior.getResponse(submission, attemptNum);
      var points;
      if (behavior.getPoints) {
        points = behavior.getPoints(submission, attemptNum);
      } else {
        var success = behavior.getSuccess(submission, attemptNum);
        points = this.pointsForSuccess(success, numHintsUsed);
      }

      // assemble information for submission
      var lastFix = this.get('controllers.location.lastFix');
      var lastCoords = lastFix ? [lastFix.coords.longitude, lastFix.coords.latitude] : null;
      var pendingSubmissions = _ember['default'].copy(this.get('pendingSubmissions'));
      var submittedAt = new Date(Math.floor(Breadcrumb.now() / 1000) * 1000);
      pendingSubmissions.pushObject({
        time: submittedAt,
        location: lastCoords,
        submission: submission
      });

      var shouldUploadFiles = Breadcrumb.Utils.isSafari() || this.get('playthrough.options.upload_immediately');

      if (response.shouldUpload) {
        var app = this.container.lookup('application:main');
        var maxUploadSize = app.get('maxUploadSize');
        files.forEach(function (file, fileIndex) {
          var mediaItem = mediaItems[fileIndex];
          var upload = _playUpload['default'].create({
            mediaItem: mediaItem,
            file: file,
            waypoint: waypoint
          });
          if (file.dummy) {
            // don't upload dummy files.
          } else if (shouldUploadFiles && file.size < maxUploadSize) {
              // if you're uploading files, add and start right away.
              this.get('controllers.playSyncUploads').addAndStart(upload, waypoint);
            } else if (response.shouldSync) {
              // if we're not uploading files
            } else {
                // if uploads are not starting, then if we're syncing, do nothing,
                // since the files will be included in the sync. But if we are NOT
                // syncing (i.e. for bonus cam), then send the 'pending' status
                // update so that the images can be recovered in wired transfer.
                var uploadController = this.get('controllers.playSyncUploads').createUploadController(upload, waypoint);
                uploadController.updateStatus('pending').then(null, function (err) {});
              }
        }, this);
      }

      // add to sync controller
      if (response.shouldSync) {
        var updates = {
          submissions: pendingSubmissions,
          num_hints_used: numHintsUsed,
          started_at: this.container.lookup('transform:date').serialize(startedAt),
          completed_at: response.isCompleted ? this.container.lookup('transform:date').serialize(submittedAt) : null,
          completed_location: response.isCompleted ? lastCoords : null,
          media: mediaItems
        };
        var syncPromise = this.get('controllers.playSyncWaypoints').addAndSync(waypoint, 'sync', updates, response.isCompleted);
        if (!response.isCompleted) {
          // if we're syncing but not completed, but we should wait for
          // completion, then fire an asyncCompletion once done.
          if (this.get('behavior.waitForCompletion')) {
            syncPromise.then(B.bind(function () {
              this.checkAsyncCompletion();
            }, this));
          }
        }
      }

      var isSocialAvailable = Breadcrumb.NativeHooks.Social.Facebook.isAvailable || Breadcrumb.NativeHooks.Social.Linkedin.isAvailable || Breadcrumb.NativeHooks.Social.Twitter.isAvailable;
      var isSocialEnabled = isSocialAvailable && files.length && this.get('playthrough.game.options.is_social_enabled');
      var pointsForSharing = this.get('controllers.gameOptions.pointsForSharing');

      // After fake submission delay, set submitting to false and show
      // the response message.
      this.set('hasSubmitted', true);
      _ember['default'].run.later(this, function () {
        this.setProperties({ isSubmitting: false });
        var messagePromise = response.message ? this.showMessage(waypoint, response.message, points, pointsForSharing, isSocialEnabled) : _ember['default'].RSVP.resolve();
        messagePromise.then(B.bind(function () {
          if (response.isCompleted) {
            this.get('target').send('completed');
          } else {
            this.set('pendingSubmissions', pendingSubmissions);
            this.get('controllers.playSubmission').clearSubmission();
          }
          deferred.resolve();
        }, this));
      }, this.get('fakeSubmissionDelay'));

      // Reload team while the fake submission and message is in progress.
      this.get('team').safeReload();

      return deferred.promise;
    },

    actions: {
      submit: function submit() {
        this.submit();
      },

      abort: function abort() {
        var teamId = this.get('waypoint.linkedTeams').objectAt(0).get("id"),
            missionId = this.get('content.mission.id'),
            actionName = 'abortHeadToHead',
            actionParams = { team_id: teamId, mission_id: missionId },
            self = this;
        this.get('controllers.playthrough').triggerAction(actionName, actionParams).then(function () {// success
        }, function () {// error
        }).then(function () {// either case
        });

        var waypoint = this.get('waypoint'),
            waypoints = [waypoint];
        var mission = waypoint.get('mission'),
            stepNum = waypoint.get('stepNum');
        // skip all next waypoints too
        waypoints = waypoints.concat(this.get('team.activeWaypoints').filter(function (w) {
          return w.get('mission') === mission && w.get('stepNum') > stepNum;
        }));
        waypoints.forEach(function (w) {
          this.get('controllers.playSyncWaypoints').addAndSync(w, 'skip', {}, true);
        }, this);

        this.get('target').send('completed');
      },

      checkAsyncCompletion: function checkAsyncCompletion() {
        this.get('waypoint').safeReload().then(B.bind(function () {
          this.checkAsyncCompletion();
        }, this));
      },

      completedAsync: function completedAsync() {
        this.clearCheckingForCompletion();
        if (!this.get('isWaitingForCompletion')) {
          return;
        }

        var waypoint = this.get('waypoint');
        var completedMessage = this.get('behavior').getAsyncCompleteMessage(waypoint.get('result') || {});
        this.showMessage(waypoint, completedMessage, waypoint.get('points'), null).then(B.bind(function () {
          this.get('target').send('completed');
        }, this));
      },

      getHint: function getHint() {
        if (this.get('numHintsUsed') < this.get('numHints')) {
          this.incrementProperty('numHintsUsed');
        }
      },

      skip: function skip() {
        // skip this waypoint.
        var waypoint = this.get('waypoint'),
            waypoints = [waypoint];
        var mission = waypoint.get('mission'),
            stepNum = waypoint.get('stepNum');
        // skip all next waypoints too
        waypoints = waypoints.concat(this.get('team.activeWaypoints').filter(function (w) {
          return w.get('mission') === mission && w.get('stepNum') > stepNum;
        }));
        waypoints.forEach(function (w) {
          this.get('controllers.playSyncWaypoints').addAndSync(w, 'skip', {}, true);
        }, this);
        this.get('target').send('completed');
      }
    }
  });

  exports['default'] = PlayWaypointController;
});
/* globals moment */