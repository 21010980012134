define('play/routes/play/sync', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlaySyncRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'admin',
    renderTemplate: function renderTemplate() {
      this.render('play/sync', { into: 'playthrough' });
    }
  });

  exports['default'] = PlaySyncRoute;
});