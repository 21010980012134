define('play/controllers/play-mode-challenge-home', ['exports', 'ember', 'play/views/play-mode-base-translation-property'], function (exports, _ember, _playViewsPlayModeBaseTranslationProperty) {

  var PlayModeChallengeHomeController = _ember['default'].Controller.extend({
    needs: ['playthrough', 'identity', 'playModes', 'playMode', 'playModeChallenge', 'playSyncWaypoints'],
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    modeName: _ember['default'].computed.oneWay('mode.modeName'),
    title: _ember['default'].computed.oneWay('mode.modeTitle'),
    isSending: false,
    pin: '',
    isScanAvailable: false,

    mode: (function () {
      return this.get('controllers.playModes').findBy('modeName', this.get('controllers.playMode.modeName'));
    }).property('controllers.playMode.modeName'),

    translationForKey: function translationForKey(key, context) {
      return this.get('mode').translationForKey(key, context);
    },

    isEnterPinDisabled: (function () {
      var numRemaining = this.get('numRemaining');
      // if numRemaining then we  have no max limit
      if (numRemaining !== null && numRemaining <= 0) {
        return true;
      }
      if (this.get('isSending')) {
        return true;
      }
      return false;
    }).property('isSending', 'numRemaining'),

    isSubmitPinDisabled: (function () {
      var numRemaining = this.get('numRemaining');
      // if numRemaining then we  have no max limit
      if (numRemaining !== null && numRemaining <= 0) {
        return true;
      }
      if (this.get('isSending')) {
        return true;
      }
      if (!this.get('pin')) {
        return true;
      }
      return false;
    }).property('isSending', 'pin', 'numRemaining'),

    teamQRUrl: (function () {
      var pin = this.get('team.pin');
      if (!pin) {
        return '';
      }
      return Breadcrumb.Utils.createQRCode(pin);
    }).property('team.pin'),

    numCompleted: (function () {
      var numCompleted = 0,
          challenges = this.get('team.challenges') || {};
      for (var key in challenges) {
        numCompleted += challenges[key].num_completed || 0;
      }
      return numCompleted;
    }).property('team.challenges'),

    numRemaining: (function () {
      if (!this.get('maximum')) {
        return null;
      }
      return this.get('maximum') - this.get('numCompleted');
    }).property('maximum', 'numCompleted'),

    hasReachedMaximum: (function () {
      if (!this.get('maximum')) {
        return false;
      }
      return this.get('numCompleted') >= this.get('maximum');
    }).property('numCompleted', 'maximum'),

    categoryName: _ember['default'].computed.alias('mode.content.category'),
    maximum: _ember['default'].computed.alias('mode.content.max_total'),

    pinPlaceholder: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.pin_placeholder'),

    pinPrompt: (function () {
      return this.translationForKey('home.pin_prompt', { pin: this.get('team.pin') });
    }).property('modeName', 'team.pin'),

    pinChallengeLabel: (function () {
      if (this.get('maximum')) {
        return this.translationForKey('home.pin_challenge_with_remaining', { numRemaining: this.get('numRemaining') });
      }
      return this.translationForKey('home.pin_challenge');
    }).property('modeName', 'maximum', 'numRemaining'),

    yourCodeLabel: (function () {
      return this.translationForKey('home.your_pin', { pin: this.get('team.pin') });
    }).property('modeName', 'team.pin'),

    activeWaypoint: null,

    teamChallengesDidChange: (function () {
      // because updating inline doesn't catch updates.
      _ember['default'].run.next(this, 'updateActiveWaypoint');
    }).observes('team.challenges'),

    updateActiveWaypoint: function updateActiveWaypoint() {
      this.set('activeWaypoint', this.get('mode.activeWaypoints.firstObject'));
    },

    getFirstTeamIdWithChallengeState: function getFirstTeamIdWithChallengeState(state) {
      var challenges = this.get('team.challenges');
      if (!challenges) {
        return null;
      }
      for (var teamId in challenges) {
        if (challenges[teamId].state === state) {
          return teamId;
        }
      }
      return null;
    },

    sentChallengeToTeamId: (function () {
      return this.getFirstTeamIdWithChallengeState('sent');
    }).property('team.challenges'),

    sentChallengeToTeam: (function () {
      if (!this.get('sentChallengeToTeamId')) {
        return null;
      }
      return this.store.find('team', this.get('sentChallengeToTeamId'));
    }).property('sentChallengeToTeamId'),

    sentChallengeLabel: (function () {
      if (!this.get('hasSentChallenge')) {
        return null;
      }
      var team = this.get('sentChallengeToTeam');
      return this.translationForKey('home.sent', { teamName: team.get('name') });
    }).property('sentChallengeToTeam.name'),

    hasSentChallenge: _ember['default'].computed.bool('sentChallengeToTeamId'),

    receivedChallengeFromTeamId: (function () {
      return this.getFirstTeamIdWithChallengeState('received');
    }).property('team.challenges'),

    receivedChallengeFromTeam: (function () {
      if (!this.get('receivedChallengeFromTeamId')) {
        return null;
      }
      return this.store.find('team', this.get('receivedChallengeFromTeamId'));
    }).property('receivedChallengeFromTeamId'),

    receivedChallengeLabel: (function () {
      if (!this.get('hasReceivedChallenge')) {
        return null;
      }
      var team = this.get('receivedChallengeFromTeam'),
          context = { teamName: team.get('name') };
      return this.translationForKey('home.received', context);
    }).property('receivedChallengeFromTeam.name'),

    hasReceivedChallenge: _ember['default'].computed.bool('receivedChallengeFromTeamId'),

    playLabel: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.play'),
    inProgressLabel: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.inprogress'),
    resendLabel: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.resend'),
    respondLabel: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.respond'),

    canSendChallenges: (function () {
      return !this.get('isSelfUninterruptible');
    }).property('isSelfUninterruptible'),

    cannotSendChallengesLabel: (function () {
      return this.translationForKey('home.uninterruptible');
    }).property('isSelfUninterruptible'),

    isSelfUninterruptible: (function () {
      var completedAndSyncingIds = this.get('controllers.playSyncWaypoints.completedWaypointsSyncedIds');
      var activeStarted = this.get('team.activeWaypoints').filter(function (w) {
        if (!w.get('startedAt')) {
          return false;
        }
        if (w.get('completedAt')) {
          return false;
        }
        if (completedAndSyncingIds.contains(w.get('id'))) {
          return false;
        }
        return true;
      });
      return activeStarted.anyBy('mission.category.uninterruptible');
    }).property('controllers.playSyncWaypoints.completedWaypointsSyncedIds', 'team.activeWaypoints.@each.mission.category', 'team.activeWaypoints.@each.startedAt'),

    actions: {
      enterPin: function enterPin() {
        var pin = this.get('pin');
        if (!pin) {
          return;
        }
        if (pin.toLowerCase) {
          pin = pin.toLowerCase();
        }
        this.send('sendChallenge', { pin: pin });
        this.set('pin', '');
      },
      refresh: function refresh() {
        this.get('team').safeReload();
      },
      sendChallenge: function sendChallenge(toTeam) {
        var self = this;
        this.set('isSending', true);
        this.get('mode').sendChallenge(toTeam).then(function () {
          self.set('isSending', false);
        });
      },
      resendChallenge: function resendChallenge() {
        var resendToTeam = this.get('sentChallengeToTeam');
        if (resendToTeam) {
          this.send('sendChallenge', resendToTeam);
        }
      },
      respondToChallenge: function respondToChallenge() {
        var respondToTeam = this.get('receivedChallengeFromTeam').get('content');
        // Team might not be loaded yet. If so just ignore click.
        if (!respondToTeam) {
          return;
        }
        this.get('mode').showChallengeReceived(respondToTeam);
      },
      homeLoaded: function homeLoaded() {}
    }

  });

  exports['default'] = PlayModeChallengeHomeController;
});