define('play/routes/playthrough-tab', ['exports'], function (exports) {
  var PlaythroughTabRoute = Breadcrumb.Route.extend({
    tabValue: null,
    activate: function activate() {
      this.controllerFor('playthroughHeader').set('selected', this.get('tabValue'));
    },
    deactivate: function deactivate() {
      if (this.controllerFor('playthroughHeader').get('isDestroyed')) {
        return;
      }
      this.controllerFor('playthroughHeader').set('selected', null);
    },
    contextDidChange: function contextDidChange() {
      this._super();
      this.controllerFor('playthroughHeader').set('selected', this.get('tabValue'));
    }
  });

  exports['default'] = PlaythroughTabRoute;
});