define('play/routes/login', ['exports', 'ember'], function (exports, _ember) {

  var LoginRoute = Breadcrumb.Route.extend({
    model: function model() {
      var teamId = Breadcrumb.localStorage.get("breadcrumb-save-team-id");
      if (!_ember['default'].isEmpty(teamId)) {
        return this.store.find('team', teamId)['catch'](function () {
          return null;
        });
      }

      return null;
    },

    setupController: function setupController(controller, model, transition) {
      if (controller.get('canResume')) {
        return controller.resumeAndLogin();
      }
      this._super(controller, model, transition);
      controller.getUpdateStatus();
      controller.loadDeviceId();
    },
    actions: {
      resume: function resume() {
        this.controllerFor('login').getUpdateStatus();
      },
      submitPin: function submitPin() {
        if (!this.controller.get('c')) {
          return;
        }
        this.controller.submitPin(this.controller.get('c'));
      }
    }
  });

  exports['default'] = LoginRoute;
});