define('play/views/submission-photo-widget', ['exports', 'play/views/submission-media-widget', 'ember'], function (exports, _playViewsSubmissionMediaWidget, _ember) {

  var SubmissionPhotoWidget = _playViewsSubmissionMediaWidget['default'].extend({
    accept: 'image/*',
    mediaTypeTitle: 'photo',
    isVideo: false,
    mimeTypes: ['image/jpeg', 'image/png', 'image/gif'],

    getNativeMedia: function getNativeMedia() {
      var options = this.get('nativeOptions');

      $.extend(options, {
        // todo configure photo quality settings from game admin
        useCompression: false,
        quality: 'best',
        waypointID: this.get('controller.waypoint.id'),
        playthroughID: this.get('controller.playthrough.id')
      });

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        Breadcrumb.NativeHooks.getImage(resolve, reject, options);
      });
    }
  });

  exports['default'] = SubmissionPhotoWidget;
});