define('play/controllers/play-submission', ['exports', 'ember'], function (exports, _ember) {

  var PlaySubmissionController = _ember['default'].Controller.extend({
    needs: ['playWaypoint', 'playSubmissionParts'],
    waypoint: _ember['default'].computed.oneWay('controllers.playWaypoint.content'),
    step: _ember['default'].computed.oneWay('waypoint.step'),
    behavior: _ember['default'].computed.oneWay('waypoint.step.behavior'),

    waypointDidChange: (function () {
      this.clearSubmission();
    }).observes('waypoint'),

    clearSubmission: function clearSubmission() {
      this.get('controllers.playSubmissionParts').forEach(function (p) {
        p.propertyWillChange('waypoint');
        p.setProperties({
          // file: null,
          submission: null,
          validSubmission: null,
          isValid: false
        });
        p.propertyDidChange('waypoint');
      });
    },

    parseSubmissionOptions: function parseSubmissionOptions(submissionOptions, options) {
      var stepContent = this.get('step.content');
      for (var optionName in submissionOptions) {
        var optionVal = submissionOptions[optionName];
        var optionType = _ember['default'].typeOf(optionVal);

        // todo this is a bad way to do this since Ember now prefers model.{property} to content.{property}
        if (optionType === 'string' && optionVal.substr(0, 8) === 'content.') {
          // if it's a string that references content, replace the value with
          // a lookup into the step content.
          var contentKey = optionVal.substr(8);
          var contentVal = stepContent[contentKey];
          optionVal = contentVal;
        }
        options[optionName] = optionVal;
      }
      // options['waypoint_id'] = this.get('waypoint.id');
      return options;
    },

    submissionTypes: (function () {
      var types = this.get('behavior.submissionType');
      if (!types) {
        return [];
      }
      if (!_ember['default'].isArray(types)) {
        types = [types];
      }
      var behaviorOptions = this.get('controllers.playWaypoint.behavior.extraOptions');
      return types.map(B.bind(function (type, i) {
        var options = {};
        if (behaviorOptions !== undefined) {
          for (var optionName in behaviorOptions) {
            options[optionName] = behaviorOptions[optionName];
          }
        }
        if (_ember['default'].typeOf(type) === 'object') {
          return { type: type.type, index: i,
            options: this.parseSubmissionOptions(type, options) };
        } else {
          return { type: type, options: options, index: i };
        }
      }, this));
    }).property('waypoint.id'),

    isValid: (function () {
      var parts = this.get('controllers.playSubmissionParts');
      return parts.everyProperty('isValid');
    }).property('controllers.playSubmissionParts.@each.isValid'),

    isValidDidChange: (function () {
      if (this.get('isValid')) {
        this.get('controllers.playWaypoint').checkAutosubmit();
      }
    }).observes('isValid'),

    submission: (function () {
      if (!this.get('isValid')) {
        return null;
      }
      var parts = this.get('controllers.playSubmissionParts');
      return parts.mapProperty('validSubmission');
    }).property('isValid', 'controllers.playSubmissionParts.@each.validSubmission'),

    files: (function () {
      if (!this.get('isValid')) {
        return [];
      }
      var parts = this.get('controllers.playSubmissionParts');
      var files = parts.filterProperty('file').mapProperty('file');
      return files;
    }).property('isValid', 'controllers.playSubmissionParts.@each.file')

  });

  exports['default'] = PlaySubmissionController;
});