define('play/routes/play/play', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlayPlayRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'home',
    setupController: function setupController() {
      var _this = this;

      var modeNames = this.controllerFor('playRole').get('modeNames') || [];
      var modes = modeNames.map(function (modeName) {
        return _this.controllerFor('playModes').findBy('modeName', modeName);
      });
      this.controllerFor('identity').get('team.activeWaypoints').then(function () {
        var firstMode = modes.get('firstObject');
        var firstOfMode = firstMode && firstMode.get('activeWaypoints')[0];
        if (firstOfMode) {
          _this.transitionTo('playMode.waypoint', firstMode.get('modeName'), firstOfMode);
        } else {
          _this.transitionTo('play.home');
        }
      });
    }
  });

  exports['default'] = PlayPlayRoute;
});