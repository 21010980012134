define('play/views/play-sync-upload-x', ['exports', 'ember'], function (exports, _ember) {

  var PlaySyncUpload = _ember['default'].Object.extend({

    hasBeenStarted: false,
    progress: _ember['default'].computed.oneWay('content.progress'),
    isError: _ember['default'].computed.oneWay('uploadRetryable.isError'),
    isRetrying: _ember['default'].computed.oneWay('uploadRetryable.isRetrying'),
    isUploading: _ember['default'].computed.oneWay('uploadRetryable.isActing'),
    isCompleted: _ember['default'].computed.oneWay('uploadRetryable.isCompleted'),
    retryAt: _ember['default'].computed.oneWay('uploadRetryable.retryAt'),
    uploadFailed: _ember['default'].computed.oneWay('uploadRetryable.isFailure'),

    uploadRetryable: null,
    confirmRetryable: null,

    isConfirmed: _ember['default'].computed.oneWay('confirmRetryable.isCompleted'),
    isConfirming: _ember['default'].computed.oneWay('confirmRetryable.isInProgress'),
    confirmError: _ember['default'].computed.oneWay('confirmRetryable.isFailure'),

    canRetry: (function () {
      return this.get('uploadRetryable.isWaitingToRetry') || this.get('uploadRetryable.isFailure');
    }).property('uploadRetryable.isWaitingToRetry', 'uploadRetryable.isFailure'),

    confirmStatus: (function () {
      if (this.get('isUploading')) {
        return '';
      }
      if (this.get('isConfirming')) {
        return 'Confirming...';
      }
      if (this.get('isConfirmed')) {
        return 'Confirmed!';
      }
      if (this.get('confirmError')) {
        return 'Error confirming.';
      }
      if (this.get('isCompleted')) {
        return 'Uploaded.';
      }
      return '?';
    }).property('isConfirming', 'isConfirmed', 'confirmError'),

    start: function start() {
      this.set('hasBeenStarted', true);
      // FIXME
      var app = this.parentController.container.lookup('application:main');
      var retryable = Breadcrumb.retry(this._start, this, app.get('xhrRetryOptions'));
      // console.log('PlaySyncUploadX, start. xhrRetryOptions', app.get('xhrRetryOptions'));
      this.set('uploadRetryable', retryable);
      var self = this;
      return retryable.then(function () {
        self.confirmUploadOnCompletion();
      });
    },

    restart: function restart() {
      if (this.get('isCompleted')) {
        return;
      }
      if (this.get('uploadRetryable.isFailure')) {
        // if failure, start again
        return this.start();
      } else {
        // otherwise, restart
        this.get('content').stopUpload();
        this.get('uploadRetryable').restart();
      }
      return this.get('uploadRetryable');
    },

    _start: function _start() {
      return this.get('content').start();
    },

    confirm: function confirm() {
      // console.log('PlaySyncUploadX, confirm');
      if (!this.get('isCompleted')) {
        return;
      }
      if (this.get('isConfirming') || this.get('isConfirmed')) {
        return;
      }
      // FIXME
      var app = this.parentController.container.lookup('application:main');
      var retryable = Breadcrumb.retry(this._confirm, this, app.get('xhrRetryOptions'));
      this.set('confirmRetryable', retryable);
      // when retryable is done, remove from list.
      return retryable.then(B.bind(this.didConfirm, this));
    },

    didConfirm: function didConfirm() {
      // console.log('PlaySyncUploadX, didConfirm');
      this.get('parentController').didConfirmUpload(this);
      this.destroy();
    },

    _confirm: function _confirm() {
      // console.log('PlaySyncUploadX, _confirm(retryable)');
      return this.updateStatus('uploaded');
    },

    updateStatus: function updateStatus(status) {
      var options = { media_item: this.get('content.mediaItem'), status: status };
      return this.get('waypoint').triggerAction('updateMedia', options);
    },

    confirmUploadOnCompletion: function confirmUploadOnCompletion() {
      // console.log('PlaySyncUploadX, confirmUploadOnCompletion');
      if (this.get('isConfirming') || this.get('isConfirmed')) {
        return this.get('confirmRetryable');
      }
      return this.confirm();
    }
  });

  exports['default'] = PlaySyncUpload;
});