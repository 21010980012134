define('play/controllers/vote/select', ['exports', 'ember'], function (exports, _ember) {

  var VoteSelectController = _ember['default'].ArrayController.extend({

    needs: ['playthrough', 'identity', 'playMessage'],
    itemController: 'voteSelectItem',
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    game: _ember['default'].computed.oneWay('playthrough.game'),

    numToChoose: (function () {
      var numVotables = this.get('content.length');
      return Math.min(numVotables, this.get('game.routing.votables.max'));
    }).property('content.length', 'game.routing.votables.max'),

    requiresChoice: (function () {
      return this.get('numToChoose') !== this.get('numChosen');
    }).property('numToChoose', 'numChosen'),

    isReady: (function () {
      return !this.get('requiresChoice');
    }).property('requiresChoice'),

    numChosen: (function () {
      return this.filterBy('isVotingSelected').length;
    }).property('@each.isVotingSelected'),

    confirmLabel: (function () {
      if (this.get('isReady')) {
        return _ember['default'].I18n.t('voting.select.confirm');
      }
      var context = { numChosen: this.get('numChosen') };
      return _ember['default'].I18n.t('voting.select.notready', context);
    }).property('isReady', 'numChosen'),

    chooseLabel: (function () {
      var numChosen = this.get('numChosen'),
          numToChoose = this.get('numToChoose'),
          messageKey = this.get('isReady') ? 'ok' : 'choose';
      return _ember['default'].I18n.t('voting.select.' + messageKey, {
        numToChoose: numToChoose,
        numChosen: numChosen });
    }).property('isReady', 'numChosen'),

    reload: function reload() {
      var team = this.get('controllers.identity.team');
      var waypointQuery = { team: team.get('id'), is_votable: true };
      return this.load(this.store.find('waypoint', waypointQuery));
    },

    checkReadiness: function checkReadiness() {
      // if nothing to choose, then we're automatically ready.
      if (!this.get('requiresChoice')) {
        return _ember['default'].RSVP.resolve(true);
      }
      var self = this;
      return this.reload().then(function () {
        return self.get('isReady');
      });
    },

    checkReadinessAndNotify: function checkReadinessAndNotify() {
      var redirectRequired = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var self = this;
      return this.checkReadiness().then(function (isReady) {
        if (!isReady) {
          self.notifySelectionRequired();
        } else if (redirectRequired) {
          this.transitionToRoute('vote.select');
        }
      });
    },

    notifySelectionRequired: function notifySelectionRequired() {
      var _this = this;

      this.get('controllers.playMessage').show({
        text: _ember['default'].I18n.t('voting.select.notification'),
        canDismiss: true
      }).then(function () {
        _this.transitionToRoute('vote.select');
      });
    },

    actions: {
      confirm: function confirm() {
        this.transitionToRoute('play.play');
      }
    }
  });

  exports['default'] = VoteSelectController;
});