define('play/controllers/play/info', ['exports', 'ember'], function (exports, _ember) {

  var PlayInfoController = _ember['default'].Controller.extend({
    needs: ['playthrough'],
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    infoMsg: _ember['default'].computed.oneWay('playthrough.game.infoMessage'),

    isUrl: (function () {
      var infoMsg = this.get('infoMsg');
      return infoMsg && infoMsg.trim().substr(0, 4) === 'http';
    }).property('infoMsg')
  });

  exports['default'] = PlayInfoController;
});