define('play/controllers/play-inbox', ['exports', 'ember'], function (exports, _ember) {

  var PlayInboxController = _ember['default'].ArrayController.extend({
    needs: ['identity', 'play', 'playMessage'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    content: _ember['default'].computed.oneWay('team.messages'),
    itemController: 'playInboxItem',

    _readLocalIds: [], // list of read messages identified by serialized sentAt

    showUnread: function showUnread() {
      if (this.get('controllers.playMessage.isVisible')) {
        // a message is already being read
        return;
      }
      // show an unread message if present
      var self = this,
          unread = [];
      var dateTransform = this.container.lookup('transform:date');
      unread = self.filter(function (item) {
        return !item.get('readAt') && !self._readLocalIds.contains(dateTransform.serialize(item.get('sentAt')));
      });
      if (!unread.length) {
        return;
      }

      var item = unread.sortBy('sentAt').get('firstObject');
      this.showItem(item);
    },

    showSendMessage: function showSendMessage() {
      var _this = this;

      // item must be an item controller in the inbox.
      this.get('controllers.playMessage').show({
        text: "Message to the Game Runner",
        style: 'info',
        confirmType: 'sendcancel',
        canDismiss: true,
        verifyText: null,
        isOutgoingMessage: true
      }).then(function (response) {
        var dateTransform = _this.container.lookup('transform:date');
        var sentAt = dateTransform.serialize(new Date());
        _this.get('controllers.play').triggerAction('send_message_to_gr', {
          message: response,
          sent_at: sentAt
        });
        // self.markAsRead(sentAt);
      });
    },

    showItem: function showItem(item) {
      var self = this,
          sentAt = item.get('sentAt');
      // item must be an item controller in the inbox.
      this.get('controllers.playMessage').show({
        text: item.get('messageText'),
        style: 'info',
        confirmType: item.get('verifyText') ? 'verify' : 'ok',
        canDismiss: item.get('verifyText') ? false : true,
        verifyText: item.get('verifyText')
      }).then(function () {
        self.markAsRead(sentAt);
      });
    },

    markAsRead: function markAsRead(sentAt) {
      var dateTransform = this.container.lookup('transform:date');
      sentAt = dateTransform.serialize(sentAt);
      var item = this.find(function (item) {
        return dateTransform.serialize(item.get('sentAt')) === sentAt;
      });
      if (item) {
        this._readLocalIds.push(sentAt);
        var readAt = dateTransform.serialize(new Date());
        this.get('controllers.play').triggerAction('read_message', {
          sent_at: sentAt,
          read_at: readAt
        });
        // this.showUnread();
      } else {
          console.error('message item matching sentAt not found');
        }
    }
  });

  exports['default'] = PlayInboxController;
});