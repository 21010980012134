define('play/controllers/play-mode-base', ['exports', 'ember', 'play/views/play-mode-base-translation-property'], function (exports, _ember, _playViewsPlayModeBaseTranslationProperty) {

  var PlayModeBaseController = _ember['default'].Controller.extend({
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    game: _ember['default'].computed.oneWay('playthrough.game'),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    mode: _ember['default'].computed.oneWay('content'),
    icon: _ember['default'].computed.oneWay('content.icon'),
    modeName: _ember['default'].computed.oneWay('content.name'),
    modeType: _ember['default'].computed.oneWay('content.type'),

    isActive: (function () {
      var activeModes = this.get('controllers.playRole.modeNames');
      return activeModes.indexOf(this.get('modeName')) >= 0;
    }).property('controllers.playRole.modeNames', 'modeName'),

    isVisible: (function () {
      return this.get('isActive') && this.get('model.enabled') === true && this.get('missions.length') > 0;
    }).property('isActive'),

    missions: (function () {
      return this.get('controllers.playthrough.missions').filterBy('modeName', this.get('modeName'));
    }).property('modeName', 'controllers.playthrough.missions.@each'),

    navTitle: (function () {
      return _ember['default'].I18n.t('nav.modes.' + this.get('modeName'));
    }).property('content.name'),

    modeTitle: (0, _playViewsPlayModeBaseTranslationProperty['default'])('title'),
    homeTitle: _ember['default'].computed.alias('modeTitle'),

    translationForKey: function translationForKey(key, context) {
      var fullKey = 'modes.' + this.get('modeName') + '.' + key;
      return _ember['default'].I18n.t(fullKey, context);
    },

    activeWaypointSourcesDidChange: (function () {
      this.notifyPropertyChange('activeWaypoints');
    }).observes('team.activeWaypoints.@each.id', 'team.activeWaypoints.length', 'controllers.playSyncWaypoints.completedWaypointsSyncedIds'),

    activeWaypoints: (function () {
      var completedAndSyncingIds = this.get('controllers.playSyncWaypoints.completedWaypointsSyncedIds');
      var waypoints = this.get('controllers.identity.team.activeWaypoints'),
          modeName = this.get('modeName');
      if (!waypoints) {
        return [];
      }
      return waypoints.filter(function (w) {
        if (w.get('mission.modeName') !== modeName) {
          return false;
        }
        if (w.get('completedAt')) {
          return false;
        }
        if (completedAndSyncingIds.contains(w.id)) {
          return false;
        }
        if (w.get('startedAt')) {
          return true;
        }
        if (w.get('gatedByMission')) {
          return false;
        }
        if (w.get('gatedUntil') && w.get('gatedUntil') > Breadcrumb.now()) {
          return false;
        }
        return !!w.get('ungatedAt');
      });
    }).property('content', 'team.activeWaypoints.@each.id', 'team.activeWaypoints.@each.completedAt', 'team.activeWaypoints.length', 'controllers.playSyncWaypoints.completedWaypointsSyncedIds').volatile()
  });

  exports['default'] = PlayModeBaseController;
});