define('play/views/play-message', ['exports', 'play/views/play-modal-base'], function (exports, _playViewsPlayModalBase) {

  var PlayMessageView = _playViewsPlayModalBase['default'].extend({
    classNames: 'app-play-message',
    controllerName: 'playMessage',
    classNameBindings: ['messageClass', 'controller.hasImage:with-image:no-image', 'controller.pointsAwarded:with-points:no-points'],
    messageClass: (function () {
      var messageStyle = this.get('controller.style');
      var messageClasses = {
        successful: 'message-success',
        unsuccessful: 'message-failure',
        info: 'message-info',
        error: 'message-error' };
      return messageClasses[messageStyle];
    }).property('controller.style')
  });

  exports['default'] = PlayMessageView;
});