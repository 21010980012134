define('play/controllers/play-home-paused', ['exports', 'ember'], function (exports, _ember) {

  var PlayHomePausedController = _ember['default'].Controller.extend({
    needs: ['playthrough'],
    playthrough: _ember['default'].computed.alias('controllers.playthrough.playthrough'),

    pauseMessage: (function () {
      return this.get('playthrough.pauseMessage') || _ember['default'].I18n.t('home.paused.message');
    }).property('playthrough.pauseMessage')
  });

  exports['default'] = PlayHomePausedController;
});