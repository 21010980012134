define('play/i18n', ['exports', 'ember'], function (exports, _ember) {

  var I18n = {
    isTranslationAnArray: function isTranslationAnArray(key) {
      if (_ember['default'].I18n.exists(key)) {
        return false;
      }
      if (_ember['default'].I18n.exists(key + '.text')) {
        return false;
      }
      if (_ember['default'].I18n.exists(key + '.0')) {
        return true;
      }
      if (_ember['default'].I18n.exists(key + '.0.text')) {
        return true;
      }
      return false;
    },

    getTranslationArrayLength: function getTranslationArrayLength(key) {
      var len;
      for (len = 0; len < 20; len++) {
        // If you have a value or a .text value, you're all good.
        if (!_ember['default'].isEmpty(_ember['default'].I18n.t(key + '.' + len))) {
          continue;
        }
        if (!_ember['default'].isEmpty(_ember['default'].I18n.t(key + '.' + len + '.text'))) {
          continue;
        }
        break;
      }
      return len;
    },

    selectKey: function selectKey(key, type, index) {
      // if array, select one from the list
      if (this.isTranslationAnArray(key)) {
        var numItems = this.getTranslationArrayLength(key);
        if (index === undefined) {
          index = Math.floor(Math.random() * numItems);
        }
        index = index % numItems;
        key += '.' + index;
      }
      if (type && (_ember['default'].I18n.exists(key + '.' + type) || type !== 'text')) {
        key += '.' + type;
      }
      return key;
    },

    tWithSelect: function tWithSelect(key, context, type, index) {
      key = this.selectKey(key, type, index);
      if (!key || !_ember['default'].I18n.exists(key)) {
        return null;
      }
      return _ember['default'].I18n.t(key, context);
    }
  };

  exports['default'] = I18n;
});