define('play/controllers/play-social-select', ['exports', 'play/controllers/play-modal-base', 'ember'], function (exports, _playControllersPlayModalBase, _ember) {

  var PlaySocialSelectController = _playControllersPlayModalBase['default'].extend({
    needs: ['identity', 'playMessage', 'play'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    facebookIsAvailable: Breadcrumb.NativeHooks.Social.Facebook.isAvailable,
    linkedinIsAvailable: Breadcrumb.NativeHooks.Social.Linkedin.isAvailable,
    twitterIsAvailable: Breadcrumb.NativeHooks.Social.Twitter.isAvailable,
    waitingForTwitter: false,
    waitingForFacebook: false,
    waitingForLinkedin: false,

    close: function close(err) {
      var playMessageController = this.get('controllers.playMessage');
      if (err) {
        playMessageController.set('error', err);
      } else {
        playMessageController.set('error', "");
      }
      this.set('isSaving', false);
      this.set('waitingForTwitter', false);
      this.set('waitingForFacebook', false);
      this.set('waitingForLinkedin', false);
      this.set('isVisible', false);
      this.set('fbPromise', null);
      this.set('twitterPromise', null);
      playMessageController.set('isVisible', true);
    },

    isSaving: (function () {
      return this.get('controllers.identity.team.isSaving');
    }).property('controllers.identity.team.isSaving'),

    actions: {
      cancel: function cancel() {
        var self = this;
        self.close();
      },

      facebookLogin: function facebookLogin() {
        var self = this,
            team = this.get('controllers.identity.team');

        this.set('waitingForFacebook', true);
        var fbPromise = Breadcrumb.NativeHooks.Social.Facebook.login(team.get('id'));

        fbPromise.then(function () {
          return team.safeReload(true);
        }).then(function () {
          if (team.get('socialInfo.access_token')) {
            self.close();
          } else {
            // console.log("No facebook access token", team.get('socialInfo'));
            self.close("Facebook login failed");
          }
        }, function () {
          self.close("Facebook login failed");
        });
      },

      linkedinLogin: function linkedinLogin() {
        var self = this,
            team = this.get('controllers.identity.team');

        this.set('waitingForLinkedin', true);
        var liPromise = Breadcrumb.NativeHooks.Social.Linkedin.login(team.get('id'));

        liPromise.then(function () {
          return team.safeReload(true);
        }).then(function () {
          if (team.get('socialInfo.access_token')) {
            self.close();
          } else {
            console.log("No linkedin access token", team.get('socialInfo'));
            self.close("LinkedIn login failed");
          }
        }, function () {
          self.close("LinkedIn login failed");
        });
      },

      twitterLogin: function twitterLogin() {
        var self = this,
            team = this.get('controllers.identity.team');

        this.set('waitingForTwitter', true);
        var twitterPromise = Breadcrumb.NativeHooks.Social.Twitter.login(team.get('id'));
        twitterPromise.then(function () {
          return team.safeReload(true);
        }).then(function () {
          if (team.get('socialInfo.access_token_key')) {
            self.close();
          } else {
            // console.log("No twitter access token", team.get('socialInfo'));
            self.close("Twitter login failed");
          }
        }, function () {
          self.close("Twitter login failed");
        });
      }

    }
  });

  exports['default'] = PlaySocialSelectController;
});