define('play/controllers/playthrough-realtime-test', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughRealtimeTestController = _ember['default'].Controller.extend({
    needs: ['playthrough'],

    heartbeatTimeout: 15000,
    heartbeatFailed: false,
    heartbeatInProgress: false,
    heartbeatTime: null,
    numFailures: 0,

    willDestroy: function willDestroy() {
      if (this._timeout) {
        _ember['default'].run.cancel(this._timeout);
      }
    },

    actions: {
      testRealtime: function testRealtime() {
        var t0 = Breadcrumb.now(),
            self = this;
        this.setProperties({
          heartbeatInProgress: true,
          heartbeatFailed: false,
          heartbeatTime: 0
        });
        // send heartbeat and set response
        this.get('controllers.playthrough').sendHeartbeat().then(function () {
          self.setProperties({
            heartbeatFailed: false,
            heartbeatInProgress: false,
            heartbeatTime: (Breadcrumb.now() - t0) / 1000,
            numFailures: 0
          });
          if (self._timeout) {
            _ember['default'].run.cancel(self._timeout);
          }
          self._timeout = null;
        });
        // set timeout
        if (this._timeout) {
          _ember['default'].run.cancel(this._timeout);
        }
        this._timeout = _ember['default'].run.later(this, function () {
          this.setProperties({
            heartbeatInProgress: false,
            heartbeatFailed: true
          });
          this.incrementProperty('numFailures');
          this._timeout = null;
        }, this.get('heartbeatTimeout'));
      }
    }
  });

  exports['default'] = PlaythroughRealtimeTestController;
});