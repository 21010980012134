define('play/views/submission-number-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionNumberWidget = _playViewsSubmissionBaseWidget['default'].extend({
    blankSubmission: { number: '' },
    validate: function validate(submission) {
      if (_ember['default'].typeOf(submission.number) === 'number') {
        return true;
      }
      if (_ember['default'].typeOf(submission.number) !== 'string') {
        return false;
      }
      if (_ember['default'].isNone(submission.number.match(/^\d+$/))) {
        return false;
      }
      return true;
    },
    clean: function clean(submission) {
      return { number: parseInt(_ember['default'].get(submission, 'number'), 10) };
    },
    numberDidChange: (function () {
      this.performValidation();
    }).observes('submission.number'),
    template: _ember['default'].Handlebars.compile('{{view Ember.TextField class="input-block-level" type="number" ' + 'disabledBinding="controller.isUpdating" ' + 'placeholderBinding="view.options.placeholder" ' + 'action="submit" pattern="[0-9]*" ' + 'valueBinding="view.submission.number"}}')
  });

  exports['default'] = SubmissionNumberWidget;
});