define('play/routes/playthrough/signup', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlaythroughLoginRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'signup',
    afterModel: function afterModel(model) {
      var isLoggedIn = this.controllerFor('identity').get('loggedIn');
      if (isLoggedIn) {
        this.controllerFor('identity').logout();
      }
    },
    renderTemplate: function renderTemplate(controller) {
      this.render('playthrough/signup', { into: 'playthrough' });
    },
    setupController: function setupController(controller) {
      // TODO: fix this
      // Breadcrumb.NativeHooks.getDeviceId().then(function(deviceId){
      //   controller.set('deviceId', deviceId);
      // });
    }
  });

  exports['default'] = PlaythroughLoginRoute;
});
/**
 * Created by evanbabb on 7/7/16.
 */