define('play/original-app', ['exports', 'ember'], function (exports, _ember) {

  /*global Play:true*/

  // Ember.LOG_BINDINGS = true;

  var Play = Breadcrumb.Application.create({
    rootElement: '#appContainer',
    adminCodes: ['game', 'g', 'gg'],
    restartCodes: ['restart', 'refresh', 'r', 'rr'],
    defaultFlavorName: 'gogame-en',
    defaultBehaviorName: 'gogame',
    xhrRetryOptions: BREADCRUMB_ENV.ENVIRONMENT_NAME === 'local' ? { initialRetryBackoff: 500, maxAttempts: 6 } : { initialRetryBackoff: 5000, maxAttempts: 12 },

    maxUploadSize: Breadcrumb.Utils.isMobile() || BREADCRUMB_ENV.TESTING ? 500000000 : 10000000000 // 500MB mobile : 1GB desktop
  });
  Breadcrumb.api = Breadcrumb.Api.create({});
  Breadcrumb.localStorage = Breadcrumb.LocalStorage;
  _ember['default'].I18n.translations = {};

  exports['default'] = Play;
});