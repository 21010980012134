define('play/views/login', ['exports', 'ember'], function (exports, _ember) {

  var LoginView = _ember['default'].View.extend({
    classNames: ['app-login'],

    DeviceIdTapTargetView: _ember['default'].View.extend({
      classNames: ['admin-tap-target'],
      lastTouchEnd: null,
      touchEnd: function touchEnd() {
        var t = new Date().getTime(),
            lastT = this.get('lastTouchEnd'),
            timeSinceLast = lastT ? t - lastT : null,
            fastThreshold = 500,
            isFast = timeSinceLast ? timeSinceLast < fastThreshold : false;
        if (isFast) {
          this.set('lastTouchEnd', null);
          _ember['default'].run.next(this, this.doubleClick);
        } else {
          this.set('lastTouchEnd', t);
        }
        return false;
      },
      doubleClick: function doubleClick() {
        this.get('controller').promptDeviceId();
        return false;
      }
    })
  });

  exports['default'] = LoginView;
});