define('play/controllers/leaderboard', ['exports', 'ember'], function (exports, _ember) {

  var NUM_TOP_TEAMS = 10;
  var TEAMS_AROUND_CURRENT = 1;

  var LeaderboardController = _ember['default'].ArrayController.extend({
    needs: ['leaderboard', 'identity', 'playthrough'],
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    itemController: 'leaderboard-team',
    sortProperties: ['pointsTotal'],
    sortAscending: false,

    topTeams: (function () {
      return this.slice(0, NUM_TOP_TEAMS);
    }).property('model'),

    numOmittedTeamsInMiddle: (function () {
      var currentTeamId = this.get('controllers.identity').get('team').get('id');
      var currentTeam = this.findBy("id", currentTeamId);
      var currentTeamIndex = this.indexOf(currentTeam);

      var numTeams = currentTeamIndex - TEAMS_AROUND_CURRENT - NUM_TOP_TEAMS;
      numTeams = Math.max(numTeams, 0);

      return numTeams;
    }).property('model'),

    teamsAroundCurrentTeam: (function () {
      var currentTeamId = this.get('controllers.identity').get('team').get('id');
      var currentTeam = this.findBy("id", currentTeamId);
      var currentTeamIndex = this.indexOf(currentTeam);

      //make sure we arent returning duplicate teams with 'topTeams'
      var lowIndex = currentTeamIndex - TEAMS_AROUND_CURRENT;
      var upperIndex = currentTeamIndex + TEAMS_AROUND_CURRENT + 1;

      lowIndex = Math.max(lowIndex, NUM_TOP_TEAMS);
      upperIndex = Math.max(upperIndex, NUM_TOP_TEAMS);

      return this.slice(lowIndex, upperIndex);
    }).property('model'),

    numOmittedTeamsAtEnd: (function () {
      var currentTeamId = this.get('controllers.identity').get('team').get('id');
      var currentTeam = this.findBy("id", currentTeamId);
      var currentTeamIndex = this.indexOf(currentTeam);

      var leaderboard = this.get('controllers.leaderboard');

      var highestShownTeamPlace = currentTeamIndex + TEAMS_AROUND_CURRENT + 1;
      if (highestShownTeamPlace < NUM_TOP_TEAMS) {
        highestShownTeamPlace = NUM_TOP_TEAMS;
      }

      var numTeams = leaderboard.get('length') - highestShownTeamPlace;
      numTeams = Math.max(numTeams, 0);

      return numTeams;
    }).property('model'),

    notShownMessage: (function () {
      return _ember['default'].I18n.t('leaderboard.not_shown');
    }).property()
  });

  exports['default'] = LeaderboardController;
});