define('play/routes/gallery/team', ['exports', 'play/routes/gallery'], function (exports, _playRoutesGallery) {

  var GalleryTeamRoute = _playRoutesGallery['default'].extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('galleryWaypoints').load(this.store.find('waypoint', { team: model.id, has_media: true, is_archived: false }));
    }
  });

  exports['default'] = GalleryTeamRoute;
});