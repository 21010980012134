define('play/controllers/play-submission-part', ['exports', 'ember'], function (exports, _ember) {

  var PlaySubmissionPartController = _ember['default'].Controller.extend({
    needs: ['playWaypoint', 'playSubmission', 'playWaypointProgress', 'identity', 'playthrough', 'gameOptions'],

    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    admin: _ember['default'].computed.oneWay('controllers.identity.admin'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    waypoint: _ember['default'].computed.oneWay('controllers.playWaypoint.content'),
    mission: _ember['default'].computed.oneWay('waypoint.mission'),
    step: _ember['default'].computed.oneWay('waypoint.step'),

    storedData: (function () {
      var index = this.get('index');
      return this.get('controllers.playWaypointProgress').getStoredPart(this.get('waypoint.id'), index);
    }).property('controllers.playWaypointProgress.parts'),

    isRevealingAnswers: _ember['default'].computed.oneWay('controllers.playWaypoint.isRevealingAnswers'),
    isAutofillingMedia: _ember['default'].computed.oneWay('controllers.playWaypoint.isAutofillingMedia'),

    isUpdating: _ember['default'].computed.oneWay('controllers.playWaypoint.isUpdating'),

    submissionType: _ember['default'].computed.oneWay('content.type'),
    submissionOptions: _ember['default'].computed.oneWay('content.options'),
    index: _ember['default'].computed.oneWay('content.index'),

    correctSubmission: (function () {
      var correct = this.get('controllers.playWaypoint.correctSubmission');
      return correct && correct[this.get('index')] || {};
    }).property('controllers.playWaypoint.correctSubmission', 'index'),

    submission: {},
    upload: null,
    media: null,
    validSubmission: {},
    isValid: false
  });

  exports['default'] = PlaySubmissionPartController;
});