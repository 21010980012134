define('play/views/playthrough-header', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughHeaderView = _ember['default'].View.extend({
    selected: _ember['default'].computed.oneWay('controller.selected'),
    classNameBindings: [':app-playthrough-header', ':navbar', 'controller.shouldShowMoreTab:show-more-tab', 'controller.hasTwoRows:two-rows'],

    didInsertElement: function didInsertElement() {
      this.resizeHandler = B.bind(this.resize, this);
      this.scrollHandler = B.bind(this.scroll, this);
      $(window).on('resize', this.resizeHandler);
      $(window).on('scroll', this.scrollHandler);
      var self = this;
      $(".custom-header-image img").load(function () {
        _ember['default'].run.next(self, 'resize');
      });
      this.resize();
    },

    willClearRender: function willClearRender() {
      $(window).off('resize', this.resizeHandler);
      $(window).off('scroll', this.scrollHandler);
    },

    playSyncStatusDidChange: (function () {
      _ember['default'].run.next(this, 'resize');
    }).observes('controller.controllers.playSyncStatus.hasUploads', 'controller.controllers.playSyncStatus.hasFailure'),

    shouldShowAllTabsDidChange: (function () {
      _ember['default'].run.next(this, 'resize');
    }).observes('controller.shouldShowAllTabs'),

    resize: function resize() {
      if (BREADCRUMB_ENV.TESTING) {
        return;
      }
      if (!this.$('.nav li')) {
        return;
      }
      // maxTabs
      var pointsWidth = this.get('controller.pointsWidth');
      var navTabWidth = this.$('.nav li').outerWidth();
      var headerWidth = this.$().outerWidth();
      this.set('controller.maxTabs', parseInt((headerWidth - pointsWidth) / navTabWidth, 10));

      var imgNaturalHeight = 0,
          imgNaturalWidth = 0;
      var imgHeight = 0;
      var imgEl = this.$(".custom-header-image img").get(0);
      if (!!this.get('controller.headerImage') && imgEl && imgEl.naturalHeight) {
        imgNaturalHeight = imgEl.naturalHeight;
        imgNaturalWidth = imgEl.naturalWidth;
      }
      var isPhone = headerWidth < 768;
      var maxHeaderHeight = isPhone ? this.get('controller.maxHeaderHeightPhone') : this.get('controller.maxHeaderHeightDesktop');

      var imgAspect = imgNaturalWidth / imgNaturalHeight;
      var headerAspect = headerWidth / maxHeaderHeight;

      if (!imgNaturalWidth || !imgNaturalHeight) {
        // do nothing
      } else if (imgAspect > headerAspect) {
          // img is wider and shorter than container
          // proportionally stretch img to full width
          imgHeight = imgNaturalHeight / imgNaturalWidth * headerWidth;
        } else {
          // img is narrower and taller then container
          // proportionally stretch img to max height
          imgHeight = Math.min(imgNaturalHeight, maxHeaderHeight);
        }

      var navTabHeight = this.$('.nav li').outerHeight();
      var navbarHeight = navTabHeight * (this.get('controller.hasTwoRows') ? 2 : 1);
      var progressBarHeight = this.get('controller.controllers.playSyncStatus.hasUploads') ? this.get('controller.progressBarDefaultHeight') : 0;
      var headerHeight = imgHeight + navbarHeight + progressBarHeight;

      this.$(".custom-header-image").css("height", imgHeight + "px");
      this.$(".custom-header-image img").css("height", imgHeight + "px");
      _ember['default'].$(".app-body-container").css("top", headerHeight + "px");
      _ember['default'].$(".app-body-container").css("height", "calc(100vh - " + headerHeight + "px)");
    },

    scroll: function scroll() {
      this.set('controller.shouldShowAllTabs', false);
    },

    HeaderItemView: _ember['default'].View.extend({
      tagName: 'li',
      classNameBindings: 'isActive:active isDisabled:disabled'.w(),
      isActive: (function () {
        var item = this.get('item'),
            items = this.get('controller.tabs').mapProperty('item'),
            selected = this.get('parentView.selected'),
            maxTabs = this.get('controller.maxTabs');
        return item === selected || item === 'more' && items.indexOf(selected) >= maxTabs - 1;
      }).property('item', 'parentView.selected'),
      click: function click() {
        if (this.get('item') !== 'more') {
          this.set('controller.shouldShowAllTabs', false);
        }
      }
    }),

    AdminTapTargetView: _ember['default'].View.extend({
      classNames: ['admin-tap-target'],
      lastTouchEnd: null,
      touchEnd: function touchEnd() {
        var t = new Date().getTime(),
            lastT = this.get('lastTouchEnd'),
            timeSinceLast = lastT ? t - lastT : null,
            fastThreshold = 500,
            isFast = timeSinceLast ? timeSinceLast < fastThreshold : false;
        if (isFast) {
          this.set('lastTouchEnd', null);
          _ember['default'].run.next(this, this.doubleClick);
        } else {
          this.set('lastTouchEnd', t);
        }
        return false;
      },
      doubleClick: function doubleClick() {
        this.get('controller').promptAdmin();
        return false;
      }
    })

  });

  exports['default'] = PlaythroughHeaderView;
});