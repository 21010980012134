define('play/add-to-flavor', ['exports', 'ember'], function (exports, _ember) {

  var addToFlavor = function addToFlavor(container, key, text) {
    var i18nService = container.lookup('service:i18n');
    var obj = {};
    obj[key] = text;
    i18nService.addTranslations('en', obj);
  };

  exports['default'] = addToFlavor;
});