define('play/controllers/play-mode-persistent-home', ['exports', 'ember'], function (exports, _ember) {

  var PlayModePersistentHomeController = _ember['default'].Controller.extend({
    needs: ['identity', 'play', 'playModes', 'playMode', 'playMessage'],

    availableMissions: null,

    init: function init() {
      this._super();
      this._missionsCache = {};
    },

    mode: (function () {
      return this.get('controllers.playModes').findBy('modeName', this.get('controllers.playMode.modeName'));
    }).property('controllers.playMode.modeName'),

    waypoints: (function () {
      var firstStepNums = {},
          waypoints = this.get('mode.activeWaypoints');

      // filter out waypoints whose missions were disabled or deleted
      waypoints = waypoints.filter(function (w) {
        return w.get('mission.isArchived') !== true && w.get('mission.isEnabled') !== false;
      });

      // gather first step number for each mission
      waypoints.forEach(function (w) {
        firstStepNums[w.get('mission.id')] = Math.min(firstStepNums[w.get('mission.id')] || 999, w.get('stepNum'));
      });
      // and only return the first step for each mission.
      return waypoints.sortBy('mission.name').filter(function (w) {
        return firstStepNums[w.get('mission.id')] === w.get('stepNum');
      });
    }).property('mode.activeWaypoints'),

    title: _ember['default'].computed.oneWay('mode.modeTitle'),

    loadAvailableMissions: function loadAvailableMissions() {
      var _this = this;

      var mode = this.get('mode.content'),
          source = mode.source || 'queued';
      if (source === 'queued') {
        this.set('availableMissions', []);return;
      }
      this.set('isLoading', true);
      return this.fetchAvailableMissions().then(function (missions) {
        _this.set('availableMissions', missions);
        _this.set('isLoading', false);
        if (missions.get('length') === 1) {
          _this._startMission(missions.get('firstObject'));
        }
      }, function (err) {
        // do nothing
        Breadcrumb.Logging.error(err);
      });
    },

    isEmpty: (function () {
      return !this.get('waypoints.length') && !this.get('availableMissions.length');
    }).property('waypoints.length', 'availableMissions.length'),

    modeWillChange: (function () {
      if (!this.get('mode.modeName')) {
        return;
      }
      this._missionsCache[this.get('mode.modeName')] = this.get('availableMissions');
    }).observesBefore('mode'),

    modeDidChange: (function () {
      var cachedMissions = this._missionsCache[this.get('mode.modeName')];
      this.set('availableMissions', cachedMissions || []);
    }).observes('mode'),

    fetchAvailableMissions: function fetchAvailableMissions() {
      var mode = this.get('mode.content'),
          source = mode.source || 'queued';
      // if queued, just return missions that have already been queued.
      if (source === 'queued') {
        return _ember['default'].RSVP.resolve(this.get('waypoints').mapBy('mission'));
      }
      var team = this.get('controllers.identity.team'),
          store = this.get('store');
      return team.getDynamicProperty('availableMissions', { mode_name: mode.name }).then(function (data) {
        if (_ember['default'].typeOf(data) === 'array' && !data.length) {
          return [];
        }
        if (!data.missions || !data.missions.length) {
          return [];
        }
        var array = store.recordArrayManager.createAdapterPopulatedRecordArray('mission', {} /* query */);
        var serializer = store.serializerFor('mission');
        var payload = serializer.extractArray(store, Breadcrumb.Mission, data);
        array.load(payload);
        return array;
      });
    },

    _startMission: function _startMission(mission) {
      var _this2 = this;

      var mode = this.get('mode.content');
      var waypoint = this.get('mode.activeWaypoints').findBy('mission', mission);

      if (waypoint) {
        this.transitionToRoute('playMode.waypoint', mode.name, waypoint.id);
        return;
      }

      this.get('controllers.playMessage').show({
        text: _ember['default'].I18n.t('global.loading'),
        confirmType: 'none',
        canDismiss: false
      });

      this.get('controllers.play').triggerAction('queue', { mission_id: mission.id }).then(function () {
        _this2.get('controllers.playMessage').send('dismiss');
        var self = _this2;
        var team = _this2.get('controllers.identity.team');
        team.get('activeWaypoints').then(function (activeWaypoints) {
          var waypoint = activeWaypoints.filter(function (w) {
            return w.get('mission.id') === mission.id;
          })[0];
          if (waypoint) {
            if (self.get('availableMissions').removeRecord) {
              self.get('availableMissions').removeRecord(mission);
            }
            self.transitionToRoute('playMode.waypoint', mode.name, waypoint.id);
          } else {
            console.error('no waypoint found after queuing mission ' + mission.get('name'));
          }
        });
      }, function (err) {
        _this2.get('controllers.playMessage').show({
          text: _ember['default'].I18n.t('persistent.queue_error'),
          style: 'error'
        });
      });
    },

    actions: {
      homeLoaded: function homeLoaded() {
        this.loadAvailableMissions();
      },
      startMission: function startMission(mission) {
        return this._startMission(mission);
      }
    }
  });

  exports['default'] = PlayModePersistentHomeController;
});