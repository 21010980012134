define('play/views/submission-yesno-widget', ['exports', 'play/views/submission-buttons-widget', 'ember'], function (exports, _playViewsSubmissionButtonsWidget, _ember) {

  var SubmissionYesnoWidget = _playViewsSubmissionButtonsWidget['default'].extend({
    validate: function validate(submission) {
      var val = submission.choice;
      return val === true || val === false;
    },
    clean: function clean(submission) {
      return { choice: !!submission.choice };
    },
    choicesWithValue: (function () {
      if (!_ember['default'].isArray(this.get('options.choices'))) {
        return [];
      }
      return this.get('options.choices').map(function (choice, idx) {
        var label = _ember['default'].typeOf(choice) === 'string' ? choice : choice.text;
        return { label: label, value: idx === 0 ? true : false };
      });
    }).property('options.choices', 'controller.waypoint')
  });

  exports['default'] = SubmissionYesnoWidget;
});