define('play/controllers/vote-scores-team', ['exports', 'ember'], function (exports, _ember) {

  var VoteScoresTeamController = _ember['default'].ObjectController.extend({
    needs: ['vote/scores'],
    scorePosition: (function () {
      return this.get('controllers.vote/scores').indexOf(this) + 1;
    }).property()
  });

  exports['default'] = VoteScoresTeamController;
});