define('play/controllers/play-mode-persistent-waypoint', ['exports', 'ember'], function (exports, _ember) {

  var PlayModePersistentWaypointController = _ember['default'].Controller.extend({
    needs: ['playWaypoint', 'playthrough', 'identity', 'playSyncWaypoints', 'playWaypointProgress', 'playModePersistentHome'],

    waypoint: _ember['default'].computed.oneWay('controllers.playWaypoint.waypoint'),

    isEndlessCam: (function () {
      var behaviorName = this.get('waypoint.step.behaviorName');
      return behaviorName && behaviorName.indexOf('endless') === 0;
    }).property('waypoint.step.behaviorName'),

    isOnlyMissionInMode: (function () {
      var mode_missions = this.get('controllers.playthrough.missions.content').filterBy('modeName', this.get('waypoint.modeName'));
      return mode_missions.length <= 1;
    }).property('controllers.playthrough.missions.content', 'waypoint.modeName'),

    startedMediaWaypoint: (function () {
      var completedAndSyncingIds = this.get('controllers.playSyncWaypoints.completedWaypointsSyncedIds');
      var active = this.get('controllers.identity.team.activeWaypoints');
      var progress = this.get('controllers.playWaypointProgress');
      var started = active.filter(function (w) {
        // If it's syncing, then we're done, so it's all good.
        if (completedAndSyncingIds.contains(w.id)) {
          return false;
        }
        // persistent media waypoints don't show a warning.
        if (w.get('isPersistent')) {
          return false;
        }
        // If the data says its started, then we haven't finished, so show
        // the warning.
        if (w.get('startedAt') && !w.get('completedAt')) {
          return true;
        }
        // If the progress cache says it's started, then we've started it
        // just now but a new update hasn't come through so it hasn't registered
        // in the model yet. But we know to show the warning anyway.
        var waypointProgress = progress.progressFor(w.id);
        if (waypointProgress && waypointProgress.startedAt) {
          return true;
        }
        // Otherwise this waypoint hasn't been started yet, so don't need to
        // show a warning.
        return false;
      });

      var startedMedia = started.filter(function (w) {
        var behaviorName = w.get('step.behaviorName') || "";
        if (behaviorName.indexOf('endless') > -1) {
          return false;
        }
        if (behaviorName.indexOf('photo') > -1) {
          return true;
        }
        if (behaviorName.indexOf('movie') > -1) {
          return true;
        }
        if (behaviorName.indexOf('camera') > -1) {
          return true;
        }
        return false;
      });
      return startedMedia.get('firstObject');
    }).property('controllers.identity.team.activeWaypoints.@each.startedAt', 'controllers.playWaypointProgress.startedAt'),

    mediaMissionWarning: (function () {
      return this.get('isEndlessCam') && !!this.get('startedMediaWaypoint');
    }).property('startedMediaWaypoint', 'isEndlessCam')
  });

  exports['default'] = PlayModePersistentWaypointController;
});