define('play/controllers/play', ['exports', 'ember', 'breadcrumb/mixins/realtime'], function (exports, _ember, _breadcrumbMixinsRealtime) {

  var ONE_MINUTE = 60000;
  var THIRTY_THREE_SEC = 33000;
  var PlayController = _ember['default'].Controller.extend(_breadcrumbMixinsRealtime['default'], {
    needs: ['identity', 'location', 'playInbox', 'playModes', 'playthrough', 'playWaypoint', 'playMessage', 'playHomeInProgress'],
    channelFormat: 'breadcrumb-@env-team-@id',
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    content: _ember['default'].computed.oneWay('controllers.identity.team'),
    model: (function () {}).property(),
    isUpdating: false,
    isFakingLocation: false,
    lastCheckInbox: null,
    lastLocationUpdate: null,
    isOnAutomatic: false,

    automaticInterval: 2000,

    availableStartTracks: (function () {
      var start_tracks = this.get('controllers.playthrough.startTrackMissions').getEach('id');
      var active_waypoints = this.get('team.activeWaypoints');
      return active_waypoints.filter(function (n) {
        return start_tracks.indexOf(n.get('mission.id')) !== -1;
      });
    }).property('playthrough.startTrackMissions', 'team.activeWaypoints'),

    isOnAutomaticDidChange: (function () {
      if (this.get('isOnAutomatic')) {
        this._setAutomaticTimer();
      } else {
        this._clearAutomaticTimer();
      }
    }).observes('isOnAutomatic'),

    _setAutomaticTimer: function _setAutomaticTimer() {
      if (this._automaticTimer) {
        return;
      }
      this._automaticTimer = setInterval(this.onAutomaticTick.bind(this), this.automaticInterval);
    },

    _clearAutomaticTimer: function _clearAutomaticTimer() {
      if (!this._automaticTimer) {
        return;
      }
      clearInterval(this._automaticTimer);
      this._automaticTimer = null;
    },

    onAutomaticTick: function onAutomaticTick() {
      if (this.get('isOnAutomatic')) {
        this.autoContinue();
      }
    },

    autoContinue: function autoContinue() {
      var waypointController = this.get('controllers.playWaypoint');
      // check for active waypoint that is visible
      if ($(".app-play-waypoint").length > 0 && !waypointController.get('isSubmitDisabled')) {
        waypointController.send('submit');
        return;
      }
      // check for home that is visible.
      if ($(".app-play-home").length > 0 && $(".home-option-main").is(':visible') && !$(".home-option-main").is(':disabled')) {
        $(".home-option-main").click();
        return;
      }
      // Check for routing error button
      if ($(".app-play-mode-routed-home").is(":visible") && $(".btn-routing-retry").is(':visible')) {
        $(".btn-routing-retry").click();
        return;
      }
      // Check for intro page
      if ($(".app-playthrough-intro").is(':visible') && $(".btn-play").is(':visible') && !$(".btn-play").is(':disabled')) {
        $(".btn-play").click();
      }
    },

    teamDidChange: (function () {
      this.set('isFakingLocation', !Breadcrumb.Utils.isMobile());
    }).observes('controllers.identity.team.id'),

    teamActiveWaypointsDidUpdate: (function () {
      this.get('controllers.playModes').invoke('notifyPropertyChange', 'activeWaypoints');
    }).observes('controllers.identity.team.data'),

    init: function init() {
      this._super();
      Breadcrumb.Logs.store = this.store;
    },

    willDestroy: function willDestroy() {
      this._clearAutomaticTimer();
      this._super();
    },

    realtimeEvents: {
      startedGame: function startedGame() {
        this.get('team').safeReload();
      },
      messageReceived: function messageReceived(params) {
        // console.log('messageReceived', params);
        var inbox = this.get('controllers.playInbox');
        this.get('team').reload().then(function () {
          inbox.showUnread();
        });
      },
      aborted: function aborted(params) {
        var _this = this;

        // skip if currently on it.
        var waypointId = params.waypoint_id;
        this.get('team').safeReload();
        if (waypointId === this.get('controllers.playWaypoint.content.id')) {
          this.get('controllers.playMessage').show({
            text: "Head to Head cancelled - onwards!"
            // canDismiss: false
          }).then(function () {
            _this.transitionToRoute('play.play');
          });
        }
      },
      skipped: function skipped(params) {
        var _this2 = this;

        // skip if currently on it.
        var waypointId = params.waypoint_id;
        this.get('team').safeReload();
        if (waypointId === this.get('controllers.playWaypoint.content.id')) {
          this.get('controllers.playMessage').show({
            text: _ember['default'].I18n.t('mission.skipped')
            // canDismiss: false
          }).then(function () {
            _this2.transitionToRoute('play.play');
          });
        }
      },
      completed: function completed(params) {
        // asynchronous completion.
        var waypointId = params.waypoint_id,
            self = this;
        var activeWaypoint = this.get('controllers.playWaypoint.content');
        if (!activeWaypoint) {
          return;
        }
        if (waypointId !== activeWaypoint.get('id')) {
          return;
        }
        // phone
        _ember['default'].RSVP.all([this.get('team').safeReload(), activeWaypoint.safeReload()]).then(function () {
          // don't send if we've already moved on after reloading everything.
          if (self.get('controllers.playWaypoint.content.id') === waypointId) {
            self.get('controllers.playWaypoint').send('completedAsync');
          }
        });
      },
      wildcard: function wildcard(events) {
        events.forEach(function (evt) {
          switch (evt.event) {
            case 'reenter':
              var playHomeInProgress = this.get('controllers.playHomeInProgress');
              this.get('team').reload().then(function () {
                // console.log('team safeReload callback, args:', arguments, 'this:', this);
                playHomeInProgress.set('isReentered', true);
              });
              break;
            case 'routed':
            case 'queued':
            case 'unqueued':
              this.get('team').safeReload();
              break;
            case 'challenge-received':
            case 'challenge-accepted':
            case 'challenge-declined':
            case 'challenge-failed':
              var teamId = evt.params.team_id,
                  modeName = evt.params.mode_name,
                  modes = this.get('controllers.playModes'),
                  mode = modes.findBy('modeName', modeName);
              if (mode.get('isActive')) {
                this.store.find('team', teamId).then(function (team) {
                  mode.didReceiveChallengeMessage(evt.event, team, evt.params);
                });
              }
              break;
          }
        }, this);
      }
    },

    tickerDidUpdate: (function () {
      // don't phone home while testing.
      if (BREADCRUMB_ENV.TESTING) {
        return;
      }
      if (!this.get('team')) {
        return;
      }

      // location updates
      var blueprintName = this.get('controllers.playthrough.playthrough.game.blueprintName');
      var now = Breadcrumb.now();
      if (blueprintName !== 'gogameshow') {
        var lastLocationUpdate = this.get('lastLocationUpdate');
        var locationIntervalAgo = new Date(Breadcrumb.now().getTime() - ONE_MINUTE);
        if (!lastLocationUpdate || lastLocationUpdate < locationIntervalAgo) {
          this.set('lastLocationUpdate', now);
          this.updateLocation();
        }
      }

      // inbox checks
      var lastCheckInbox = this.get('lastCheckInbox');
      var inboxIntervalAgo = new Date(Breadcrumb.now().getTime() - THIRTY_THREE_SEC);
      if (!lastCheckInbox || lastCheckInbox < inboxIntervalAgo) {
        this.set('lastCheckInbox', now);
        this.checkInbox();
      }
    }).observes('Breadcrumb.ticker'),

    checkInbox: function checkInbox() {
      this.get('controllers.playInbox').showUnread();
    },

    updateLocation: function updateLocation() {
      // Update current location unless game has entered voting stage.
      var playthrough = this.get('controllers.playthrough.playthrough');
      if (playthrough.get('status') === B.PlaythroughStatuses.CLOSED) {
        return;
      }
      if (playthrough.get('status') >= B.PlaythroughStatuses.VOTING) {
        return;
      }
      return this.triggerAction('updateLocation').then(null, function (err) {
        // absorb errors.
        console.error("Error updating location: %@".fmt(err));
      });
    },

    getFakeLocationFix: function getFakeLocationFix() {
      var team = this.get('controllers.identity.team');
      if (!team) {
        return null;
      }
      var activeWaypoints = team.get('activeWaypoints');
      var waypointsWithLocation = activeWaypoints.filterBy('mission.location');
      if (!waypointsWithLocation.length) {
        return null;
      }
      var location = waypointsWithLocation.get('lastObject.mission.location');
      if (!location) {
        return null;
      }
      return {
        time: this.container.lookup('transform:date').serialize(new Date()),
        coords: location,
        accuracy: 30
      };
    },

    getLocationFix: function getLocationFix() {
      if (this.get('isFakingLocation')) {
        return this.getFakeLocationFix();
      }
      var lastFix = this.get('controllers.location.lastFix');
      if (!lastFix) {
        return null;
      }
      var timeString = lastFix.timestamp ? this.container.lookup('transform:date').serialize(new Date(lastFix.timestamp)) : null;
      return {
        time: timeString,
        coords: [lastFix.coords.longitude, lastFix.coords.latitude],
        accuracy: lastFix.coords.accuracy
      };
    },

    triggerAction: function triggerAction(actionName, actionParams) {
      this.set('isUpdating', true);
      var locationFix = this.getLocationFix(),
          self = this;
      actionParams = actionParams || {};
      if (locationFix) {
        actionParams.location_fix = locationFix;
      }
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        self.get('team').triggerAction(actionName, actionParams).then(function (data) {
          if (data && data.team) {
            var teamData = data.team.team ? data.team : data;
            var serializer = self.store.serializerFor('team');
            var payload = serializer.extract(self.store, self.store.modelFor('team'), teamData, teamData.team.id, 'find');
            self.store.push('team', payload);
          }
          self.set('isUpdating', false);
          resolve(data);
        }, function (err) {
          self.set('isUpdating', false);
          reject(err);
        });
      });
    }
  });

  exports['default'] = PlayController;
});