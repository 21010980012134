define('play/preload-flavor-images', ['exports'], function (exports) {
  var preloadFlavorImages = function preloadFlavorImages(data) {
    function endsWith(str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }
    for (var key in data) {
      if (endsWith(key, '.image')) {
        var newImage = new Image();
        newImage.src = data[key];
      }
    }
  };

  exports['default'] = preloadFlavorImages;
});