define('play/controllers/playthrough/intro', ['exports', 'ember'], function (exports, _ember) {

    var PlaythroughIntroController = _ember['default'].Controller.extend({
        needs: ['playthrough', 'identity', 'playLoginRoles', 'playthroughRealtimeTest', 'play', 'playWaypoint'],
        team: _ember['default'].computed.oneWay('controllers.identity.team'),
        playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
        game: _ember['default'].computed.oneWay('playthrough.game'),
        isLoadingPlay: false,
        isRefreshing: false,

        heartbeatFailed: _ember['default'].computed.oneWay('controllers.playthroughRealtimeTest.heartbeatFailed'),
        heartbeatInProgress: _ember['default'].computed.oneWay('controllers.playthroughRealtimeTest.heartbeatInProgress'),
        heartbeatTime: _ember['default'].computed.oneWay('controllers.playthroughRealtimeTest.heartbeatTime'),

        playthroughDidChange: (function () {
            this.set('isLoadingPlay', false);
        }).observes('playthrough'),

        canStart: (function () {
            if (!this.get('playthrough.hasStarted')) {
                return false;
            }
            if (!this.get('team.hasStartedGame')) {
                return false;
            }
            return true;
        }).property('playthrough.hasStarted', 'team.hasStartedGame'),

        gameTitle: (function () {
            return this.get('game.title') || _ember['default'].I18n.t('intro.title');
        }).property('game.title'),

        welcomeMessage: (function () {
            var welcomeMessage = this.get('game.welcomeMessage') || _ember['default'].I18n.t('intro.welcome');
            welcomeMessage = this.get('controllers.playWaypoint').preprocessInstructions(welcomeMessage);
            var html = Breadcrumb.Utils.markdownToHtml(welcomeMessage);
            html = this.get('controllers.playWaypoint').postprocessInstructionsHtml(html);
            return new _ember['default'].Handlebars.SafeString(html);
        }).property('game.welcomeMessage'),

        refresh: function refresh() {
            var _this = this;

            var playthrough = this.get('playthrough'),
                team = this.get('team');
            this.set('isRefreshing', true);
            _ember['default'].RSVP.all([team.safeReload(), this.store.findWithSideload('playthrough', playthrough.id, 'game.flavors')]).then(function () {
                _this.set('isRefreshing', false);
            });
        },

        actions: {
            play: function play() {
                var _this2 = this;

                this.set('isLoadingPlay', true);
                this.get('team').safeReload().then(function () {
                    return _this2.transitionToRoute('play.play');
                }).then(null, function (err) {
                    // error routing -- or redirected
                }).then(function () {
                    _this2.set('isLoadingPlay', false);
                });
            },
            refresh: function refresh() {
                this.refresh();
            },
            testRealtime: function testRealtime() {
                this.get('controllers.playthroughRealtimeTest').send('testRealtime');
            },
            hardRestart: function hardRestart() {
                window.location = window.location.pathname; // reset href
            }
        }
    });

    exports['default'] = PlaythroughIntroController;
});