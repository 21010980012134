define('play/routes/application', ['exports', 'play/load-flavor', 'ember', 'play/config/environment'], function (exports, _playLoadFlavor, _ember, _playConfigEnvironment) {

  var ApplicationRoute = Breadcrumb.Route.extend({
    afterModel: function afterModel() {
      var app = this.container.lookup('application:main');
      var flavor = (0, _playLoadFlavor['default'])(app.get('defaultFlavorName'), this.container);
      var blueprint = Breadcrumb.Blueprint.load(app.get('defaultBehaviorName'));
      var behaviors = Breadcrumb.Behavior.load();
      return _ember['default'].RSVP.all([flavor, blueprint, behaviors]).then(null, function (err) {
        //console.log('error loading static: ', err);
        throw new Error("Error loading static: ", err);
      });
    },
    setupController: function setupController() {
      // start watching location early so that permission notification
      // happens right away.
      this.controllerFor('location').startWatching();
      Breadcrumb.NativeHooks.init();
    },
    model: function model() {
      var _this = this;

      $.extend(true, BREADCRUMB_ENV, {
        DISPLAY_VERSION: _playConfigEnvironment['default'].displayVersion,
        FACEBOOK_APP_ID: _playConfigEnvironment['default'].facebookAppId,
        ENVIRONMENT_NAME: _playConfigEnvironment['default'].environment,
        SITE_DOMAIN: _playConfigEnvironment['default'].siteDomain,
        AWS_REGION: _playConfigEnvironment['default'].AWSRegion,
        MEDIA_BUCKET_NAME: _playConfigEnvironment['default'].mediaBucketName,
        DISPLAY_MEDIA_BUCKET_NAME: _playConfigEnvironment['default'].displayMediaBucketName
      });
      // init firebase
      var firebaseApp = undefined;
      try {
        firebaseApp = firebase.initializeApp(_playConfigEnvironment['default'].firebaseConfig);
      } catch (e) {
        console.error('Firebase initialization', e.message);
      }

      Breadcrumb.api = Breadcrumb.Api.create({});
      Breadcrumb.firebaseApp = firebaseApp;
      Breadcrumb.localStorage = Breadcrumb.LocalStorage;
      var app = this.container.lookup('application:main');
      // set max upload size to 500MB for mobile : 1GB desktop
      var maxUploadSize = Breadcrumb.Utils.isMobile() || BREADCRUMB_ENV.TESTING ? 500000000 : 10000000000;
      app.set('maxUploadSize', maxUploadSize);
      $('body').tooltip({ selector: '[rel=tooltip]' });
      var ssl = location.protocol === 'http:' ? '' : 's';
      return $.getJSON('http' + ssl + '://' + _playConfigEnvironment['default'].siteDomain + '/api/application_context').then(function (result) {
        _ember['default'].run(function () {
          $.extend(true, BREADCRUMB_ENV, result);
          var userId = BREADCRUMB_ENV.CURRENT_USER_ID;
          if (userId) {
            _this.store.find('creator', userId).then(function (creator) {
              app.set('loggedInCreator', creator);
            });
          }
        });
      }, function (err) {
        Breadcrumb.Logging.warn("Couldn't fetch application context from game server." + err);
      });
    },
    actions: {
      error: function error(reason, transition) {
        console.error(reason);
        if (reason.stack) {
          console.error(reason.stack);
        }
        if (reason && reason.status === 404) {
          return this.transitionTo('login');
        }
        Breadcrumb.Alert.error("error: " + reason);
      },
      resume: function resume() {},
      // these are here in case an action unexpectedly bubbles up.
      playthroughStatusDidChange: function playthroughStatusDidChange() {},
      completed: function completed() {}
    }
  });

  exports['default'] = ApplicationRoute;
});