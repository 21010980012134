define('play/controllers/play-home-ended', ['exports', 'ember'], function (exports, _ember) {

    var PlayHomeEndedController = _ember['default'].Controller.extend({
        needs: ['playthrough', 'playWaypoint'],
        playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
        game: _ember['default'].computed.oneWay('controllers.playthrough.content.playthrough.game'),

        isEndedEarly: (function () {
            var playthrough = this.get('playthrough');
            if (playthrough.get('isEndingOrEnded')) {
                return false;
            }
            return true;
        }).property('playthrough.status'),

        endedMessage: (function () {
            var message,
                playthrough = this.get('playthrough');
            if (this.get('isEndedEarly')) {
                message = this.get('game.endedEarlyMessage');
            }
            if (!message) {
                message = this.get('game.endedMessage');
            }
            if (!message) {
                message = _ember['default'].I18n.t('home.ended.defaultmessage');
            }
            message = this.get('controllers.playWaypoint').preprocessInstructions(message);
            var html = Breadcrumb.Utils.markdownToHtml(message);
            html = this.get('controllers.playWaypoint').postprocessInstructionsHtml(html);
            return new _ember['default'].Handlebars.SafeString(html);
        }).property('playthrough.status', 'game.endedEarlyMessage', 'game.endedMessage')
    });

    exports['default'] = PlayHomeEndedController;
});