define('play/controllers/play-sync-status', ['exports', 'ember', 'play/upload'], function (exports, _ember, _playUpload) {

  var PlaySyncStatusController = _ember['default'].Controller.extend({
    needs: ['playSyncUploads', 'playSyncWaypoints', 'playthroughHeader'],
    waypoints: _ember['default'].computed.alias('controllers.playSyncWaypoints'),
    uploads: _ember['default'].computed.alias('controllers.playSyncUploads'),

    shouldShowAllTabs: _ember['default'].computed.oneWay('controllers.playthroughHeader.shouldShowAllTabs'),

    hasFailure: (function () {
      return this.get('waypoints').anyBy('syncFailed') || this.get('uploads').anyBy('uploadFailed');
    }).property('controllers.playSyncWaypoints.@each.syncFailed', 'controllers.playSyncUploads.@each.uploadFailed'),

    numUploads: _ember['default'].computed.alias('controllers.playSyncUploads.currentQueueLength'),
    completedInCurrentQueue: _ember['default'].computed.alias('controllers.playSyncUploads.completedInCurrentQueue'),
    hasUploads: _ember['default'].computed.bool('controllers.playSyncUploads.length'),
    currentNum: (function () {
      return this.get('controllers.playSyncUploads.completedInCurrentQueue') + 1;
    }).property('controllers.playSyncUploads.completedInCurrentQueue'),

    init: function init() {
      // console.log('PlaySyncStatusController init');
      var self = this;
      if (Breadcrumb.NativeHooks.clearAppDataDir) {
        Breadcrumb.NativeHooks.clearAppDataDir().then(function () {
          //get uploading queue from storage
          var q = Breadcrumb.NativeHooks.getUploadingQueue();
          if (q && Object.keys(q).length) {
            console.log('PlaySyncStatusController init, restarting uploading queue, files in the q:', Object.keys(q).length);
            Object.keys(q).forEach(function (i) {
              var item = q[i];
              console.log('restarting', i, item);
              var mediaItem = item['mediaItem'];
              var file = item['file'];
              var waypointId = item['waypointId'];
              self.store.find('waypoint', waypointId).then(function (waypoint) {
                var upload = _playUpload['default'].create({
                  mediaItem: mediaItem,
                  file: file,
                  waypoint: waypoint
                });
                self.get('controllers.playSyncUploads').addAndStart(upload, waypoint);
                var queue = Breadcrumb.NativeHooks.getUploadingQueue();
                if (/^u\_\d+$/.test(queue[i]["uploadId"])) {
                  delete queue[i];
                  Breadcrumb.NativeHooks.setUploadingQueue(queue);
                }
                //var options = {media_item: {test: 'test'}, status: 'uploaded'};
                //wp.triggerAction('updateMedia', options);
              });
            });
          }
        });
      }
    },

    uploadProgress: (function () {
      if (!this.get('hasUploads')) {
        return 100;
      }
      var uploads = this.get('uploads');
      var progress = 0,
          numUploads = 0;
      uploads.forEach(function (upload) {
        progress += upload.get('progress');
        numUploads += upload.get('progress') > 0 ? 1 : 0;
      });
      return progress / numUploads;
    }).property('controllers.playSyncUploads.@each.progress')

  });

  exports['default'] = PlaySyncStatusController;
});