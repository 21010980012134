define('play/controllers/application', ['exports', 'ember', 'play/config/environment'], function (exports, _ember, _playConfigEnvironment) {

  var ApplicationController = Breadcrumb.ApplicationController.extend({
    hasEnvironmentLabel: (function () {
      return _playConfigEnvironment['default'].environment.indexOf('production') === -1;
    }).property(),

    environmentLabel: (function () {
      return _ember['default'].String.capitalize(_playConfigEnvironment['default'].environment);
    }).property()
  });

  exports['default'] = ApplicationController;
});