define('play/router', ['exports', 'ember', 'play/config/environment'], function (exports, _ember, _playConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _playConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login', { path: '/' });

    this.resource('playthrough', { path: '/p/:playthrough_id' }, function () {
      this.route('intro', { path: '/' });
      this.route('login', { path: '/login' });
      this.route('signup', { path: '/signup' });
      this.route('admin', { path: '/admin' });
      this.route('teamchat', { path: '/teamchat' });

      this.resource('play', { path: '/play' }, function () {
        this.route('home', { path: '/' });
        this.route('info', { path: '/info' });
        this.route('play', { path: '/play' });
        this.route('sync', { path: '/sync' });

        this.resource('playRetry', { path: '/retry' }, function () {
          this.route('list', { path: '/' });
          this.route('waypoint', { path: '/:waypoint_id' });
        });

        this.resource('playMode', { path: '/mode/:mode_name' }, function () {
          this.route('home', { path: '/' });
          this.route('waypoint', { path: '/w/:waypoint_id' });
        });
      });

      this.resource('leaderboard', { path: '/leaderboard' }, function () {
        this.route('leaderboard', { path: '/' });
      });

      this.resource('gallery', { path: '/gallery' }, function () {
        this.route('team', { path: '/team/:team_id' });
        this.route('teams', { path: '/teams' });
      });

      this.resource('vote', { path: '/vote' }, function () {
        this.route('vote', { path: '/' });
        this.route('select', { path: '/select' });
        this.route('admin', { path: '/admin' });
        this.route('scores', { path: '/scores' });
      });
    });
  });

  exports['default'] = Router;
});