define('play/controllers/play-login-role', ['exports', 'ember'], function (exports, _ember) {

  var PlayLoginRoleController = _ember['default'].Controller.extend({
    needs: ['playRole', 'identity', 'playthrough'],
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    roleName: _ember['default'].computed.oneWay('content'),

    roleIndex: (function () {
      return this.get('controllers.playRole.roles').mapBy('name').indexOf(this.get('roleName'));
    }).property(),

    loginCode: (function () {
      return B.Pin.getTeamLogin(this.get('playthrough.pin'), this.get('team.pin'), this.get('roleIndex'));
    }).property('playthrough.pin', 'team.pin', 'roleIndex'),

    loginLabel: (function () {
      var context = {
        url: BREADCRUMB_ENV.SITE_DOMAIN,
        code: this.get('loginCode') };
      var key = 'home.login.' + this.get('roleName') + '.message';
      return _ember['default'].I18n.t(key, context);
    }).property('loginCode', 'roleName')
  });

  exports['default'] = PlayLoginRoleController;
});