define('play/views/vote/select', ['exports', 'ember'], function (exports, _ember) {

    var VoteSelectView = _ember['default'].View.extend(Breadcrumb.Views.AdaptsToWindowHeight, {
        classNames: ['app-play-vote-select'],
        padding: 30,
        ignoreAtWidthsLessThan: 0,
        resizeSelectors: ['.vote-select-content'],
        resizeSelf: false
    });

    exports['default'] = VoteSelectView;
});