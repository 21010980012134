define('play/controllers/play/home', ['exports', 'ember'], function (exports, _ember) {

  var PlayHomeController = _ember['default'].Controller.extend({
    needs: ['identity', 'login', 'play', 'playHomeModes', 'playthrough', 'playModes', 'playRole', 'playLoginRoles', 'playSyncWaypoints', 'vote/select'],
    team: _ember['default'].computed.alias('controllers.identity.team'),
    playthrough: _ember['default'].computed.alias('controllers.playthrough.playthrough'),
    isGamePaused: _ember['default'].computed.alias('playthrough.isPaused'),

    isExitButtonEnabled: (function () {
      return this.get('playthrough.options.exit_button_enabled');
    }).property('playthrough.options'),

    isStartTracksAvailable: (function () {
      var start_tracks = this.get('controllers.playthrough.startTrackMissions').getEach('id');
      var active_waypoints = this.get('team.activeWaypoints');
      return active_waypoints.filter(function (n) {
        return start_tracks.indexOf(n.get('mission.id')) !== -1;
      }).length > 0;
    }).property('playthrough.startTrackMissions', 'team.activeWaypoints'),

    doesVotingRequireSelection: (function () {
      // if we haven't ended the game yet, don't allow selection
      if (!this.get('playthrough.isEndingOrEnded')) {
        return false;
      }
      // don't require selection if we're ready.
      if (this.get('controllers.vote/select.isReady')) {
        return false;
      }
      return true;
    }).property('playthrough.isEndingOrEnded', 'controllers.vote/select.isReady'),

    allowVoting: (function () {
      if (!this.get('playthrough.isVoting')) {
        return false;
      }
      if (!this.get('controllers.playRole.role.voting')) {
        return false;
      }
      return true;
    }).property('playthrough.isVoting', 'controllers.playRole.role.voting'),

    areAnyRoutedMissionsActive: (function () {
      var hasAnyRoutedMissions = false;
      var completedAndSyncingIds = this.get('controllers.playSyncWaypoints.completedWaypointsSyncedIds');
      this.get('controllers.playModes').forEach(function (mode) {
        if (mode.get('modeType') !== 'routed') {
          return;
        }
        mode.get('activeWaypoints').forEach(function (waypoint) {
          if (completedAndSyncingIds.contains(waypoint.id)) {
            return;
          }
          hasAnyRoutedMissions = true;
        });
      });
      return hasAnyRoutedMissions;
    }).property('controllers.playSyncWaypoints.completedWaypointsSyncedIds', 'controllers.playModes.@each.activeWaypoints.length'),

    isGameEndedForTeam: (function () {
      var isEnded = this.get('playthrough.isEnded');
      var hasAnyRoutedMissions = this.get('areAnyRoutedMissionsActive');
      var hasTeamCompleted = this.get('team.hasCompletedGame');
      return isEnded || hasTeamCompleted && !hasAnyRoutedMissions;
    }).property('areAnyRoutedMissionsActive', 'playthrough.status', 'team.hasCompletedGame'),

    isGameInProgress: (function () {
      return !this.get('isGameEndedForTeam') && !this.get('isGamePaused');
    }).property('isGamePaused', 'isGameEndedForTeam'),

    footerText: (function () {
      var fText = _ember['default'].I18n.t('footerText');
      //console.log("fText: " + fText);
      if (fText.indexOf("translation") > 0) {
        return "";
      } else {
        return fText;
      }
    }).property('playthrough.status'),

    logout: function logout() {
      this.get('controllers.login').clearResumePoint();
      this.get('controllers.identity').logout(this.get('playthrough.id'));
      this.transitionToRoute("login");
    },

    actions: {
      logout: function logout() {
        var self = this;
        var msg = _ember['default'].I18n.t('home.logout.confirm_message');
        var title = _ember['default'].I18n.t('home.logout.confirm_title');
        Breadcrumb.NativeHooks.confirm(msg, title, function yesCb() {
          self.logout();
        }, function noCb() {/*do nothing*/
        });
      }
    },

    numMissionsCompleted: _ember['default'].computed.alias('team.history.length'),
    score: _ember['default'].computed.alias('team.pointsTotal')
  });

  exports['default'] = PlayHomeController;
});