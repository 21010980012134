define('play/controllers/vote-select-item', ['exports', 'ember'], function (exports, _ember) {

  var VoteSelectItemController = _ember['default'].Controller.extend({
    step: _ember['default'].computed.alias('content.step'),
    isChanging: false,

    thumbnailUrl: (function () {
      var mediaItem = this.get('content.media')[0];
      if (!mediaItem) {
        return null;
      }
      return Breadcrumb.Media.getThumbnailUrl(mediaItem, 'tiny');
    }).property('content.media'),

    hasThumbnail: _ember['default'].computed.bool('thumbnailUrl'),

    isVotingSelected: _ember['default'].computed('content.isVotingSelected', {
      get: function get(key) {
        return this.get('content.isVotingSelected');
      },

      set: function set(key, value) {
        var content = this.get('content'),
            self = this;
        this.set('isChanging', true);
        content.triggerAction('selectForVoting', { is_selected: value }).then(null, function (err) {}).then(function () {
          if (!self.isDestroyed) {
            self.set('isChanging', false);
          }
        });
        return value;
      }
    }),

    instructions: (function () {
      return this.get("content.instructions");
    }).property('content.instructions'),

    actions: {
      toggle: function toggle() {
        if (this.get('isChanging')) {
          return;
        }
        this.set('isVotingSelected', !this.get('isVotingSelected'));
      }
    }
  });

  exports['default'] = VoteSelectItemController;
});