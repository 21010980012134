define('play/views/submission-base-widget', ['exports', 'ember'], function (exports, _ember) {

  var SubmissionBaseWidget = _ember['default'].View.extend({
    classNames: ['submission-widget'],
    options: _ember['default'].computed.alias('controller.submissionOptions'),
    templateName: 'submission-base',

    didInsertElement: function didInsertElement() {
      this.resetEntry();
    },

    resetEntry: function resetEntry() {
      var submission;
      if (this.get('controller.isRevealingAnswers')) {
        submission = _ember['default'].copy(this.get('controller.correctSubmission'));
      } else {
        submission = _ember['default'].copy(this.get('blankSubmission'));
      }
      this.set('submission', submission);
      this.performValidation();
    },

    isRevealingAnswersDidChange: (function () {
      this.resetEntry();
    }).observes('controller.isRevealingAnswers'),

    controllerSubmissionDidChange: (function () {
      this.set('submission', this.get('controller.submission'));
    }).observes('controller.submission'),

    waypointDidChange: (function () {
      if (this.state === 'inDOM') {
        this.rerender();
      }
    }).observes('controller.waypoint'),

    performValidation: function performValidation() {
      var isValid = this.validate(this.get('submission') || {});
      var cleaned = isValid ? this.clean(this.get('submission')) : null;
      if (!this.get('controller')) {
        return;
      }
      this.get('controller').setProperties({
        isValid: isValid,
        validSubmission: isValid ? cleaned : null
      });
    },
    blankSubmission: {},
    validate: function validate(submission) {
      return true;
    },
    clean: function clean(submission) {
      return submission;
    }
  });

  exports['default'] = SubmissionBaseWidget;
});