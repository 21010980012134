define('play/controllers/play-role', ['exports', 'ember'], function (exports, _ember) {

  var PlayRoleController = _ember['default'].Controller.extend({
    needs: ['identity', 'playthrough'],
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),

    roles: _ember['default'].computed.oneWay('playthrough.game.blueprint.roles'),

    roleNames: (function () {
      if (!this.get('roles')) {
        return [];
      }
      return this.get('roles').mapBy('name');
    }).property('roles'),

    roleName: (function (key, value) {
      var roleIndex;
      if (arguments.length === 1) {
        // get
        roleIndex = this.get('controllers.identity.roleIndex') || 0;
        return this.get('roleNames')[roleIndex];
      } else {
        // set
        roleIndex = this.get('roleNames').indexOf(value);
        if (roleIndex === -1) {
          roleIndex = 0;
        }
        this.set('controllers.identity.roleIndex', roleIndex);
        return value;
      }
    }).property('roles', 'controllers.identity.roleIndex'),

    role: (function () {
      var roleIndex = this.get('controllers.identity.roleIndex') || 0;
      if (!this.get('roles')) {
        return null;
      }
      return this.get('roles')[roleIndex];
    }).property('roles', 'controllers.identity.roleIndex'),

    modeNames: _ember['default'].computed.oneWay('role.modes'),

    loginRoleNames: (function () {
      if (!this.get('controllers.identity.team')) {
        return [];
      }
      return this.get('role.login_roles');
    }).property('controllers.identity.team', 'role')
  });

  exports['default'] = PlayRoleController;
});