define('play/routes/vote/select', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var VoteSelectRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'vote',

    model: function model() {
      var team = this.controllerFor('identity').get('team');
      return this.store.find('waypoint', { team: team.id, is_votable: true });
    },
    // setupController: function(controller, model) {
    //
    // },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('vote/select', { into: 'playthrough' });
    }
  });

  exports['default'] = VoteSelectRoute;
});