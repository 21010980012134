define('play/views/submission-select-team-linked-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionSelectTeamLinkedWidget = _playViewsSubmissionBaseWidget['default'].extend({
    blankSubmission: { text: '' },
    validate: function validate(submission) {
      return !_ember['default'].isEmpty(_ember['default'].get(submission, 'text')) && !_ember['default'].isEmpty(_ember['default'].get(submission, 'text').trim());
    },
    textDidChange: (function () {
      this.performValidation();
    }).observes('submission.text'),
    template: _ember['default'].Handlebars.compile('{{view Ember.TextField class="input-block-level" ' + 'disabledBinding="controller.isUpdating" ' + 'placeholderBinding="view.options.placeholder" ' + 'action="submit"' + 'valueBinding="view.submission.text"}}')
  });

  exports['default'] = SubmissionSelectTeamLinkedWidget;
});