define('play/views/submission-checkboxes-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionCheckboxesWidget = _playViewsSubmissionBaseWidget['default'].extend({
    blankSubmission: { checkboxes: null },
    validate: function validate(submission) {
      return Array.isArray(submission.checkboxes) && submission.checkboxes.length > 0;
    },
    clean: function clean(submission) {
      return { checkboxes: submission.checkboxes };
    },
    choicesWithValue: (function () {
      if (!_ember['default'].isArray(this.get('options.choices'))) {
        return [];
      }
      var self = this;
      return this.get('options.choices').map(function (choice, idx) {
        var label = _ember['default'].typeOf(choice) === 'string' ? choice : choice.text;
        var check = false;
        var checkboxes = self.get('submission');
        return _ember['default'].Object.create({ label: label, value: idx, check: check });
      });
    }).property('options.choices', 'controller.waypoint'),

    checkboxesDidChange: (function () {
      var checked = this.get('choicesWithValue').filterBy('check').mapBy('value');
      this.set('submission', { checkboxes: checked });
      this.performValidation();
    }).observes('choicesWithValue.@each.check'),

    template: _ember['default'].Handlebars.compile('<p>' + '  {{#each choice in view.choicesWithValue}}' + '    <label class="radio-label">' + '      {{input type="checkbox" name="submission_checkboxes" disabledBinding="controller.isUpdating"' + '        valueBinding="choice.id"' + '        checkedBinding="choice.check"}}' + '      <div>{{choice.label}}</div>' + '    </label>' + '  {{/each}}' + '</p>')
  });

  exports['default'] = SubmissionCheckboxesWidget;
});