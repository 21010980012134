define('play/load-flavor', ['exports', 'play/preload-flavor-images'], function (exports, _playPreloadFlavorImages) {
  exports['default'] = loadFlavor;

  function loadFlavor(flavorName, container) {
    var flavorUrl = 'flavors/%@.json'.fmt(flavorName);
    return Breadcrumb.Assets.loadAsset(flavorUrl).then(function (data) {
      var i18nService = container.lookup('service:i18n');
      i18nService.addTranslations('en', data);
      (0, _playPreloadFlavorImages['default'])(data);
    }, function () {
      console.error('failed to load asset', flavorUrl);
    });
  }
});