define('play/controllers/vote/vote', ['exports', 'ember'], function (exports, _ember) {

  var VoteVoteController = _ember['default'].Controller.extend({
    needs: ['playthrough', 'identity', 'playMessage'],
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),

    voteOptions: (function () {
      return [{ value: 6, title: _ember['default'].I18n.t("voting.score6") }, { value: 5, title: _ember['default'].I18n.t("voting.score5") }, { value: 4, title: _ember['default'].I18n.t("voting.score4") }, { value: 3, title: _ember['default'].I18n.t("voting.score3") }, { value: 2, title: _ember['default'].I18n.t("voting.score2") }, { value: 1, title: _ember['default'].I18n.t("voting.score1") }];
    }).property(),

    actions: {
      vote: function vote(value) {
        this.get('controllers.playMessage').show({
          text: _ember['default'].I18n.t("voting.inprogress"),
          confirmType: 'none'
        });
        var responseShown = false,
            self = this;
        function showResult(ok, message) {
          if (responseShown) {
            return;
          }
          responseShown = true;
          self.get('controllers.playMessage').show({
            text: _ember['default'].I18n.t("voting." + message),
            style: ok ? 'successful' : 'unsuccessful'
          });
        }
        _ember['default'].run.later(this, function () {
          showResult(true, 'received');
        }, Math.random() * 1500 + 500);
        this.get('team').triggerAction('vote', { value: value }).then(function (response) {
          // success -- update ok / success values for
          showResult(response.ok, response.message);
        }, function (err) {
          // ignore errors.
        });
      }
    }
  });

  exports['default'] = VoteVoteController;
});