define('play/controllers/play-sync-uploads', ['exports', 'ember', 'play/views/play-sync-upload-x'], function (exports, _ember, _playViewsPlaySyncUploadX) {

  var PlaySyncUploadsController = _ember['default'].ArrayController.extend({
    numCompleted: 0,
    completedInCurrentQueue: 0,
    maxSimultaneousUploads: 2000,

    createUploadController: function createUploadController(upload, waypoint) {
      return _playViewsPlaySyncUploadX['default'].create({
        content: upload,
        waypoint: waypoint,
        parentController: this
      });
    },

    add: function add(upload, waypoint) {
      var uploadController = this.createUploadController(upload, waypoint);
      this.pushObject(uploadController);
      return uploadController;
    },

    addAndStart: function addAndStart(upload, waypoint) {
      var uploadController = this.add(upload, waypoint);
      if (this.get('allowNewUploadStart')) {
        this.start(uploadController);
      } else {
        // send update that the media item is pending, just so the system
        // knows that it's ready. otherwise it won't get a notification until
        // it's started.
        uploadController.updateStatus('pending');
      }
      return uploadController;
    },

    start: function start(uploadController) {
      uploadController.updateStatus('started');
      uploadController.start().then(null, function () {
        // ignore failure.
        Breadcrumb.Logs.error('PlaySyncUploadController FAILURE');
      });
    },

    didConfirmUpload: function didConfirmUpload(uploadController) {
      this.removeObject(uploadController);
      this.incrementProperty('numCompleted');
      if (this.get('length') == 0) {
        this.set('completedInCurrentQueue', 0);
      } else {
        this.incrementProperty('completedInCurrentQueue');
      }
      // console.log('didconfirmupload', this.get('length'), this.get('completedInCurrentQueue'));
    },

    allowNewUploadStartDidChange: (function () {
      if (this.get('numWaiting') && this.get('allowNewUploadStart')) {
        var waiting = this.filter(function (u) {
          return !u.get('hasBeenStarted');
        });
        if (waiting[0]) {
          this.start(waiting[0]);
        }
      }
    }).observes('allowNewUploadStart'),

    allowNewUploadStart: (function () {
      return this.get('numUploading') < this.maxSimultaneousUploads;
    }).property('numUploading'),

    numWaiting: (function () {
      return this.filterBy('hasBeenStarted', false).length;
    }).property('@each.hasBeenStarted', 'length'),

    numUploading: (function () {
      return this.filter(function (u) {
        if (u.get('uploadFailed')) {
          return false;
        }
        if (u.get('hasBeenStarted')) {
          return true;
        }
        return false;
      }).length;
    }).property('@each.hasBeenStarted', '@each.uploadFailed', 'length'),

    currentQueueLength: (function () {
      return this.get('completedInCurrentQueue') + this.get('numWaiting') + this.get('numUploading');
    }).property('numUploading', 'numWaiting', 'completedInCurrentQueue')

  });

  exports['default'] = PlaySyncUploadsController;
});