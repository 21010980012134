define('play/routes/playthrough', ['exports', 'ember', 'play/load-flavor', 'play/add-to-flavor'], function (exports, _ember, _playLoadFlavor, _playAddToFlavor) {

  var PlaythroughRoute = Breadcrumb.Route.extend({
    activate: function activate() {
      var game = this.modelFor('playthrough').playthrough.get('game');
      if (!game) {
        return;
      }
      var envTitles = { local: 'Local: ', staging: 'Staging: ' },
          envTitle = envTitles[BREADCRUMB_ENV.ENVIRONMENT_NAME] || "",
          gameTitle = game.get('title') || game.get('name') || "The Go Game";
      document.title = envTitle + gameTitle;
    },
    deactivate: function deactivate() {
      document.title = DEFAULT_TITLE;
    },
    model: function model(params) {
      var _this = this;

      _ember['default'].assert("Must have a playthrough ID", !!params.playthrough_id);
      return this.get('store').findWithSideload('playthrough', params.playthrough_id, 'game.flavors').then(function (playthrough) {
        var game_id = playthrough.get('game.id');

        var missionGroupPromise = _this.store.find('mission_group', {
          group_type: Breadcrumb.MissionGroupTypes.START_TRACK,
          game: game_id
        });
        var missionPromise = _this.store.find('mission', { game: game_id });
        var gamePlanPromise = _this.store.find('game-plan', { game: game_id });
        return _ember['default'].RSVP.hash({
          playthrough: playthrough,
          missions: missionPromise,
          missionGroups: missionGroupPromise,
          gamePlan: gamePlanPromise
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      var missionGroups = model.missionGroups;
      var missions = model.missions;
      var gamePlan = model.gamePlan;
      model = model.playthrough;

      if (!this.checkPlaythroughStatus(model)) {
        return;
      }
      var container = this.container;
      var app = container.lookup('application:main');
      var flavorName = model.get('game.flavorName') || app.get('defaultFlavorName');
      var blueprintName = model.get('game.blueprintName');
      var loadIdentity = this.controllerFor('identity').lookupIdentity(model.get('id'));
      var loadBlueprint = Breadcrumb.Blueprint.load(blueprintName);
      // NOTE: Flavor name is null for all games at the moment
      // var loadFlavorPromise = (flavorName === app.get('defaultFlavorName')) ?
      //   Ember.RSVP.resolve() : loadFlavor(flavorName, container);
      // Load Default Flavor for clearing
      var loadFlavorPromise = (0, _playLoadFlavor['default'])(app.get('defaultFlavorName'), container);
      var allPromises = [loadIdentity, loadBlueprint, loadFlavorPromise];
      var startTrackMissions = [];
      if (missionGroups.get('content.length')) {
        startTrackMissions = missionGroups.get('content')[0].get('missions');
      }

      var loadEverything = _ember['default'].RSVP.all(allPromises);

      // Load base flavor, blueprint and identity, then augment by DB flavors.
      return loadEverything.then(function () {
        return model.get('game.flavors');
      }).then(function (flavors) {
        if (!flavors) {
          return;
        }
        _this2.loadStyle(model.get('game.id'));
        flavors.forEach(function (flavor) {
          if (flavor.get('isArchived')) {
            return;
          }
          flavor.get('entries').forEach(function (entry) {
            (0, _playAddToFlavor['default'])(container, entry[0], entry[1]);
          });
        });
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var team = this.controllerFor('identity').get('team');
      if (team) {
        this.controllerFor('login').saveResumePoint(model.playthrough, team, this.controllerFor('identity').get('roleIndex'));
      }
    },
    checkPlaythroughStatus: function checkPlaythroughStatus(playthrough) {
      playthrough = playthrough || this.modelFor('playthrough').playthrough;
      // clear resume and leave playthrough if archived
      var isAdmin = this.controllerFor('identity').get('admin');
      if (playthrough.get('isStatusArchived') && !isAdmin) {
        this.controllerFor('login').clearResumePoint();
        this.transitionTo('login');
        return false;
      }
      return true;
    },
    loadStyle: function loadStyle(gameId) {
      if (BREADCRUMB_ENV.TESTING) {
        return;
      }
      var ssl = location.protocol === 'http:' ? '' : 's';
      var styleUrl = 'http' + ssl + '://%@/play/style/%@.css'.fmt(BREADCRUMB_ENV.SITE_DOMAIN, gameId);
      var linkHtml = "<link rel='stylesheet' type='text/css' href='%@'/>".fmt(styleUrl);
      $("head").append(linkHtml);
    },
    actions: {
      playthroughStatusDidChange: function playthroughStatusDidChange(newStatus) {
        var team = this.controllerFor('identity').get('team');
        // uncomment to reactivate client logging
        // Breadcrumb.Logs.save(team.id);
        if (team) {
          team.safeReload();
        }
        this.checkPlaythroughStatus();
      },
      resume: function resume() {
        var playthrough = this.modelFor('playthrough').playthrough,
            self = this;
        playthrough.safeReload(true).then(function () {
          self.checkPlaythroughStatus();
        });
      }
    }
  });
  var DEFAULT_TITLE = 'Game';

  exports['default'] = PlaythroughRoute;
});