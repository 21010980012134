define('play/views/play-waypoint', ['exports', 'ember'], function (exports, _ember) {

  var PlayWaypointView = _ember['default'].View.extend({
    classNames: ['app-play-waypoint'],
    submit: function submit() {
      this.get('controller').submit();
      return false;
    }
  });
  var get = _ember['default'].get;

  exports['default'] = PlayWaypointView;
});