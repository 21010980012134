define('play/controllers/playthrough-header', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughHeaderController = _ember['default'].Controller.extend({
    needs: ['playthrough', 'identity', 'login', 'playModes', 'playRole', 'playSyncStatus', 'vote/select', 'playInbox'],
    selected: null,
    admin: _ember['default'].computed.oneWay('controllers.identity.admin'),
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    game: _ember['default'].computed.oneWay('playthrough.game'),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    voteSelectReady: _ember['default'].computed.alias('controllers.vote/select.isReady'),

    hasHybridChat: (function () {
      return !!(this.get('team.weve_conversation_team_id') && this.get('team.weve_conversation_org_id') && this.get('team.weve_conversation_game_id') && this.get('playthrough.weve_client_id'));
    }).property('team.weve_conversation_org_id', 'team.weve_conversation_game_id', 'team.weve_conversation_team_id', 'playthrough.weve_client_id'),

    shouldShowAllTabs: false,
    // defaults
    headerWidth: 320,
    navTabHeight: 44,
    navTabWidth: 50,
    pointsWidth: 70,
    progressBarDefaultHeight: 22,
    maxTabs: 5,
    maxHeaderHeightPhone: 90,
    maxHeaderHeightDesktop: 180,

    enabledMessageMenu: (function () {
      return this.get('playthrough.options.enabled_teams_message');
    }).property('playthrough.options.enabled_teams_message'),
    headerImage: (function () {
      var image = _ember['default'].I18n.t('nav.header.image');
      return image.substr(0, 4) === 'http' ? image : null;
    }).property('playthrough'),

    shouldShowMoreTab: (function () {
      var maxTabs = this.get('maxTabs');
      if (this.get('enabledMessageMenu')) {
        maxTabs -= 1;
      }
      return this.get('tabs.length') > maxTabs;
    }).property('tabs', 'maxTabs'),

    hasTwoRows: (function () {
      return this.get('shouldShowMoreTab') && this.get('shouldShowAllTabs');
    }).property('shouldShowMoreTab', 'shouldShowAllTabs'),

    hasStarted: (function () {
      return this.get('playthrough.hasStarted') && this.get('team.hasStartedGame');
    }).property('playthrough.hasStarted', 'team.hasStartedGame'),

    hasInfo: _ember['default'].computed.bool('playthrough.game.infoMessage'),

    areGameModesEnabled: (function () {
      return this.get('playthrough.isInProgress') && this.get('team.hasStartedGame');
    }).property('playthrough.isInProgress', 'team.hasStartedGame'),

    allowVoting: (function () {
      if (!this.get('playthrough.isVoting')) {
        return false;
      }
      if (!this.get('controllers.playRole.role.voting')) {
        return false;
      }
      return true;
    }).property('playthrough.isVoting', 'controllers.playRole.role'),

    promptAdmin: function promptAdmin() {
      if (this.get('admin')) {
        return;
      }
      var self = this;
      var pw = Breadcrumb.NativeHooks.prompt('To enter admin mode, provide the password.', function (pw) {
        pw = (pw || '').toLowerCase();
        var app = self.container.lookup('application:main');
        if (app.get('adminCodes').indexOf(pw) > -1) {
          self.enableAdmin();
        }
        if (app.get('restartCodes').indexOf(pw) > -1) {
          self.restart();
        }
        if (app.get('logoutCodes').indexOf(pw) > -1) {
          self.logout();
        }
      }, 'Password needed');
    },

    shouldShowPoints: (function () {
      var blueprint = this.get('playthrough.game.blueprint');
      if (blueprint.get('data.hide_mission_points') === true) {
        return false;
      }
      if (!this.get('team')) {
        return false;
      }
      if (!this.get('team.pointsTotal')) {
        return false;
      }
      if (!this.get('hasStarted')) {
        return false;
      }
      if (this.get('playthrough.isVoting')) {
        return false;
      }
      return true;
    }).property('team', 'team.pointsTotal', 'hasStarted', 'playthrough.isVoting'),

    canSelectForVoting: (function () {
      // no max
      var max_votables = this.get('game.routing.votables.max');
      if (!max_votables) {
        return false;
      }
      var status = this.get('playthrough.status');
      return status === B.PlaythroughStatuses.ENDED || status === B.PlaythroughStatuses.ENDING;
    }).property('playthrough.status', 'game.routing.votables.max'),

    selectForVotingRequired: (function () {
      if (this.get('canSelectForVoting')) {
        return this.get('voteSelectReady') !== true;
      }
      return false;
    }).property('canSelectForVoting', 'voteSelectReady'),

    enableAdmin: function enableAdmin() {
      this.get('controllers.identity').set('admin', true);
      this.transitionToRoute('playthrough.admin');
    },

    restart: function restart() {
      var msg = 'Are you sure you\'d like to restart the game interface? ' + 'This will interrupt any current photo or video uploads, ' + 'so ideally wait until they have finished.';
      var title = 'Confirm please';
      Breadcrumb.NativeHooks.confirm(msg, title, function onyes() {
        window.location = window.location.pathname; // reset href
      }, function onNo() {
        return false; // nothing happens;
      });
    },

    logout: function logout() {
      var msg = 'Are you sure you want to exit the game?';
      var title = 'Confirm please';
      var self = this;
      Breadcrumb.NativeHooks.confirm(msg, title, function onyes() {
        self.get('controllers.login').clearResumePoint();
        self.get('controllers.identity').logout(self.get('playthrough.id'));
        self.transitionToRoute('login');
      }, function onNo() {
        return false; // nothing happens;
      });
    },

    tabs: (function () {
      var _this = this;

      var tabs = [];
      if (!this.get('team')) {
        // login
        tabs.push({
          item: 'login',
          link: 'login',
          icon: 'icon-signin',
          caption: _ember['default'].I18n.t('nav.login')
        });
      } else {
        (function () {
          var allowVoting = _this.get('allowVoting');
          var disabledByVoting = _this.get('selectForVotingRequired');
          // home
          tabs.push({
            item: 'home',
            link: 'play.home',
            icon: 'icon-home',
            isDisabled: disabledByVoting,
            caption: _ember['default'].I18n.t('nav.home')
          });
          // modes
          if (_this.get('areGameModesEnabled')) {
            _this.get('controllers.playModes').forEach(function (mode) {
              tabs.push({
                isMode: true,
                item: mode.get('modeName'),
                link: 'playMode.home',
                modeName: mode.get('modeName'),
                isDisabled: mode.get('isDisabled') || disabledByVoting,
                icon: mode.get('icon'),
                caption: mode.get('navTitle'),
                mode: mode
              });
            });
          }
          // voting
          if (_this.get('canSelectForVoting')) {
            tabs.push({
              item: 'vote',
              link: 'vote.select',
              icon: 'icon-thumbs-up',
              caption: _ember['default'].I18n.t('nav.select_votables')
            });
          }
          if (allowVoting) {
            tabs.push({
              item: 'vote',
              link: 'vote.vote',
              icon: 'icon-thumbs-up',
              caption: _ember['default'].I18n.t('nav.vote')
            });
          }
          // team-chat, + hybrid
          if (_this.get('hasHybridChat')) {
            tabs.push({
              item: 'teamchat',
              link: 'playthrough.teamchat',
              icon: 'icon-envelope',
              caption: _ember['default'].I18n.t('nav.teamchat')
            });
          }
        })();
      }
      // leaderboard
      if (this.get('hasStarted') && this.get('game.options.leaderboard_enabled')) {
        tabs.push({
          item: 'leaderboard',
          link: 'leaderboard.leaderboard',
          icon: 'icon-trophy',
          caption: _ember['default'].I18n.t('nav.leaderboard')
        });
      }
      // gallery
      if (this.get('hasStarted') && this.get('game.options.gallery_enabled')) {
        tabs.push({
          item: 'gallery',
          link: 'gallery',
          icon: 'icon-picture',
          caption: _ember['default'].I18n.t('nav.gallery')
        });
      }
      // info
      if (this.get('hasStarted') && this.get('hasInfo')) {
        tabs.push({
          item: 'info',
          link: 'play.info',
          icon: 'icon-info-sign',
          caption: _ember['default'].I18n.t('nav.info')
        });
      }
      // message
      // if(this.get('hasStarted') && this.get('game.options.message_enabled')) {
      //   tabs.push({
      //     item: 'info',
      //     link: 'play.info',
      //     icon: 'icon-envelope',
      //     caption: Ember.I18n.t('nav.message')
      //   });
      // }

      // admin
      if (this.get('admin')) {
        if (this.get('playthrough.isEnded')) {
          tabs.push({
            item: 'voteAdmin',
            link: 'vote.admin',
            icon: 'icon-list',
            caption: _ember['default'].I18n.t('nav.vote_admin')
          });
        }
        tabs.push({
          item: 'admin',
          link: 'playthrough.admin',
          icon: 'icon-cog',
          caption: _ember['default'].I18n.t('nav.admin')
        });
      }
      return tabs.filter(function (tab) {
        return !tab.mode || tab.mode.get('isVisible');
      });
    }).property('team', 'playthrough.status', 'admin', 'areGameModesEnabled', 'canSelectForVoting', 'allowVoting', 'hasInfo', 'controllers.playModes', 'controllers.playModes.@each.isVisible', 'selectForVotingRequired'),

    truncatedTabs: (function () {
      var tabs = this.get('tabs');
      var maxTabs = this.get('maxTabs');
      if (this.get('enabledMessageMenu')) {
        maxTabs -= 1;
      }
      if (this.get('shouldShowMoreTab')) {
        maxTabs -= 1;
      }
      if (tabs.length > maxTabs && !this.get('shouldShowAllTabs')) {
        return tabs.slice(0, maxTabs);
      }
      return tabs;
    }).property('tabs', 'maxTabs', 'shouldShowMoreTab', 'shouldShowAllTabs'),

    actions: {
      showAllTabs: function showAllTabs() {
        this.set('shouldShowAllTabs', true);
      },
      hideMoreTabs: function hideMoreTabs() {
        this.set('shouldShowAllTabs', false);
      },
      showMessagePopup: function showMessagePopup() {
        this.get('controllers.playInbox').showSendMessage();
      }
    }
  });
  exports['default'] = PlaythroughHeaderController;
});