define('play/routes/play-retry/list', ['exports'], function (exports) {
  var PlayRetryListRoute = Breadcrumb.Route.extend({
    setupController: function setupController(controller) {
      this.loadWaypoints();
    },
    loadWaypoints: function loadWaypoints() {
      var team = this.controllerFor('identity').get('team');
      var query = { team: team.id, queued_at__ne: 'null' };
      var waypoints = this.store.find('waypoint', query);
      this.controller.load(waypoints);
    },
    renderTemplate: function renderTemplate() {
      this.render('playRetry/list', { into: 'playthrough' });
    }
  });

  exports['default'] = PlayRetryListRoute;
});