define('play/controllers/play-home-mode', ['exports', 'ember'], function (exports, _ember) {

  var PlayHomeModeController = _ember['default'].ObjectController.extend({
    needs: ['play/home', 'playHomeModes', 'playHomeInProgress'],

    isFirst: (function () {
      var firstMode = this.get('controllers.playHomeModes').filterBy('isVisible')[0];
      if (!firstMode) {
        return false;
      }
      return this.get('modeName') === firstMode.get('modeName');
    }).property('modeName'),

    btnClassName: (function () {
      return 'home-option home-option-' + this.get('modeName').dasherize();
    }).property('modeName'),

    actions: {
      goToMode: function goToMode() {
        this.get('controllers.playHomeInProgress').set('isReentered', false);
        this.transitionToRoute('playMode.home', this.get('modeName'));
      }
    }
  });

  exports['default'] = PlayHomeModeController;
});