define('play/controllers/play-mode-persistent', ['exports', 'play/controllers/play-mode-base', 'ember'], function (exports, _playControllersPlayModeBase, _ember) {

  var PlayModePersistentController = _playControllersPlayModeBase['default'].extend({
    needs: ['playthrough', 'identity', 'playSyncWaypoints', 'play/home', 'playRole'],

    source: _ember['default'].computed.oneWay('content.source'),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),

    hideDuringStartTrack: (function () {
      var blueprint = this.get('controllers.playthrough.content.playthrough.game.blueprint.name');
      return blueprint === 'conference';
    }).property('controllers.playthrough.content.playthrough.game.blueprint.name'),

    isVisible: (function () {
      if (!this.get('isActive')) {
        return false;
      }
      if (this.get('source') === 'queued' && !this.get('activeWaypoints.length')) {
        return false;
      }
      var mode = this.get('mode');
      if (this.get('team.status') === 200 && mode.category === 'anytime') {
        return false;
      }
      if (this.get('team.status') === 200 && this.get('hideDuringStartTrack') && mode.category !== 'intro') {
        return false;
      }
      if (this.get('hideDuringStartTrack') && this.get('team') && mode.category === 'intro') {
        var waypoints = this.get('team').get('activeWaypoints.content.content');
        if (!waypoints) {
          return false;
        }
        waypoints = waypoints.filter(function (waypoint) {
          return waypoint.get('mission.modeName') === mode.category;
        });
        return waypoints.length > 0;
      }
      var game_id = this.get('controllers.playthrough.model.playthrough.game.id');
      var mode_name = this.get('modeName');
      var available_missions = this.get('controllers.playthrough.missions.content').filter(function (mission) {
        return mission.get('game.id') === game_id && mission.get('modeName') === mode_name && mission.get('isArchived') !== true;
      });
      return mode && mode.enabled !== false && available_missions.get('length') > 0;
    }).property('isActive', 'modeName', 'game.routing', 'activeWaypoints.length', 'missions.length', 'hideDuringStartTrack', 'team'),

    homeTitle: (function () {
      var title = this.translationForKey('title');
      if (this.get('playthrough.isEnded')) {
        title += ' (' + _ember['default'].I18n.t('global.unavailable') + ')';
      }
      return title;
    }).property('content', 'playthrough.isEnded'),

    isSelfUninterruptible: (function () {
      if (this.get('modeName') !== 'head_to_head') {
        return false;
      }
      var completedAndSyncingIds = this.get('controllers.playSyncWaypoints.completedWaypointsSyncedIds');
      var activeStarted = this.get('team.activeWaypoints').filter(function (w) {
        if (!w.get('startedAt')) {
          return false;
        }
        if (w.get('completedAt')) {
          return false;
        }
        if (completedAndSyncingIds.contains(w.get('id'))) {
          return false;
        }
        return true;
      });
      return activeStarted.anyBy('mission.category.uninterruptible');
    }).property('controllers.playSyncWaypoints.completedWaypointsSyncedIds', 'team.activeWaypoints.@each.mission.category', 'team.activeWaypoints.@each.startedAt'),

    isDisabled: (function () {
      if (this.get('isSelfUninterruptible')) {
        return true;
      }
      var playthroughStatus = this.get('playthrough.status');
      return !(playthroughStatus === B.PlaythroughStatuses.STARTED || playthroughStatus === B.PlaythroughStatuses.ENDING);
    }).property('playthrough.status'),

    getDefaultMission: function getDefaultMission() {
      var missions = this.get('controllers.playthrough.missions.content').filterBy('modeName', this.get('modeName'));
      if (missions.length === 1) {
        var activeWaypoints = this.get('activeWaypoints');
        if (activeWaypoints.length === 1) {
          return activeWaypoints[0];
        }
      }
      return null;
    }
  });

  exports['default'] = PlayModePersistentController;
});