define('play/controllers/leaderboard-team', ['exports', 'ember'], function (exports, _ember) {

  var LeaderboardTeamController = _ember['default'].ObjectController.extend({
    needs: ['leaderboard', 'identity'],
    place: (function () {
      return this.get('controllers.leaderboard').indexOf(this) + 1;
    }).property(),

    isCurrentTeam: (function () {
      var currentTeamId = this.get('controllers.identity').get('team').get('id');
      return this.get('id') === currentTeamId;
    }).property('controllers.identity.team.id', 'id')

  });

  exports['default'] = LeaderboardTeamController;
});