define('play/routes/vote/admin', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var VoteAdminRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'voteAdmin',
    beforeModel: function beforeModel() {
      if (!this.controllerFor('identity').get('admin')) {
        this.transitionTo('playthrough.intro', this.modelFor('playthrough'));
      }
    },
    setupController: function setupController(controller) {
      var playthrough = this.modelFor('playthrough').playthrough;
      controller.set('content', this.store.filter(Breadcrumb.Waypoint, function (w) {
        return w.get('playthrough') === playthrough && w.get('isVotable') && w.get('isVotingSelected');
      }));
      this.refresh();
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('vote/admin', { into: 'playthrough' });
    },
    refresh: function refresh() {
      var playthrough = this.modelFor('playthrough').playthrough;
      var waypointQuery = {
        playthrough: playthrough.get('id'),
        is_votable: true,
        is_voting_selected: true,
        sideload: 'mission,team',
        range_size: 1000
      };
      var controller = this.controller;
      this.controller.set('isLoading', true);
      this.store.find('waypoint', waypointQuery).then(function () {
        controller.set('isLoading', false);
      });
      this.controllerFor('vote-admin-voting-on').set('content', playthrough.get('votingWaypoint'));
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      resume: function resume() {
        this.refresh();
      }
    }
  });

  exports['default'] = VoteAdminRoute;
});