define('play/controllers/play-message', ['exports', 'play/controllers/play-modal-base', 'ember'], function (exports, _playControllersPlayModalBase, _ember) {

  var PlayMessageController = _playControllersPlayModalBase['default'].extend({
    needs: ['playSocialSelect', 'identity', 'playWaypoint', 'play'],
    text: null,
    style: null,
    image: null,
    footer: null,
    error: null,
    pointsAwarded: null,
    pointsPossible: null,
    pointsForSharing: 0,
    isSocialEnabled: false,
    isOutgoingMessage: false,
    shareMessage: null,
    canDismiss: true,
    canNotDismiss: _ember['default'].computed.not('canDismiss'),
    hasImage: _ember['default'].computed.notEmpty('image'),
    verifyInput: null,
    verifyText: null,
    fade: 'hidden',

    shareForPointsText: (function () {
      var pointsForSharing = this.get('pointsForSharing');
      return _ember['default'].I18n.t('social.share', { 'pointsForSharing': pointsForSharing });
    }).property('content.pointsForSharing'),

    shareNoPointsText: (function () {
      return _ember['default'].I18n.t('social.message.nopoints');
    }).property(),

    verifyInputDidChange: (function () {
      var verifyText = this.get('verifyText'),
          verifyInput = this.get('verifyInput');
      if (verifyText && verifyInput) {
        var fuzzyMatchScore = verifyText.toLowerCase().score(verifyInput.toLowerCase(), 0.75);
        this.set('canDismiss', fuzzyMatchScore > 0.8);
      }
    }).observes('verifyInput', 'verifyText'),

    confirmOptionsForType: function confirmOptionsForType(confirmType) {
      switch (confirmType) {
        case 'yesno':
          return [{
            label: _ember['default'].I18n.t('message.yes'),
            result: true,
            primary: true
          }, {
            label: _ember['default'].I18n.t('message.no'),
            result: false
          }];
        case 'verify':
          return [{
            label: _ember['default'].I18n.t('message.verify.confirm'),
            result: null,
            primary: true
          }];
        case 'none':
          return [];
        case 'sendcancel':
          return [{
            label: _ember['default'].I18n.t('message.send'),
            result: null,
            primary: true
          }, {
            label: _ember['default'].I18n.t('message.cancel'),
            result: null
          }];
        default:
          return [{
            label: _ember['default'].I18n.t('message.ok'),
            result: null,
            primary: true
          }];
      }
    },

    messageDefault: (function () {
      return _ember['default'].I18n.t('social.message.default');
    }).property(),

    messageSharedText: (function () {
      var shareMessage = this.get('shareMessage');
      if (shareMessage) {
        return shareMessage;
      } else {
        return this.get('messageDefault');
      }
    }).property('shareMessage'),

    resolve: function resolve(result) {
      if (!this._deferred) {
        return;
      }
      this._deferred.resolve(result);
      this._deferred = null;
    },

    show: function show(props) {
      if (this._deferred) {
        this.resolve(null);
      }
      var defaults = {
        text: null,
        style: 'info',
        image: null,
        canDismiss: true,
        confirmType: 'ok',
        footer: null,
        pointsAwarded: null,
        pointsPossible: null,
        pointsForSharing: 0,
        error: null,
        verifyInput: null,
        verifyText: null,
        isSocialEnabled: false,
        isOutgoingMessage: false,
        inputText: null,
        fade: 'show'
      };
      props = $.extend(defaults, props);
      if (!props.confirmOptions) {
        props.confirmOptions = this.confirmOptionsForType(props.confirmType);
      }
      _ember['default'].run(this, function () {
        this.setProperties(props);
      });
      this.set('isVisible', true);
      this._deferred = _ember['default'].RSVP.defer();
      return this._deferred.promise;
    },

    hasError: (function () {
      return !!this.get('error');
    }).property('error'),

    errorMessage: (function () {
      return this.get('error', "");
    }).property('error'),

    isSocialSelected: (function () {
      return this.get('controllers.identity.team.socialInfo.network');
    }).property('controllers.identity.team.socialInfo'),

    selectedSocialIcon: (function () {
      var network = this.get('controllers.identity.team.socialInfo.network'),
          icon = _ember['default'].I18n.t('social.' + network + '.icon');

      return icon || "";
    }).property('controllers.identity.team.socialInfo'),

    actions: {
      skipShare: function skipShare() {
        this.send('confirm', null);
        this.set('error', "");
      },
      clear: function clear() {
        this.send('confirm', null);
      },
      dismiss: function dismiss() {
        this.send('confirm', null);
      },
      confirm: function confirm(result) {
        var team = this.get('controllers.identity.team'),
            self = this;
        this.set('fade', 'hide');
        _ember['default'].run(self, function () {
          _ember['default'].run.later(function () {
            self.set('isVisible', false);
          }, 600);
          //self.set('fade','hidden');
          self.resolve(result);
        });
      },
      socialLogout: function socialLogout() {
        var playController = this.get('controllers.play');
        playController.triggerAction('social_log_out').then(function () {}, function (err) {
          console.error("Play Message Error: Social Log Out Failed");
        });
      },
      selectSocial: function selectSocial() {
        _ember['default'].run(this, function () {
          this.set('isVisible', false);
          this.get('controllers.playSocialSelect').send('show');
        });
      },
      share: function share() {
        var selectedSocial = this.get('controllers.identity.team.socialInfo'),
            missionName = this.get('controllers.playWaypoint.step.title'),
            teamName = this.get('controllers.identity.team.name'),
            self = this;

        if (selectedSocial && selectedSocial.network) {
          var shareParams = $.extend({
            name: teamName + ": " + missionName,
            message: this.get('messageSharedText'),
            points: this.get('pointsForSharing')
          }, selectedSocial);

          var waypoint = this.get('controllers.playWaypoint.waypoint');
          waypoint.triggerAction('share', { params: shareParams }).then(function () {}, function (err) {
            console.error("Play Message Error: Share Failed");
          });
        }
        this.set('shareMessage', null);
        this.send('confirm', null);
      }
    }
  });

  exports['default'] = PlayMessageController;
});