define('play/routes/leaderboard', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var LeaderboardRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'leaderboard',
    beforeModel: function beforeModel() {
      if (!this.controllerFor('identity').get('loggedIn')) {
        this.transitionTo('playthrough.intro', this.modelFor('playthrough'));
      }
    },
    setupController: function setupController(controller) {
      this.refresh();
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('leaderboard', { into: 'playthrough' });
    },
    refresh: function refresh() {
      var playthrough = this.modelFor('playthrough').playthrough;
      var teamQuery = {
        playthrough: playthrough.get('id'),
        is_archived: false,
        points_total__gt: 0,
        range_size: 1000
      };
      this.controller.load(this.store.find('team', teamQuery));
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      resume: function resume() {
        this.refresh();
      }
    }
  });

  exports['default'] = LeaderboardRoute;
});