define('play/views/vote-select-item', ['exports', 'ember'], function (exports, _ember) {

  var VoteSelectItemView = _ember['default'].View.extend({
    classNameBindings: [':select-item', 'controller.isVotingSelected:is-selected:is-deselected'],

    click: function click(e) {
      if (e.target.tagName === 'INPUT') {
        return true;
      }
      this.get('controller').send('toggle');
      return false;
    }
  });

  exports['default'] = VoteSelectItemView;
});