define('play/controllers/play-mode-routed-home', ['exports', 'ember', 'play/views/play-mode-base-translation-property'], function (exports, _ember, _playViewsPlayModeBaseTranslationProperty) {

  var PlayModeRoutedHomeController = _ember['default'].Controller.extend({
    needs: ['playMode', 'playModes', 'play', 'identity'],
    title: _ember['default'].computed.oneWay('mode.modeTitle'),
    team: _ember['default'].computed.oneWay('controllers.identity.team'),
    isRouting: false,
    routingError: null,

    mode: (function () {
      return this.get('controllers.playModes').findBy('modeName', this.get('controllers.playMode.modeName'));
    }).property('controllers.playMode.modeName'),

    translationForKey: function translationForKey(key, context) {
      return this.get('mode').translationForKey(key, context);
    },

    routingText: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.routing'),
    continueText: (0, _playViewsPlayModeBaseTranslationProperty['default'])('home.continue'),

    route: function route() {
      var _this = this;

      this.setProperties({ isRouting: true, routingError: null });
      this.get('controllers.play').triggerAction('route').then(function () {
        // Check the next waypoint and route to it, or home.
        var nextWaypoint = _this.get('mode.activeWaypoints.firstObject');
        if (nextWaypoint) {
          _this.transitionToRoute('playMode.waypoint', nextWaypoint);
        } else {
          _this.transitionToRoute('play.home');
        }
      }, function (err) {
        // Check the next waypoint in case it worked anyway.
        _this.set('routingError', _this.translationForKey('home.error'));
        var nextWaypoint = _this.get('mode.activeWaypoints.firstObject');
        if (nextWaypoint) {
          _this.transitionToRoute('playMode.waypoint', nextWaypoint);
        }
      }).then(function () {
        _this.set('isRouting', false);
      });
    },

    actions: {
      homeLoaded: function homeLoaded() {
        this.route();
      },
      route: function route() {
        this.route();
      }
    }
  });

  exports['default'] = PlayModeRoutedHomeController;
});