define('play/controllers/play-retry/list', ['exports', 'ember'], function (exports, _ember) {

  var PlayRetryListController = _ember['default'].ArrayController.extend({
    sortProperties: ['startedAt', 'id'],
    sortAscending: false,

    sortFunction: function sortFunction(x, y) {
      if (x === y) {
        return 0;
      }
      if (x === null) {
        return 1;
      }
      if (y === null) {
        return -1;
      }
      return x < y ? -1 : 1;
    }

  });

  exports['default'] = PlayRetryListController;
});