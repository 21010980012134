define('play/controllers/playthrough/teamchat', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughTeamChatController = _ember['default'].Controller.extend({
    needs: ['play', 'playthrough', 'identity', 'login', 'playRole'],
    team: _ember['default'].computed.alias('controllers.play.team'),
    playthrough: _ember['default'].computed.alias('controllers.playthrough.playthrough'),
    roles: _ember['default'].computed.oneWay('controllers.playRole.roles'),
    conversationInstance: null,
    isLoadedChat: false,
    message: '',
    messages: [],
    identity: _ember['default'].computed.oneWay('controllers.identity.team.id'),
    unreadMessageCount: 0,
    unreadMessagesRef: null,
    onUpdateActiveChatTimestamp: function onUpdateActiveChatTimestamp() {},

    onMessageUpdate: (function () {
      this.unreadMessagesRef && this.unreadMessagesRef.set(Date.now());
    }).observes('messages'),

    onUnreadMessageCountChange: (function () {
      var _this = this;

      if (this.unreadMessageCount) {
        if (this._unreadMessageTimerID) return;
        this._oldTitle = $(document).attr('title');
        this._unreadMessageTimerID = setInterval(function () {
          if (_this._oldTitle === $(document).attr('title')) {
            $(document).attr('title', 'You have an unread message');
          } else {
            $(document).attr('title', _this._oldTitle);
          }
        }, 1000);
      }
    }).observes('unreadMessageCount'),

    actions: {
      sendMessage: function sendMessage() {
        if (!this.message) return;
        var weve_team_id = this.get('team.weve_conversation_team_id');
        var weve_client_id = this.get('playthrough.weve_client_id');
        var weve_org_id = this.get('team.weve_conversation_org_id');
        var weve_game_id = this.get('team.weve_conversation_game_id');
        var messagePayload = {
          origin: 'breadcrumb',
          firstname: this.get('team.name'),
          fromID: this.get('team.id'),
          message: this.message,
          timestamp: Date.now()
        };
        Breadcrumb.api.ajax('/api/hybrid-team/' + weve_team_id + '/send-message', 'post', JSON.stringify({
          messagePayload: messagePayload,
          orgID: weve_org_id,
          gameID: weve_game_id,
          sessionID: weve_client_id
        }), {
          contentType: 'application/json'
        }).then(function () {
          console.log('done sending message');
        })['catch'](function (e) {
          Breadcrumb.Alert.error('Error sending  message (' + e.message + '})');
          console.log(e);
        });

        // new Ember.RSVP.Promise((resolve, reject) => {
        //   const data = {
        //     messagePayload,
        //     orgID: weve_org_id,
        //     gameID: weve_game_id,
        //     sessionID: weve_client_id,
        //   };
        //
        //  $.ajax({
        //     url: `/api/hybrid-team/${weve_team_id}/send-message`,
        //     type: 'post',
        //     data: JSON.stringify(data),
        //     success: resolve,
        //     error: reject,
        //     contentType : 'application/json'
        //   });
        // }).then(() => {
        //   console.log('done sending message')
        // }).catch(e => {
        //   Breadcrumb.Alert.error(`Error sending  message (${e.message}})`);
        //   console.log(e);
        // });

        this.set('message', '');
      },
      clearMessageNotification: function clearMessageNotification() {
        if (this._unreadMessageTimerID) {
          clearInterval(this._unreadMessageTimerID);
          this._unreadMessageTimerID = null;
          if (this._oldTitle) $(document).attr('title', this._oldTitle);
          this.set('unreadMessageCount', 0);
        }
      }
    }
  });

  exports['default'] = PlaythroughTeamChatController;
});
/* globals moment */