define('play/views/play-submission-part', ['exports', 'ember'], function (exports, _ember) {

  var PlaySubmissionPartView = _ember['default'].ContainerView.extend({
    // Controller is the partController
    classNames: ['submission-part'],
    controller: _ember['default'].computed.oneWay('content'),
    currentView: (function () {
      var submissionType = this.get('controller.submissionType');
      return this.createViewForSubmissionType(submissionType);
    }).property('controller.submissionType'),

    createViewForSubmissionType: function createViewForSubmissionType(submissionType) {
      _ember['default'].assert("Can't create a view for blank submission type", !!submissionType);
      var submissionViewName = 'view:submission-' + submissionType.dasherize() + '-widget';
      var submissionViewClass = this.container.lookupFactory(submissionViewName);
      _ember['default'].assert("Must have a view for submission type %@".fmt(submissionType), _ember['default'].View.detect(submissionViewClass));
      return submissionViewClass.create();
    }
  });

  exports['default'] = PlaySubmissionPartView;
});