define('play/routes/vote/vote', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var VoteVoteRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'vote',
    beforeModel: function beforeModel() {
      if (!this.controllerFor('identity').get('loggedIn')) {
        this.transitionTo('playthrough.intro', this.modelFor('playthrough'));
      }
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('vote/vote', { into: 'playthrough' });
    }
  });

  exports['default'] = VoteVoteRoute;
});