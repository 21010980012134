define('play/routes/vote/scores', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var VoteScoresRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'voteAdmin',
    beforeModel: function beforeModel() {
      if (!this.controllerFor('identity').get('admin')) {
        this.transitionTo('playthrough.intro', this.modelFor('playthrough'));
      }
    },
    setupController: function setupController(controller) {
      controller.set('isLoading', true);
      this.controllerFor('vote.admin').setVoting(false);
      var playthrough = this.modelFor('playthrough').playthrough,
          self = this;
      playthrough.triggerAction('calculateVotingScores').then(function () {
        var teams = self.store.find('team', {
          playthrough: playthrough.get('id'),
          is_archived: false, points_total__gt: 0 });
        return teams;
      }).then(null, function (err) {
        // error calculating voting scores. ignore
      }).then(function (teams) {
        controller.setProperties({ content: teams, isLoading: false });
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('vote/scores', { into: 'playthrough' });
    }
  });

  exports['default'] = VoteScoresRoute;
});