define('play/views/playthrough/teamchat', ['exports', 'ember'], function (exports, _ember) {

  var PlaythroughTeamChatView = _ember['default'].View.extend({
    classNames: ['app-teamchat-base'],
    msgs: _ember['default'].computed.oneWay('controller.messages'),

    onMessageChange: _ember['default'].observer('msgs', function () {
      var _this = this;

      clearTimeout(this.setTimeoutID);
      this.setTimeoutID = setTimeout(function () {
        try {
          _this.$("#hybridMessages").animate({ scrollTop: _this.$("#hybridMessages")[0].scrollHeight }, "slow");
        } catch (e) {};
      }, 500);
    })
  });

  exports['default'] = PlaythroughTeamChatView;
});