define('play/controllers/vote/admin', ['exports', 'ember'], function (exports, _ember) {

  var VoteAdminController = _ember['default'].GroupingArrayController.extend({
    needs: ['playthrough', 'vote-admin-voting-on'],
    playthrough: _ember['default'].computed.oneWay('controllers.playthrough.playthrough'),
    groupProperty: 'mission',
    groupsController: 'voteAdminMissions',
    groupController: 'voteAdminMission',

    isLoading: false,
    isSetting: false,

    isVotingStarted: _ember['default'].computed.oneWay('playthrough.isVoting'),
    isStarting: false,

    hasVotingOn: _ember['default'].computed.bool('controllers.vote-admin-voting-on.content'),
    isClearDisabled: _ember['default'].computed.not('hasVotingOn'),

    setVoting: function setVoting(waypoint) {
      if (this.get('isSetting')) {
        return;
      }
      var self = this;
      var wpId = waypoint ? waypoint.get('id') : null;
      this.get('controllers.vote-admin-voting-on').set('content', waypoint);
      this.set('isSetting', true);
      this.get('playthrough').triggerAction('vote_on', { waypoint_id: wpId }).then(function () {
        self.set('isSetting', false);
      }, function (err) {});
    },

    actions: {
      setVoting: function setVoting(waypoint) {
        this.setVoting(waypoint);
      },

      clearVoting: function clearVoting() {
        this.setVoting(false);
      },

      startVoting: function startVoting() {
        if (!Breadcrumb.Utils.confirm("Are you sure you want to start voting? This will open the voting tab on players' phones.")) {
          return;
        }
        var self = this;
        this.set('isStarting', true);
        this.get('playthrough').triggerAction('startVoting').then(function (data) {
          var serializer = self.store.serializerFor('playthrough');
          var payload = serializer.extract(self.store, self.store.modelFor('playthrough').playthrough, data, data.playthrough.id, 'find');
          self.store.push('playthrough', payload);
        }).then(null, function () {}).then(function () {
          self.set('isStarting', false);
        });
      }
    }
  });

  exports['default'] = VoteAdminController;
});