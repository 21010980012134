define('play/routes/play/home', ['exports', 'play/routes/playthrough-tab'], function (exports, _playRoutesPlaythroughTab) {

  var PlayHomeRoute = _playRoutesPlaythroughTab['default'].extend({
    tabValue: 'home',
    afterModel: function afterModel() {
      this.checkUnstarted();
    },
    renderTemplate: function renderTemplate() {
      this.render('play/home', { into: 'playthrough' });
    },
    checkUnstarted: function checkUnstarted() {
      var hasStarted = this.modelFor('playthrough').playthrough.get('hasStarted');
      if (!hasStarted) {
        this.transitionTo('playthrough.intro');return;
      }
    },
    actions: {
      didTransition: function didTransition() {
        // when loading home, reload everything
        if (!BREADCRUMB_ENV.TESTING) {
          var teamId = this.controllerFor('identity').get('team.id');
          this.store.findWithSideload('team', teamId, 'playthrough');
        }
        this.checkUnstarted();
      }
    }
  });

  exports['default'] = PlayHomeRoute;
});