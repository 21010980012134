define('play/controllers/vote-admin-waypoint', ['exports', 'ember'], function (exports, _ember) {

  var VoteAdminWaypointController = _ember['default'].ObjectController.extend({
    needs: ['vote-admin-voting-on', 'vote/admin', 'playthrough'],

    isVotingOn: (function () {
      var votingOn = this.get('controllers.vote-admin-voting-on').get('content');
      return this.get('content') === votingOn;
    }).property('content', 'controllers.vote-admin-voting-on.content'),

    isSetting: _ember['default'].computed.and('isVotingOn', 'controllers.vote/admin.isSetting'),

    numVotes: _ember['default'].computed.alias('votes.length'),
    voteAvg: (function () {
      var numVotes = this.get('numVotes'),
          sumValues = 0;
      if (!numVotes) {
        return "–";
      }
      this.get('votes').mapProperty('value').forEach(function (value) {
        sumValues += value;
      });
      return (sumValues / numVotes).toFixed(1);
    }).property('votes.@each.value'),

    adjustedAvg: (function () {
      var numVotes = this.get('numVotes'),
          sumValues = 0;
      if (!numVotes) {
        return null;
      }
      this.get('votes').mapProperty('value_adjusted').forEach(function (value) {
        if (sumValues === undefined) {
          return;
        }
        if (value !== null && value !== undefined) {
          sumValues += value;
        } else {
          sumValues = undefined;
        }
      });
      if (sumValues === undefined) {
        return null;
      }
      return (sumValues / numVotes).toFixed(1);
    }).property('votes.@each.value'),

    actions: {
      setVoting: function setVoting() {
        this.get('controllers.vote/admin').send('setVoting', this.get('content'));
      }
    }

  });

  exports['default'] = VoteAdminWaypointController;
});