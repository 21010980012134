define('play/views/submission-buttons-widget', ['exports', 'play/views/submission-base-widget', 'ember'], function (exports, _playViewsSubmissionBaseWidget, _ember) {

  var SubmissionButtonsWidget = _playViewsSubmissionBaseWidget['default'].extend({
    blankSubmission: { choice: null },
    validate: function validate(submission) {
      var idx = parseInt(submission.choice, 10);
      if (isNaN(idx) || idx === false || idx === null || idx === undefined) {
        return false;
      }
      var numChoices = this.get('options.choices.length');
      return idx >= 0 && idx < numChoices;
    },
    clean: function clean(submission) {
      return { choice: parseInt(submission.choice, 10) };
    },
    choicesWithValue: (function () {
      if (!_ember['default'].isArray(this.get('options.choices'))) {
        return [];
      }
      return this.get('options.choices').map(function (choice, idx) {
        var label = _ember['default'].typeOf(choice) === 'string' ? choice : choice.text;
        return { label: label, value: idx };
      });
    }).property('options.choices', 'controller.waypoint'),

    choiceDidChange: (function () {
      this.performValidation();
    }).observes('submission.choice'),

    ButtonView: _ember['default'].View.extend({
      tagName: 'button',
      classNames: ['btn', 'input-block-level', 'btn-large'],
      classNameBindings: ['isSelected:btn-primary'],

      isSelected: (function () {
        return this.get('value') === this.get('parentView.submission.choice');
      }).property('value', 'parentView.submission.choice'),

      click: function click() {
        this.get('parentView').set('submission', { choice: this.get('value') });
      }
    }),

    template: _ember['default'].Handlebars.compile('{{#each choiceAndIndex in view.choicesWithValue}}' + '  <p>' + '    {{#view view.ButtonView ' + '        disabledBinding="controller.isUpdating" ' + '        valueBinding="choiceAndIndex.value"}}' + '      {{choiceAndIndex.label}}' + '    {{/view}}' + '  </p>' + '{{/each}}')
  });

  exports['default'] = SubmissionButtonsWidget;
});