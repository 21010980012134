define('play/views/play-sync-status', ['exports', 'ember'], function (exports, _ember) {

  var PlaySyncStatusView = _ember['default'].View.extend({
    classNames: ['app-play-sync-status'],
    classNameBindings: ['controller.hasUploads:syncing:synced', 'controller.hasFailure:failure', 'controller.shouldShowAllTabs:all-tabs'],
    progressStyle: (function () {
      return 'width: %@%;'.fmt(this.get('controller.uploadProgress') || 0);
    }).property('controller.uploadProgress')
  });

  exports['default'] = PlaySyncStatusView;
});