define('play/controllers/play-inbox-item', ['exports', 'ember'], function (exports, _ember) {

  var PlayInboxItemController = _ember['default'].ObjectController.extend({
    sentAt: (function () {
      var sentAt = this.get('content.sentAt');
      var dateTransform = this.container.lookup('transform:date');
      return sentAt ? dateTransform.deserialize(sentAt) : null;
    }).property('content.sentAt'),

    messageText: (function () {
      var messageContent = this.get('content.messageContent');
      var verifyText = this.get('verifyText');
      if (messageContent && verifyText) {
        var lastChar = messageContent[messageContent.length - 1];
        if (['.', '!', '?', ':', ';', ')'].indexOf(lastChar) < 0) {
          messageContent += '.';
        }
        messageContent += ' ' + _ember['default'].I18n.t('message.verify.info', { 'verify': verifyText });
      }
      return messageContent;
    }).property('content.messageContent', 'content.verifyText')
  });

  exports['default'] = PlayInboxItemController;
});