define('play/controllers/gallery/teams', ['exports', 'ember'], function (exports, _ember) {

  var GalleryTeamsController = _ember['default'].ArrayController.extend({
    needs: ['identity'],
    itemController: 'galleryTeamsItem',
    sortProperties: ['name'],
    // sortProperties can not be computed properties

    identityTeam: (function () {
      return this.findBy('isIdentity');
    }).property('@each.isIdentity')
  });

  exports['default'] = GalleryTeamsController;
});